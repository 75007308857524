import * as React from "react";
import { UserBannerProps } from "../../../type";

export const UserBanner: React.FC<UserBannerProps> = ({ userId,userName, membershipInfo, verificationStatus }) => {
  return (
    <div className="flex gap-5 justify-between pr-7 mt-8 max-w-full bg-orange-100 rounded-xl shadow-sm w-[970px] max-md:pr-5">
      
      
      <div className="flex flex-col items-center py-4 pr-4 pl-12 bg-amber-400 rounded-xl max-md:pl-5">
        <div className="flex gap-10 self-end">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7db1df81aaba85dfcce8c94cab2a88c7306a9d003417116f2be65bc69809587a?placeholderIfAbsent=true&apiKey=61022fe3774e4aa287a6264de6b79c87"
            alt="User avatar"
            className="object-contain shrink-0 w-20 aspect-square"
          />
          <div
            className="object-contain shrink-0 self-start aspect-square w-[18px]"
          />
        </div>
        <div className="mt-2.5 text-lg font-bold text-center text-zinc-800">
        
        {userName}
        </div>
        <div className="text-sm font-medium text-center text-zinc-800">
        {userId}
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/5895e90ed1647bcaac27545300ff2a55635b11a8e9cf04d24663a69b0f87ea04?placeholderIfAbsent=true&apiKey=61022fe3774e4aa287a6264de6b79c87"
          alt=""
          className="object-contain rounded-none aspect-[5.68] w-[68px]"
        />
      </div>


      <div className="flex flex-col my-auto text-xs max-md:max-w-full">
        <div className="flex flex-wrap gap-5 justify-between items-start px-3.5 pt-2 pb-3.5 bg-cyan-700 rounded-md max-md:max-w-full">
          <div className="flex flex-col mt-1.5 text-white">
            <div className="self-start font-bold text-xl">{membershipInfo.title}</div>
            <div className="mt-3 font-medium">{membershipInfo.description}</div>
          </div>
          {/* <button className="px-3.5 py-1.5 font-medium text-center text-cyan-700 whitespace-nowrap bg-white rounded-[36px]">
            {membershipInfo.ctaText}
          </button> */}
        </div>
        <div className="mt-3.5 font-medium text-gray-700 max-md:max-w-full">
          <span className="text-sm font-bold leading-5">
            Verification in progress:
          </span>{" "}
          <span className="text-sm leading-5">{verificationStatus}</span>
        </div>
      </div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/67492dd7700a6d26feca4af85ac4a1ac8d05aee8dd7074934010c3c5ea81e260?placeholderIfAbsent=true&apiKey=61022fe3774e4aa287a6264de6b79c87"
        alt=""
        className="object-contain shrink-0 self-end mt-32 w-10 rounded-none aspect-square max-md:mt-10"
      />
    </div>
  ); 
};