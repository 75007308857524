import React from "react";
import MerchantLayout from "../../components/MerchantLayouts";
import AddBank from "../../components/Business/AddBankInfo";

const BankInfoPage = () => {
  return (
    <MerchantLayout>
      <AddBank />
    </MerchantLayout>
  ); 
};

export default BankInfoPage;
