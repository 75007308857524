import {
  DownOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import styles from "./Profile.module.css";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  TimePicker,
  Tooltip,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
interface EmptyProps {
  height?: string;
  data?: string;
}
import { debounce } from "lodash";
import axios from "axios";
import { responseNotification } from "../../../utils/notify";
import { useNavigate, useParams } from "react-router";
import ShopLayout from "../Layout";
import moment from "moment";
import { ImageUploader, SubmitResetBtn } from "../../common";

const { CheckableTag } = Tag;
const businessDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const ShopBusinessInfo = () => {
  const { type, shopInfo, merchantInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const route = useParams();
  const shopId = shopInfo?.id;
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [selectedDays, setSelectedDays] = useState<any[]>(["Sun"]);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tinUrl, setTinUrl] = useState<string>();
  const [memorandumUrl, setMemorandumUrl] = useState<string>();
  const [resolutionUrl, setResolutionUrl] = useState<string>();
  const [tradeUrl, setTradeUrl] = useState<string>();
  const [binUrl, setBinUrl] = useState<string>();
  const [utilityUrl, setUtilityUrl] = useState<string>();
  const [bankUrl, setBankUrl] = useState<string>();
  const [logoUrl, setLogoUrl] = useState<string>();
  const [bannerUrl, setBannerUrl] = useState<string>();
  const [selectedStartTime, setSelectedStartTime] = useState<any>("00:00");
  const [selectedCloseTime, setSelectedCloseTime] = useState<any>("00:00");

  const [startUploadTin, setStartUploadTin] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadMemorandum, setStartUploadMemorandum] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadResolution, setStartUploadResolution] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadTrade, setStartUploadTrade] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadBin, setStartUploadBin] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadUtility, setStartUploadUtility] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadBank, setStartUploadBank] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadLogo, setStartUploadLogo] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadBanner, setStartUploadBanner] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [singleBusinessInfo, setSingleBusinessInfo] = useState<any>({
    loading: false,
    data: null,
  });

  useEffect(() => {
    if (startUploadTin === "Uploaded") {
      form?.submit();
    }
  }, [form, startUploadTin]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      merchantBusinessInfo: {
        attachTinDocuments: tinUrl,
        attachTradeLicence: data.attachTradeLicence,
        bankDocuments: bankUrl,
        binNumber: data.binNumber,
        businessDay: selectedDays?.map(
          (businessDay: any, businessDayIndex: any) => ({
            businessDayName: businessDay,
          })
        ),
        businessDescription: data.businessDescription,
        //businessLogo: logoUrl,
        //bannerLogo: bannerUrl,
        etinNo: data.etinNo,
        memorandum: memorandumUrl,
        resolution: resolutionUrl,
        attachTradeLicenceDocument: tradeUrl,
        shopClosingTime: selectedCloseTime,
        shopOpeningTime: selectedStartTime,
        utilityBillDocuments: utilityUrl,
        attachBinNumber: binUrl,
      },
      shopId: shopId,
    };
    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/business-info`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...readyData }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          responseNotification("Update Business Info Successfully", "success");
          //fetchBusinessInfoDetails(shopInfo?.id);
          form.resetFields();
          //window.location.reload();
          // navigate("/business/info");
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedDays = checked
      ? [...selectedDays, tag]
      : selectedDays.filter((t) => t !== tag);
    setSelectedDays(nextSelectedDays);
  };

  const fetchBusinessDetails = useCallback((shopId: any) => {
    if (shopId) {
      try {
        setSingleBusinessInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/merchant-shop/my-shop-details?shopId=${shopId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleBusinessInfo({
                loading: false,
                data: data?.data?.shop,
              });

             if(data?.data?.shop?.merchantBusinessInfo?.businessDay){
              setSelectedDays(
                data?.data?.shop?.merchantBusinessInfo?.businessDay.map(
                  (tag) => tag?.businessDayName
                )
              );
             }

              setTinUrl(
                data?.data?.shop?.merchantBusinessInfo?.attachTinDocuments
              );
              setTradeUrl(
                data?.data?.shop?.merchantBusinessInfo
                  ?.attachTradeLicenceDocument
              );
              setBankUrl(data?.data?.shop?.merchantBusinessInfo?.bankDocuments);
              setMemorandumUrl(
                data?.data?.shop?.merchantBusinessInfo?.memorandum
              );
              setResolutionUrl(
                data?.data?.shop?.merchantBusinessInfo?.resolution
              );
              setUtilityUrl(
                data?.data?.shop?.merchantBusinessInfo?.utilityBillDocuments
              );
              setLogoUrl(data?.data?.shop?.merchantBusinessInfo?.businessLogo);
              setBannerUrl(data?.data?.shop?.merchantBusinessInfo?.bannerLogo);
              setBinUrl(data?.data?.shop?.merchantBusinessInfo?.attachBinNumber);
              setSelectedStartTime(
                data?.data?.shop?.merchantBusinessInfo?.shopOpeningTime ||
                  "00:00"
              );
              setSelectedCloseTime(
                data?.data?.shop?.merchantBusinessInfo?.shopClosingTime ||
                  "00:00"
              );
            } else {
              setSingleBusinessInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleBusinessInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleBusinessInfo({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    fetchBusinessDetails(shopId);
  }, [fetchBusinessDetails, shopId]);

  useEffect(() => {
    if (singleBusinessInfo?.data) {
      form.resetFields();
      setTinUrl(
        singleBusinessInfo?.data?.merchantBusinessInfo?.attachTinDocuments
      );
      setMemorandumUrl(
        singleBusinessInfo?.data?.merchantBusinessInfo?.memorandum
      );
      setResolutionUrl(
        singleBusinessInfo?.data?.merchantBusinessInfo?.resolution
      );
      setTradeUrl(
        singleBusinessInfo?.data?.merchantBusinessInfo
          ?.attachTradeLicenceDocument
      );
      setBankUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.bankDocuments);

      setUtilityUrl(
        singleBusinessInfo?.data?.merchantBusinessInfo?.utilityBillDocuments
      );
      setLogoUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.businessLogo);
      setBannerUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.bannerLogo);
      setBinUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.attachBinNumber);
      setSelectedStartTime(
        singleBusinessInfo?.data?.merchantBusinessInfo?.shopOpeningTime ||
          "00:00"
      );
      setSelectedCloseTime(
        singleBusinessInfo?.data?.merchantBusinessInfo?.shopClosingTime ||
          "00:00"
      );
    }
  }, [form, singleBusinessInfo?.data]);

  const resetData = () => {
    form?.resetFields();
    setTinUrl(
      singleBusinessInfo?.data?.merchantBusinessInfo?.attachTinDocuments
    );
  };

  return (
    <>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Business Information"
        subTitle="Business Information"
        extra={<></>}
      >
        <div className="content-body bg-white rounded-2xl p-4 shadow">
          <Form
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onSubmit}
            initialValues={{
              ...singleBusinessInfo?.data?.merchantBusinessInfo,
              etinNo:
                singleBusinessInfo?.data?.merchantBusinessInfo?.etinNo || "",
            }}
            form={form} // like ref
            layout="vertical"
            autoComplete="off"
          >
            <div className="grid grid-cols-1 gap-x-6">
              <div>
                <Form.Item
                  hasFeedback
                  label="Business Day"
                  rules={[
                    {
                      required: false,
                      message: "Business Day is Required!",
                    },
                  ]}
                  name="name"
                >
                  {/* setBusinessDay */}
                  <Space size={[0, 12]} wrap>
                    {businessDays.map((tag) => (
                      <CheckableTag
                        key={tag}
                        checked={selectedDays.includes(tag)}
                        onChange={(checked) => handleChange(tag, checked)}
                        className={styles.businessDay}
                      >
                        {tag}
                      </CheckableTag>
                    ))}
                  </Space>
                </Form.Item>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-6">
              <Form.Item
                hasFeedback
                label={`Shop Opening Time (${selectedStartTime})`}
              >
                <TimePicker
                  format="HH:mm"
                  //showNow={false}
                  value={moment(selectedStartTime, "HH:mm")}
                  suffixIcon={<DownOutlined />}
                  onSelect={(value) => {
                    const startTimeString = moment(value as any).format(
                      "HH:mm"
                    );
                    setSelectedStartTime(startTimeString);
                  }}
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                label={`Shop Closing Time (${selectedCloseTime})`}
              >
                <TimePicker
                  format="HH:mm"
                  //showNow={false}
                  value={moment(selectedCloseTime, "HH:mm")}
                  suffixIcon={<DownOutlined />}
                  onSelect={(value) => {
                    const timeCloseString = moment(value).format("HH:mm");
                    setSelectedCloseTime(timeCloseString);
                  }}
                />
              </Form.Item>
            </div>
            <div className="grid gap-x-6">
              <div>
                <Form.Item
                  hasFeedback
                  label="Business Description"
                  name="businessDescription"
                >
                  <Input.TextArea
                    id="businessDescription"
                    placeholder="Enter Business Description"
                    rows={3}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-x-6">
              <div>
                <Form.Item
                  hasFeedback
                  label="E-Tin No"
                  rules={[
                    {
                      required: false,
                      message: "E-Tin No is Required!",
                    },
                  ]}
                  name="etinNo"
                >
                  <Input id="etinNo" type="text" placeholder="Enter E-Tin No" />
                </Form.Item>
              </div>

              <div className="">
                <Divider orientation="left">
                  E-Tin No File
                  <Tooltip placement="bottom" title={"E-Tin No File"}>
                    <QuestionCircleOutlined className="px-2 -mt-2" />
                  </Tooltip>
                </Divider>

                <ImageUploader
                  imgUrl={tinUrl || ""}
                  startUpload={startUploadTin}
                  setStartUpload={(val: any) => setStartUploadTin(val)}
                  setGeneratedImgUrl={(url: any) => setTinUrl(url)}
                />
              </div>
            </div>

            {singleBusinessInfo?.data?.businessOperationType === "COMPANY" && (
              <>
                <div className="grid grid-cols-2 gap-x-6">
                  <div className="">
                    <Divider orientation="left">
                      Memorandum
                      <Tooltip placement="bottom" title={"Memorandum "}>
                        <QuestionCircleOutlined className="px-2 -mt-2" />
                      </Tooltip>
                    </Divider>

                    <ImageUploader
                      imgUrl={memorandumUrl || ""}
                      startUpload={startUploadMemorandum}
                      setStartUpload={(val: any) =>
                        setStartUploadMemorandum(val)
                      }
                      setGeneratedImgUrl={(url: any) => setMemorandumUrl(url)}
                    />
                  </div>

                  <div className="">
                    <Divider orientation="left">
                      Resolution
                      <Tooltip placement="bottom" title={"Resolution"}>
                        <QuestionCircleOutlined className="px-2 -mt-2" />
                      </Tooltip>
                    </Divider>

                    <ImageUploader
                      imgUrl={resolutionUrl || ""}
                      startUpload={startUploadResolution}
                      setStartUpload={(val: any) =>
                        setStartUploadResolution(val)
                      }
                      setGeneratedImgUrl={(url: any) => setResolutionUrl(url)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-x-6">
                  <div>
                    <Form.Item
                      hasFeedback
                      label="Trade License"
                      rules={[
                        {
                          required: false,
                          message: "Trade License is Required!",
                        },
                      ]}
                      name="attachTradeLicence"
                    >
                      <Input
                        id="attachTradeLicence"
                        type="text"
                        placeholder="Enter Trade License"
                      />
                    </Form.Item>
                  </div>

                  <div className="">
                    <Divider orientation="left">
                      Trade License File
                      <Tooltip placement="bottom" title={"Trade License File"}>
                        <QuestionCircleOutlined className="px-2 -mt-2" />
                      </Tooltip>
                    </Divider>

                    <ImageUploader
                      imgUrl={tradeUrl || ""}
                      startUpload={startUploadTrade}
                      setStartUpload={(val: any) => setStartUploadTrade(val)}
                      setGeneratedImgUrl={(url: any) => setTradeUrl(url)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-x-6">
                  <div>
                    <Form.Item
                      hasFeedback
                      label="Bin No"
                      rules={[
                        {
                          required: false,
                          message: "Bin No is Required!",
                        },
                      ]}
                      name="binNumber"
                    >
                      <Input
                        id="binNumber"
                        type="text"
                        placeholder="Enter Bin No"
                      />
                    </Form.Item>
                  </div>

                  <div className="">
                    <Divider orientation="left">
                      Bin No File
                      <Tooltip placement="bottom" title={"Bin No"}>
                        <QuestionCircleOutlined className="px-2 -mt-2" />
                      </Tooltip>
                    </Divider>

                    <ImageUploader
                      imgUrl={binUrl || ""}
                      startUpload={startUploadBin}
                      setStartUpload={(val: any) => setStartUploadBin(val)}
                      setGeneratedImgUrl={(url: any) => setBinUrl(url)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-x-6">
                  <div className="">
                    <Divider orientation="left">
                      Utility Bill
                      <Tooltip placement="bottom" title={"Utility Bill"}>
                        <QuestionCircleOutlined className="px-2 -mt-2" />
                      </Tooltip>
                    </Divider>

                    <ImageUploader
                      imgUrl={utilityUrl || ""}
                      startUpload={startUploadUtility}
                      setStartUpload={(val: any) => setStartUploadUtility(val)}
                      setGeneratedImgUrl={(url: any) => setUtilityUrl(url)}
                    />
                  </div>

                  <div className="">
                    <Divider orientation="left">
                      Bank Document
                      <Tooltip placement="bottom" title={"Bank Document"}>
                        <QuestionCircleOutlined className="px-2 -mt-2" />
                      </Tooltip>
                    </Divider>

                    <ImageUploader
                      imgUrl={bankUrl || ""}
                      startUpload={startUploadBank}
                      setStartUpload={(val: any) => setStartUploadBank(val)}
                      setGeneratedImgUrl={(url: any) => setBankUrl(url)}
                    />
                  </div>
                </div>
              </>
            )}

            {/* <div className="grid grid-cols-2 gap-x-6">
              <div className="">
                <Divider orientation="left">
                  Business Logo
                  <Tooltip
                    placement="bottom"
                    title={
                      "Business Logo image should 1200px/500px (Ratio 2.4)"
                    }
                  >
                    <QuestionCircleOutlined className="px-2 -mt-2" />
                  </Tooltip>
                </Divider>

                <ImageUploader
                  imgUrl={logoUrl || ""}
                  startUpload={startUploadLogo}
                  setStartUpload={(val: any) => setStartUploadLogo(val)}
                  setGeneratedImgUrl={(url: any) => setLogoUrl(url)}
                />
              </div>

              <div className="">
                <Divider orientation="left">
                  Business Banner
                  <Tooltip
                    placement="bottom"
                    title={"Shop Banner image should 1200px/500px (Ratio 2.4)"}
                  >
                    <QuestionCircleOutlined className="px-2 -mt-2" />
                  </Tooltip>
                </Divider>

                <ImageUploader
                  imgUrl={bannerUrl || ""}
                  startUpload={startUploadBanner}
                  setStartUpload={(val: any) => setStartUploadBanner(val)}
                  setGeneratedImgUrl={(url: any) => setBannerUrl(url)}
                />
              </div>
            </div> */}

            <SubmitResetBtn
              onClickSubmit={(e) => {
                e.preventDefault();
                setStartUploadTin("Uploading");
                setStartUploadLogo("Uploading");
                setStartUploadBanner("Uploading");
                setStartUploadMemorandum("Uploading");
                setStartUploadResolution("Uploading");
                setStartUploadTrade("Uploading");
                setStartUploadBin("Uploading");
                setStartUploadUtility("Uploading");
                setStartUploadBank("Uploading");
              }}
              onClickReset={() => {
                form?.resetFields();
                setStartUploadTin(undefined);
                setStartUploadLogo(undefined);
                setStartUploadBanner(undefined);
                setStartUploadMemorandum(undefined);
                setStartUploadResolution(undefined);
                setStartUploadTrade(undefined);
                setStartUploadBin(undefined);
                setStartUploadUtility(undefined);
                setStartUploadBank(undefined);
              }}
              disabled={
                loading ||
                startUploadTin === "Uploading" ||
                startUploadLogo === "Uploading" ||
                startUploadBanner === "Uploading" ||
                startUploadMemorandum === "Uploading" ||
                startUploadResolution === "Uploading" ||
                startUploadTrade === "Uploading" ||
                startUploadBin === "Uploading" ||
                startUploadUtility === "Uploading" ||
                startUploadBank === "Uploading"
              }
              loading={
                loading ||
                startUploadTin === "Uploading" ||
                startUploadLogo === "Uploading" ||
                startUploadBanner === "Uploading" ||
                startUploadMemorandum === "Uploading" ||
                startUploadResolution === "Uploading" ||
                startUploadTrade === "Uploading" ||
                startUploadBin === "Uploading" ||
                startUploadUtility === "Uploading" ||
                startUploadBank === "Uploading"
              }
            />
          </Form>
        </div>
      </ShopLayout>
    </>
  );
};

export default ShopBusinessInfo;
