import * as React from 'react';
import { DatePickerProps } from '../../../type';

export const DatePicker: React.FC<DatePickerProps> = ({ label, required = false }) => (
  <>
    <div className="mt-5 text-sm font-bold text-zinc-800">
      {label}{required && <span className="text-red-600">*</span>}
    </div>
    <div className="flex flex-wrap gap-5 justify-between px-4 py-2.5 mt-5 max-w-full text-sm font-medium bg-white rounded border border-solid border-neutral-300 text-neutral-300 w-[570px]">
      <div>Select Date And Time</div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/14d73f873df80aa1439135182d60b7403ea8d26635a5083129c485a1f6258b60?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
        alt="Calendar icon"
        className="object-contain shrink-0 aspect-[0.9] w-[18px]"
      />
    </div>
  </>
);