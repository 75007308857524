import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../../utils/notify";
import { useNavigate, useParams } from "react-router";
import CampaignLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { SET_CAMPAIGN_INFO } from "../../../../redux/auth/authType";
import Loader from "../../../common/Loader";
import { Button } from "antd"; 

const CampaignDetails = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const dispatch = useDispatch();
  const route = useParams();
  const getShopId = (route as any)?.shopId;
  const navigate = useNavigate();
  const [singleCampaignInfo, setSingleCampaignInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchCampaignDetails = useCallback((getCampaignId: any) => {
    if (getCampaignId) {
      try {
        setSingleCampaignInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/campaign/details/${getCampaignId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleCampaignInfo({
                loading: false,
                data: data?.data?.campaign,
              });
              dispatch({
                type: SET_CAMPAIGN_INFO,
                payload: {
                  campaignInfo: {
                    id: data?.data?.campaign?.id,
                    name: data?.data?.campaign?.name,
                    campaignType: data?.data?.campaign?.campaignType,
                    appsImg: data?.data?.campaign?.banner,
                    webImg: data?.data?.campaign?.bannerWeb,
                    status: data?.data?.campaign?.status,
                    description: data?.data?.campaign?.description,
                  },
                },
              });
            } else {
              setSingleCampaignInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleCampaignInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleCampaignInfo({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    fetchCampaignDetails((route as any)?.campaignId);
  }, [fetchCampaignDetails]);

  const campaign = singleCampaignInfo?.data;

  if (singleCampaignInfo.loading) return <Loader />;

  return (
    <React.Fragment>
      <CampaignLayout
        campaignId={campaign?.id}
        title={`Campaign Details`}
        subTitle={`Details`}
        extra={
          <>
            <Button
              type="dashed"
              shape="round"
              className="bg-white"
              onClick={() =>
                navigate(`/shops/${getShopId}/campaign/${campaign?.id}/assign-products`)
              }
            >
              Assign Product
            </Button>
          </>
        }
      >
        <div className="content-body rounded-2xl bg-white p-4 min-h-full">
          <h4 className="border-b pb-3 mb-8 font-semibold text-xl text-gray-600 border-gray-100">
            Basic Information
          </h4>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="col-span-1">
              <dt className="text-sm font-medium text-gray-800 mb-1">
                Campaign Name
              </dt>
              <dd className="text-sm font-medium text-gray-500">
                {campaign?.name?.trim()}
              </dd>
            </div>

            {campaign?.slug && (
              <div className="">
                <dt className="text-sm font-medium text-gray-900 mb-1">Slug</dt>
                <dd className="text-sm font-medium text-gray-500">
                  {campaign?.slug}
                </dd>
              </div>
            )}

            {campaign?.campaignType && (
              <div>
                <dt className="text-sm font-medium text-gray-800 mb-1">
                  Campaign Type
                </dt>
                <dd className="text-sm font-medium text-gray-500">
                  {campaign?.campaignType}
                </dd>
              </div>
            )}

            <div>
              <dt className="text-sm font-medium text-gray-800 mb-1">
                Minimum Discount
              </dt>
              <dd className="text-sm font-medium text-gray-500">
                {campaign?.minimumDiscount || 0}
                {campaign?.minimumDiscountType === "FLAT" ? "tk" : "%"}
              </dd>
            </div>

            {campaign?.description && (
              <div>
                <dt className="text-sm font-medium text-gray-800 mb-1">
                  Description
                </dt>

                <dd
                  className="mt-1 space-y-3 text-sm text-gray-500"
                  dangerouslySetInnerHTML={{
                    __html: campaign?.description,
                  }}
                ></dd>
              </div>
            )}

            {campaign?.termAndConditions && (
              <div>
                <dt className="text-sm font-medium text-gray-800 mb-1">
                  Term And Conditions
                </dt>
                <dd
                  className="mt-1 space-y-3 text-sm text-gray-500"
                  dangerouslySetInnerHTML={{
                    __html: campaign?.termAndConditions,
                  }}
                ></dd>
              </div>
            )}
          </dl>
        </div>
      </CampaignLayout>
    </React.Fragment>
  );
};

CampaignDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default CampaignDetails;
