import { Rate, Dropdown, Menu, Modal, Tabs, Form, Input, Button } from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import { useParams } from "react-router";
import ShopLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { SET_SHOP_INFO } from "../../../redux/auth/authType";
import moment from "moment";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ShopStatus } from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
const { confirm } = Modal;
const { TabPane } = Tabs;

const ShopFacebook = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const route = useParams();
  const [singleShopInfo, setSingleShopInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchShopDetails = useCallback((getShopId: any) => {
    if (getShopId) {
      try {
        setSingleShopInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/shop/details?shopId=${getShopId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleShopInfo({
                loading: false,
                data: data?.data?.shop,
              });
              dispatch({
                type: SET_SHOP_INFO,
                payload: {
                  shopInfo: {
                    id: data?.data?.shop?.id,
                    name: data?.data?.shop?.name,
                    type: data?.data?.shop?.type,
                    mobileNumber: data?.data?.shop?.mobileNumber,
                    logo: data?.data?.shop?.logo,
                    banner: data?.data?.shop?.banner,
                    rating: data?.data?.shop?.rating,
                    status: data?.data?.shop?.status,
                    hubId: data?.data?.shop?.hub?.id,
                    merchantId: data?.data?.shop?.merchantId,
                    deleted: data?.data?.shop?.deleted,
                  },
                },
              });
            } else {
              setSingleShopInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleShopInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleShopInfo({ loading: false, data: null });
      }
    }
  }, []);

  const onSubmit = async (data: any) => {
    const readyData = data && {
        facebookPageUrl: data.facebookPageUrl,
        facebookGroupUrl: data.facebookGroupUrl,
      };
    await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/shop`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...readyData,
        id: singleShopInfo?.data?.id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Updated Successfully", "success");
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };



  useEffect(() => {
    fetchShopDetails((route as any)?.shopId);
  }, [fetchShopDetails]);

  const shop = singleShopInfo?.data;

  return (
    <React.Fragment>
      <ShopLayout
        shopId={shop?.id}
        title={`Shop Details`}
        subTitle={`Details`}
        extra={''}
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
            <Tabs
              onTabScroll={() => {
                direction: top;
              }}
              tabPosition="top"
             
            >
              <TabPane tab="Shop Facebook Page & Group Information" key="General">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-horizontal"
        onFinish={onSubmit}
        form={form} // like ref
        layout="horizontal"
        initialValues={{
          ...singleShopInfo,
        }}
      >
                  <div>
                  <Form.Item
                      hasFeedback
                      label="Facebook Page URL"
                      name="facebookPageUrl"
                      rules={[
                        {
                          required: false,
                          message: "Facebook Page URL is Required!",
                        },
                      ]}
                    >
                      <Input id="facebookPageUrl" type="text" placeholder="Enter Facebook Page URL" />
                    </Form.Item>
                  </div>

                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Facebook Page Group"
                      name="facebookGroupUrl"
                    >
                      <Input
                        id="facebookGroupUrl"
                        type="text"
                        placeholder="Enter Facebook Page Group"
                      />
                    </Form.Item>
                  </div>
                  <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
                </Form>
                </dl>
              </TabPane>
            

        
            </Tabs>
          </div>
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};

ShopFacebook.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopFacebook;
