import React from "react";
import MerchantLayout from "../../components/MerchantLayouts";
import BusinessContractAddress from "../../components/Business/BusinessContractAddress";

const BusinessContractAddressPage = () => {
  return (
    <MerchantLayout>
      <BusinessContractAddress />
    </MerchantLayout>
  ); 
};

export default BusinessContractAddressPage;
