import * as React from "react";
import type { AddressCardProps } from "../../../type";

export const AddressCard: React.FC<AddressCardProps> = ({
  addressNumber,
  businessId,
  businessName,
  address,
  contactName,
  contactNumber,
  contactEmail
}) => {
  return (
    <div className="flex flex-col items-start self-stretch px-8 py-4 mt-2.5 w-full text-sm font-medium bg-white rounded-md shadow-sm text-zinc-800 max-md:px-5 max-md:max-w-full">
      <div className="flex flex-wrap gap-5 justify-between items-start self-stretch w-full font-bold max-md:max-w-full">
        <div className="flex gap-10">
          <div>Address {addressNumber}</div>
          <div>Business Address ID: {businessId}</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/34a591c5465f603d312679f9b9eea797d642f21f2eefe11407c767d19bfaaf1e?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
          className="object-contain shrink-0 w-6 aspect-square"
          alt=""
        />
      </div>
      <div>Business Name: {businessName}</div>
      <div className="mt-3 max-md:max-w-full">
        Address: {address}
      </div>
      <div className="mt-3 w-[269px]">
        Name: {contactName}<br />
        Number: {contactNumber}<br />
        Email: {contactEmail}<br />
      </div>
      <div className="flex gap-1.5 items-start mt-3.5 text-xs font-bold text-red-600">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/ac09702f01e9a079a16ec8dd741041164afed2f85488158001a3c95f5b672be7?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
          className="object-contain shrink-0 aspect-square w-[18px]"
          alt=""
        />
        <div role="button" tabIndex={0}>See Location</div>
      </div>
    </div>
  );
};