import {
  AutoComplete,
  Divider,
  Form,
  Input,
  Select,
  Spin,
  TimePicker,
  Tooltip,
} from "antd";
import axios from "axios";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DAYS, getParamValue } from "../../../utils";
import { responseNotification } from "../../../utils/notify";
import { useLocation, useNavigate, useParams } from "react-router";
import ImageUploader from "../../common/ImageUploader";
import { regions } from "../../common/Region";
import SubmitResetBtn from "../../common/SubmitBtn";

import { useDispatch, useSelector } from "react-redux";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { SET_SHOP_INFO } from "../../../redux/auth/authType";
import moment from "moment";
import React from "react";

const AddShopForm = () => {
  const { type, token, merchantId } = useSelector(
    (state) => (state as any)?.authReducer
  );

  const fetchRef = useRef<any>(0);
  const route = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [startAt, setStartAt] = useState<any>("00:00");
  const [endAt, setEndAt] = useState<any>("00:00");
  const [freeDelivery, setFreeDelivery] = useState(false);
  const [discountType, setDiscountType] = useState();
  const [limit, setLimit] = useState(16);
  const [shopAddress, setShopAddress] = useState<any>();
  const [key, setKey] = useState("");
  const [businessCountryId, setBusinessCountryId] = useState<any>();
  const [businessStateId, setBusinessStateId] = useState<any>();
  const [businessCityId, setBusinessCityId] = useState<any>(0);
  const [shopData, setShopData] = useState<any>({ loading: false, data: null });
  const [logoUrl, setLogoUrl] = useState<string>();
  const [bannerUrl, setBannerUrl] = useState<string>();

  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [stateOptions, setStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [cityOptions, setCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [geoAddress, setGeoAddress] = useState<any>({
    loading: false,
    data: null,
  });

  const [locationOptions, setLocationOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [locationListOptions, setLocationListOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [currentLocation, setCurrentLocation] = useState({
    lat: 23.77340239600077,
    lng: 90.41329051290532,
  });
  // **************************************

  // **************************************

  const getCountryOptions = useCallback(async (key?: string) => {
    setCountryOptions({ loading: true, list: [] });
    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
  }, []);

  const getStateOptions = useCallback(
    async (key?: string) => {
      setStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${businessCountryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [businessCountryId]
  );
  const getCityOptions = useCallback(
    async (key?: string) => {
      setCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${businessStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [businessStateId]
  );
  const getLocationOptions = useCallback(async (key?: string) => {
    setLocationOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_MAP_API}`;
    const res = await axios.get(
      `${encodedUri}/autocomplete` + (key ? `?key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setLocationOptions({
      loading: false,
      list: res?.data?.places,
    });

    setLocationListOptions({
      loading: false,
      list: res?.data?.places?.map((place: any) => {
        return {
          value: place?.address,
          label: place?.address,
        };
      }),
    });
  }, []);

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [form, startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    if (logoUrl || bannerUrl) {
      setLoading(true);

      const readyData = data && {
        address: data.address,
        area: data.area,
        banner: bannerUrl || data.banner,
        baseDeliveryCharge: data.baseDeliveryCharge * 1,
        countryId: businessCountryId,
        stateId: businessStateId,
        cityId: businessCityId,
        contactNumber: `+88${
          data?.contactNumber?.replace("+88", "") ||
          data?.contactNumber?.replace("+88", "")
        }`,
        country: data.country,
        description: data.description,
        dineIn: data.dineIn || false,
        displayOrder: parseInt(data.displayOrder),
        email: data.email,
        endAt: endAt || data.endAt,
        hubId: data.hubId,
        isFreeDelivery: data.isFreeDelivery || false,
        isVerified: data.isVerified || false,
        keywords: data.keywords,
        location: {
          latitude: currentLocation?.lat || data.latitude * 1,
          longitude: currentLocation?.lng || data.longitude * 1,
        },
        logo: logoUrl || data.logo,
        merchantId: merchantId,
        minimumAmountForFreeDelivery: freeDelivery
          ? null
          : parseInt(data.minimumAmountForFreeDelivery),
        mobileNumber: `+88${data?.contactNumber?.replace("+88", "")}`,
        name: data.name,
        pickUp: data.pickUp || false,
        prepareTime: data.prepareTime * 1,
        status: data.status,
        startAt: startAt || data.startAt,
        type: type,
        productSubsidyUpdateType: data.productSubsidyUpdateType,
        commissionType: data.commissionType,
        commissionAmount: parseFloat(data.commissionAmount),
        subsidy: parseFloat(data.subsidy),
      };

      if (shopData.data) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/shop`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: shopData?.data?.id,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Shop Updated Successfully", "success");
              form.resetFields();
              navigate(-1);
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(
          `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/create-shop-by-sm`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...readyData, isActive: false }),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Shop Create Successfully", "success");
              form.resetFields();
              navigate(-1);
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Shop Logo & Banner required`, "error");
    }
  };

  const fetchGeoAddress = useCallback(() => {
    try {
      setGeoAddress({ loading: true, data: null });
      axios
        .get(
          `${process.env.REACT_APP_MAP_API}/geocode?latitude=${currentLocation?.lat}&longitude=${currentLocation?.lng}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setGeoAddress({
              loading: false,
              data: data?.data?.place,
            });
          } else {
            setGeoAddress({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setGeoAddress({ loading: false, data: null });
        });
    } catch (error) {
      setGeoAddress({ loading: false, data: null });
    }
  }, [currentLocation?.lat, currentLocation?.lng]);

  const fetchShopDetails = useCallback((getShopId: any) => {
    if (getShopId) {
      try {
        setShopData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/shop/details?shopId=${getShopId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setShopData({
                loading: false,
                data: data?.data?.shop,
              });
              setLogoUrl(data?.data?.shop?.logo);
              setBannerUrl(data?.data?.shop?.banner);
              setStartAt(data?.data?.shop?.startAt);
              setEndAt(data?.data?.shop?.endAt);
              setShopAddress(data?.data?.shop?.address);
              setCountryOptions(data?.data?.shop?.countryId);
              setBusinessCountryId(data?.data?.shop?.countryId);
              setStateOptions(data?.data?.shop?.stateId);
              setCityOptions(data?.data?.shop?.city);
              setBusinessStateId(data?.data?.shop?.stateId);
              setBusinessCityId(data?.data?.shop?.cityId);

              dispatch({
                type: SET_SHOP_INFO,
                payload: {
                  shopInfo: {
                    id: data?.data?.shop?.id,
                    name: data?.data?.shop?.name,
                    type: data?.data?.shop?.type,
                    mobileNumber: data?.data?.shop?.contactNumber,
                    logo: data?.data?.shop?.logo,
                    banner: data?.data?.shop?.banner,
                    rating: data?.data?.shop?.rating,
                    status: data?.data?.shop?.status,
                    hubId: data?.data?.shop?.hub?.id,
                    merchantId: data?.data?.shop?.merchantId,
                    deleted: data?.data?.shop?.deleted,
                  },
                },
              });
            } else {
              setShopData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })

          .catch((err) => {
            setShopData({ loading: false, data: null });
          });
      } catch (error) {
        setShopData({ loading: false, data: null });
      }
    }
  }, []);

  const handleSearch = useMemo<any>(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef!.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "locations") getLocationOptions(value);
        if (field === "country") getCountryOptions(value);
        if (field === "state") getStateOptions(value);
        if (field === "city") getCityOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getLocationOptions, getCountryOptions, getStateOptions, getCityOptions]);

  useEffect(() => {
    fetchShopDetails((route as any)?.shopId);
  }, [fetchShopDetails]);

  useEffect(() => {
    getLocationOptions();
    getCountryOptions();
    getStateOptions();
    getCityOptions();
  }, [getLocationOptions, getCountryOptions, getStateOptions, getCityOptions]);

  useEffect(() => {
    if (shopData?.data) {
      form.resetFields(Object.keys(shopData.data as any));
      form.resetFields();
      setCountryOptions(shopData?.data?.countryId);
      setBusinessCountryId(shopData?.data?.countryId);
      setBusinessStateId(shopData?.data?.stateId);
      setBusinessCityId(shopData?.data?.cityId);
      setLogoUrl(shopData?.data?.logo);
      setBannerUrl(shopData?.data?.banner);
      setStateOptions(shopData?.data?.stateId);
      setCityOptions(shopData?.data?.city);
      setCurrentLocation({
        lat: shopData?.data?.location?.y,
        lng: shopData?.data?.location?.x,
      });
    }

  }, [form, shopData?.data]);

  useEffect(() => {
    if (
      shopData?.data &&
      currentLocation.lat !== shopData?.data?.location?.y &&
      currentLocation.lng !== shopData?.data?.location?.x
    ) {
      fetchGeoAddress();
    }
  }, [fetchGeoAddress, currentLocation]);

  const resetData = () => {
    // form?.resetFields();
    setLogoUrl(undefined);
    setBannerUrl(undefined);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  useEffect(() => {
    form.resetFields(["latitude", "longitude", "address"]);
  }, [currentLocation]);

  useEffect(() => {
    form.resetFields(["address"]);
  }, [geoAddress]);

  useEffect(() => {
    if (shopAddress) {
      form.resetFields(["address", "city", "area"]);
    }
  }, [shopAddress]);

  useEffect(() => {
    dispatch({
      type: SET_SHOP_INFO,
      payload: {
        shopInfo: null,
      },
    });
  }, []);

  return (
    <div className="content-body rounded-2xl">
      <div className="">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onSubmit}
          initialValues={{
            ...shopData.data,
            contactNumber: shopData.data?.contactNumber?.replace("+88", ""),
            mobileNumber: shopData.data?.contactNumber?.replace("+88", ""),
            shopEmail: shopData.data?.email,
            address: geoAddress?.data?.address || shopData.data?.address,
            latitude: currentLocation?.lat,
            longitude: currentLocation?.lng,
          }}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
        >
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl mb-6">
            <div className="grid grid-cols-2 gap-x-6">
              <div>
                <Form.Item
                  hasFeedback
                  label="Shop Name"
                  rules={[
                    {
                      required: true,
                      message: "Shop Name is Required!",
                    },
                  ]}
                  name="name"
                >
                  <Input id="name" type="text" placeholder="Enter Name" />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  hasFeedback
                  preserve={false}
                  label="Email Address"
                  rules={[
                    {
                      validator: async (_, email) => {
                        if (
                          email &&
                          !String(email)
                            .toLowerCase()
                            ?.match(
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            )
                        ) {
                          return Promise.reject(new Error("Enter valid email"));
                        }
                      },
                    },
                  ]}
                  name="email"
                >
                  <Input
                    id="email"
                    type="email"
                    placeholder="Enter Email Address"
                    autoComplete="off"
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  hasFeedback
                  label="Mobile Number"
                  rules={[
                    {
                      required: true,
                      message: "Mobile Number is required!",
                    },
                    {
                      validator: async (_, names) => {
                        if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                          return Promise.reject(
                            new Error("Enter valid mobile number")
                          );
                        }
                      },
                    },
                  ]}
                  name="contactNumber"
                  initialValue={"01"}
                >
                  <Input
                    //disabled={shopData?.data || false}
                    id="contactNumber"
                    type="contact"
                    addonBefore={"+88"}
                    placeholder="Enter Mobile Number"
                  />
                </Form.Item>
              </div>
              <Form.Item hasFeedback label={`Opening Time (${startAt})`}>
                <TimePicker
                  placeholder="Opening Time"
                  onChange={(val: any) =>
                    setStartAt(moment(val).format("HH:mm"))
                  }
                  // value={startAt}
                  format="HH:mm"
                  className="min-w-full"
                  // defaultPickerValue={moment(startAt).format("lll")}
                  value={moment(`2022-10-10 ${startAt || "00:00"}`)}
                />
              </Form.Item>

              <Form.Item hasFeedback label={`Closing Time (${endAt})`}>
                <TimePicker
                  placeholder="Closing Time"
                  onChange={(val: any) => setEndAt(moment(val).format("HH:mm"))}
                  defaultValue={endAt}
                  format="HH:mm"
                  className="min-w-full"
                  // disabledHours={() => startAt}
                  value={moment(`2022-10-10 ${endAt || "00:00"}`)}
                />
              </Form.Item>

              <div>
                <Form.Item hasFeedback label="Description" name="description">
                  <Input
                    id="description"
                    placeholder="Enter Shop Description"
                    // rows={4}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  hasFeedback
                  label="Address"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Address is required!",
                    },
                  ]}
                >
                  <Input
                    id="address"
                    type="text"
                    placeholder="Enter shops address"
                    defaultValue={shopAddress?.address}
                  />
                  {/* <AutoComplete
                    autoClearSearchValue={false}
                    allowClear
                    showSearch
                    onSearch={(val) => {
                      handleSearch?.(val, "locations");
                      setKey(val);
                    }}
                    onSelect={(val: any) => {
                      const matchData = locationOptions?.list?.find(
                        (place: any) => val === place.address
                      );
                      setCurrentLocation({
                        lat: matchData?.location?.latitude,
                        lng: matchData?.location?.longitude,
                      });
                    }}
                    options={locationListOptions?.list}
                    notFoundContent={
                      locationListOptions?.loading ? (
                        <Spin size="small" />
                      ) : null
                    }
                    //loading={locationListOptions.loading}
                    placeholder="Search Address"
                  /> */}
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  hasFeedback
                  label="Latitude"
                  name="latitude"
                  // initialValue={currentLocation?.lat}
                >
                  <Input id="latitude" type="text" placeholder="23.86450" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  hasFeedback
                  label="Longitude"
                  name="longitude"
                  // initialValue={currentLocation?.lng}
                >
                  <Input id="longitude" type="text" placeholder="90.53680" />
                </Form.Item>
              </div>

              <div>
                <Form.Item hasFeedback label="Area" name="area">
                  <Input
                    id="area"
                    type="text"
                    placeholder="Enter shops area"
                    defaultValue={shopAddress?.area}
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  hasFeedback
                  label={`Country/Region`}
                  rules={[
                    {
                      required: false,
                      message: "Region is required!",
                    },
                  ]}
                  name="businessCountry"
                  initialValue={countryOptions}
                > 
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Country"
                    optionFilterProp="children"
                    defaultValue={businessCountryId}
                    onChange={(e) => setBusinessCountryId(e)}
                    onSearch={(val) => {
                      handleSearch?.(val, "country");
                    }}
                    filterOption={() => {
                      return true;
                    }}
                    options={countryOptions?.list}
                  ></Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  hasFeedback
                  label={`State/Province`}
                  rules={[
                    {
                      required: false,
                      message: "State is required!",
                    },
                  ]}
                  name="businessState"
                  initialValue={stateOptions}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select State"
                    optionFilterProp="children"
                    defaultValue={businessStateId}
                    onChange={(val) => setBusinessStateId(val)}
                    onSearch={(val) => {
                      handleSearch?.(val, "state");
                    }}
                    filterOption={() => {
                      return true;
                    }}
                    options={stateOptions?.list}
                  ></Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  hasFeedback
                  label={`City`}
                  rules={[
                    {
                      required: false,
                      message: "City is required!",
                    },
                  ]}
                  name="businessCity"
                  initialValue={cityOptions}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select City"
                    optionFilterProp="children"
                    defaultValue={businessCityId}
                    onChange={(val) => setBusinessCityId(val)}
                    onSearch={(val) => {
                      handleSearch?.(val, "city");
                    }}
                    filterOption={() => {
                      return true;
                    }}
                    options={cityOptions?.list}
                  ></Select>
                </Form.Item>
              </div>
            </div>

            <div className="flow-root mt-6">
              <div className="grid grid-cols-2 mt-2">
                <div className="mr-4">
                  <Divider orientation="left">
                    Logo
                    <Tooltip
                      placement="bottom"
                      title={
                        "Shop Logo should be square. We prefer height 200px and width 200px (Ratio 1)"
                      }
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>
                  <ImageUploader
                    imgUrl={logoUrl || ""}
                    startUpload={startUpload}
                    setStartUpload={(val: any) => {
                      setStartUpload(val);
                    }}
                    setGeneratedImgUrl={(url: any) => {
                      setLogoUrl(url);
                      dispatch({
                        type: SET_SHOP_INFO,
                        payload: {
                          shopInfo: {
                            logo: url,
                            // banner: data?.data?.shop?.banner,
                          },
                        },
                      });
                    }}
                  />
                </div>
                <div className="">
                  <Divider orientation="left">
                    Banner
                    <Tooltip
                      placement="bottom"
                      title={
                        "Shop Banner image should 1200px/500px (Ratio 2.4)"
                      }
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>

                  <ImageUploader
                    imgUrl={bannerUrl || ""}
                    startUpload={startUpload}
                    setStartUpload={(val: any) => setStartUpload2(val)}
                    setGeneratedImgUrl={(url: any) => setBannerUrl(url)}
                  />
                </div>
              </div>
            </div>

            <div className="my-6">
              <SubmitResetBtn
                onClickSubmit={(e) => {
                  e.preventDefault();
                  setStartUpload("Uploading");
                  setStartUpload2("Uploading");
                }}
                onClickReset={resetData}
                disabled={
                  loading ||
                  startUpload === "Uploading" ||
                  startUpload2 === "Uploading"
                }
                loading={
                  loading ||
                  startUpload === "Uploading" ||
                  startUpload2 === "Uploading"
                }
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddShopForm;
