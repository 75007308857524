import * as React from "react";
import { AddressCard } from "../MemberShipProfile/common/AddressCard";

const addresses = [
  {
    addressNumber: "1",
    businessId: "458789",
    businessName: "Cosmos Holiday",
    address: "House 26, Road 2, Block D, Flat 4, Gulshan, Dhaka 1212, Bangladesh",
    contactName: "Elius",
    contactNumber: "01921328129",
    contactEmail: "emailname@gmail.com"
  },
  {
    addressNumber: "2", 
    businessId: "458789",
    businessName: "Cosmos Holiday",
    address: "House 07, Road 5, Block C, Flat 9, Banani, Dhaka 1201, Bangladesh",
    contactName: "Obydul",
    contactNumber: "01921329897",
    contactEmail: "emailname@gmail.com"
  }
];

const toggleAddresses = [
  {
    title: "Seller Warehouse Address",
    subtitle: "Same as business Address",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/245ce59ef6c68bccf1833f86231e135675f5f5349e20d53b5aea865f9b72d8be?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
  },
  {
    title: "Seller Pickup Address", 
    subtitle: "Same as business Address",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/26652cf456c72b1fa518bd764c7c812875b362028d68058078e797fb58ebf806?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
  },
  {
    title: "Seller Return Address",
    subtitle: "Same as business Address", 
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5bdfc4c0ae74694f2617389abfb7a99f42cf129b51913131077ca0c509d9618b?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
  },
  {
    title: "Platform Warehouse Address",
    subtitle: "Same as business Address",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b3a24c9a0e7696e4dcb894dc8fe86a202db9a8029358f8ae6ebbf46c9d86577d?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
  }
];

export const BusinessAddressPage: React.FC = () => {
  return (
    <div className="flex overflow-hidden flex-col bg-stone-50 pb-[871px] max-md:pb-24">


      <main className="flex flex-col items-start self-center mt-5 max-w-full w-[1170px]">
        <div className="flex gap-2 text-base font-bold text-zinc-800">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/09377c01518cc77840606da399144664ae5cd7694093b7933b609bfdb8741f57?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
            className="object-contain shrink-0 w-6 aspect-square"
            alt=""
          />
          <div className="basis-auto">Business & Contact address</div>
        </div>

        {addresses.map((address, index) => (
          <AddressCard key={index} {...address} />
        ))}

        <div className="mt-5 ml-8 text-base font-bold text-cyan-700 max-md:ml-2.5">
          Add More Business Address
        </div>

        <div className="flex flex-col gap-6 mt-8 ml-8 max-w-full w-[339px] max-md:ml-2.5">
          <h1>ToggleAddress</h1>
        </div>
      </main>
    </div>
  );
};
export default BusinessAddressPage;