import {
  Avatar,
  Button,
  Card,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Image,
  Tooltip,
  Switch,
  Checkbox,
  InputNumber,
  DatePickerProps,
  DatePicker,
  Tabs,
} from "antd";
import {
  CloseOutlined,
  GiftOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { debounce } from "lodash";
import cn from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { getQueryParams, priceCalculationType } from "../../utils";
import { jodiConfig } from "../../utils/editor-config";
import { responseNotification } from "../../utils/notify";
import { useLocation, useNavigate, useParams } from "react-router";
import BreadCrumb from "../Layouts/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import JoditEditor from "jodit-react";
import Styles from "./Product.module.css";
import { ImageUploader, SubmitResetBtn, TreeSelectTwo } from "../common";
import dayjs from "dayjs";
const { TabPane } = Tabs;
const { Search } = Input;
const Option = Select;

const AddRequestedProduct = (props: any) => {
  const editor = useRef(null);
  const { inShop, getShopId } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const route = useParams();
  const url = location?.pathname;

  const [activeKey, setActiveKey] = React.useState("1");
  const onKeyChange = (key) => setActiveKey(key);

  const segment = url.substring(url.lastIndexOf("/") + 1);
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [priceCalcType, setPriceCalcType] = useState("SHOP_BASED_COMMISSION");
  const [warrantyType, setWarrantyType] = useState("REPLACEMENT_WARRANTY");
  const [commissionType, setCommissionType] = useState("PERCENTAGE");
  const [productType, setProductType] = useState("REGULAR");
  const [auctionType, setAuctionType] = useState("NORMAL");
  const [convertedText, setConvertedText] = useState("");
  const [convertedTextForFAQ, setConvertedTextForFAQ] = useState("");
  const [convertedTextForTermCondition, setConvertedTextForTermCondition] =
    useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState(false);
  const [saveAndNew, setSaveAndNew] = useState(false);
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState<boolean>(false);

  const [allowFreeShipping, setAllowFreeShipping] = useState<any>(false);
  const [allowPriorityDelivery, setAllowPriorityDelivery] =
    useState<any>(false);
  const [allowStandardDelivery, setAllowStandardDelivery] =
    useState<any>(false);
  const [allowEconomyDelivery, setAllowEconomyDelivery] = useState<any>(false);

  const [isBattery, setIsBattery] = useState<any>(false);
  const [isLiquid, setIsLiquid] = useState<any>(false);
  const [isFlammable, setIsFlammable] = useState<any>(false);
  const [isNone, setIsNone] = useState<any>(false);
  const [shippingCurrency, setShippingCurrency] = useState<any>("BDT");

  const [variationImageUrl, setVariationImageUrl] = useState<string[]>([]);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [generatedThumbUrl, setGeneratedThumbUrl] = useState<string>();
  const [shopId, setShopId] = useState<string>(getShopId);
  const [selectedProduct, setSelectedProduct] = useState<any>(undefined);
  const [discountImageUrl, setDiscountImageUrl] = useState<string[]>([]);
  const [shippingTypes, setShippingTypes] = useState<string[]>([]);

  const [skuValue, setSkuValue] = useState<any>();
  const [productLanguage, setProductLanguage] = useState<any>("en");
  const [endingDate, setEndingDate] = useState<DatePickerProps["value"]>();
  const [productWeightUnit, setProductWeightUnit] = useState<any>("kg");
  const [dimensionUnit, setDimensionUnit] = useState<any>("cm");
  const [categoryId, setCategoryId] = useState<any>();
  const [readOnly, setReadOnly] = useState(false);
  const [productId, setProductId] = useState<any>();
  const [isNotBrand, setIsNotBrand] = useState(false);
  const [productVideoUrl, setProductVideoUrl] = useState(1);
  const [productCondition, setProductCondition] = useState();
  const [isPreOrder, setIsPreOrder] = useState<boolean>(false);
  const [isSpecialDiscount, setIsSpecialDiscount] = useState<boolean>(false);
  const [isAllowWarranty, setIsAllowWarranty] = useState<boolean>(false);
  const [isReturnable, setIsReturnable] = useState<boolean>(false);
  const [giftMessaged, setGiftMessaged] = useState<any>("false");
  //new text editor
  const [tagData, setTagData] = useState<any>({
    loading: false,
    data: null,
  });

  const [manufactureDate, setManufactureDate] =
    useState<DatePickerProps["value"]>();
  const [restockDateTime, setRestockDateTime] =
    useState<DatePickerProps["value"]>();
  const [expireDate, setExpireDate] = useState<DatePickerProps["value"]>();
  const [startingDate, setStartingDate] = useState<DatePickerProps["value"]>();
  const [discountType, setDiscountType] = useState<{
    [key: string]: {
      type: "disFlat" | "disPercentage" | null;
      value: undefined | number;
    };
  }>({});

  const [qtyDiscountType, setQtyDiscountType] = useState<{
    [key: string]: {
      type: "freeProduct" | "qtyFlat" | "qtyPercentage" | null;
      value: undefined | number;
      minimumQuantity: number;
    };
  }>({});

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded" | any
  >(undefined);

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded" | any
  >(undefined);

  const [singleProductInfo, setSingleProductInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const [campaignOptions, setCampaignOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [brandsOptions, setBrandsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  // const [languageOptions, setLanguageOptions] = useState<any>({
  //   list: [],
  //   loading: false,
  // });

  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });

  function productSKU(length: any) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setSkuValue(result.toUpperCase());
    return result;
  }

  useEffect(() => {
    if (skuValue) {
      form.resetFields(["sku"]);
    }
  }, [skuValue]);

  const multi = (getQueryParams() as any)?.for === "multiple-shop";

  const fetchProductDetails = useCallback((productSlug: any) => {
    if (productSlug) {
      if (segment === "edit") {
        try {
          setSingleProductInfo({ loading: true, data: null });
          axios
            .get(
              `${process.env.REACT_APP_CATALOG_READER_API}/merchant/product/details?slug=${productSlug}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((data) => {
              if (data.status === 200) {
                setSingleProductInfo({
                  loading: false,
                  data: data?.data?.product,
                });
                setConvertedText(data?.data?.product?.description || "");
                setConvertedTextForFAQ(data?.data?.product?.faq || "");
                setCategoryId(singleProductInfo?.data?.category?.id);
                setConvertedTextForTermCondition(
                  data?.data?.product?.termCondition || ""
                );
                setVariationImageUrl(
                  data?.data?.product?.variations?.map(
                    (item: any) => item?.imageUrl
                  )
                );

                setDiscountImageUrl(
                  data?.data?.product?.variations?.map(
                    (item: any) =>
                      item?.quantitativeProductDiscount?.freeProduct
                        ?.productImage
                  )
                );
                setIsActive(data?.data?.product?.isActive);
                setGeneratedThumbUrl(data?.data?.product?.productImage);
                setGeneratedImgUrl(data?.data?.product?.images);

                const diss = {} as any;
                data?.data?.product?.variations?.map((va: any, i: string) => {
                  diss[i] = {
                    type: va?.productDiscount?.flat
                      ? "disFlat"
                      : va?.productDiscount?.percentage
                      ? "disPercentage"
                      : null,
                    value:
                      va?.productDiscount?.flat ||
                      va?.productDiscount?.percentage ||
                      0,
                  };
                });
                const qtyDis = {} as any;
                data?.data?.product?.variations?.map((va: any, i: string) => {
                  qtyDis[i] = {
                    type: va?.quantitativeProductDiscount?.freeProduct
                      ? "freeProduct"
                      : va?.quantitativeProductDiscount?.productDiscount?.flat
                      ? "qtyFlat"
                      : va?.quantitativeProductDiscount?.productDiscount
                          ?.percentage
                      ? "qtyPercentage"
                      : null,
                    value:
                      va?.quantitativeProductDiscount?.freeProduct ||
                      va?.quantitativeProductDiscount?.productDiscount?.flat ||
                      va?.quantitativeProductDiscount?.productDiscount
                        ?.percentage ||
                      0,
                    minimumQuantity:
                      va?.quantitativeProductDiscount?.minimumQuantity,
                  };
                });

                setDiscountType(diss);
                setQtyDiscountType(qtyDis);
              } else {
                setSingleProductInfo({ loading: false, data: null });
                responseNotification(
                  data.statusText || "something went wrong",
                  "error"
                );
              }
            })
            .catch((err) => {
              setSingleProductInfo({ loading: false, data: null });
            });
        } catch (error) {
          setSingleProductInfo({ loading: false, data: null });
        }
      } else {
        try {
          setSingleProductInfo({ loading: true, data: null });
          axios
            .get(
              `${process.env.REACT_APP_CATALOG_READER_API}/global-product/details?slug=${productSlug}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((data) => {
              if (data.status === 200) {
                setSingleProductInfo({
                  loading: false,
                  data: data?.data?.product,
                });
                setConvertedText(data?.data?.product?.description || "");
                setConvertedTextForFAQ(data?.data?.product?.faq || "");
                setCategoryId(singleProductInfo?.data?.category?.id);
                setConvertedTextForTermCondition(
                  data?.data?.product?.termCondition || ""
                );
                setVariationImageUrl(
                  data?.data?.product?.variations?.map(
                    (item: any) => item?.imageUrl
                  )
                );

                setDiscountImageUrl(
                  data?.data?.product?.variations?.map(
                    (item: any) =>
                      item?.quantitativeProductDiscount?.freeProduct
                        ?.productImage
                  )
                );
                setIsActive(data?.data?.product?.isActive);
                setGeneratedThumbUrl(data?.data?.product?.productImage);
                setGeneratedImgUrl(data?.data?.product?.images);

                const diss = {} as any;
                data?.data?.product?.variations?.map((va: any, i: string) => {
                  diss[i] = {
                    type: va?.productDiscount?.flat
                      ? "disFlat"
                      : va?.productDiscount?.percentage
                      ? "disPercentage"
                      : null,
                    value:
                      va?.productDiscount?.flat ||
                      va?.productDiscount?.percentage ||
                      0,
                  };
                });
                const qtyDis = {} as any;
                data?.data?.product?.variations?.map((va: any, i: string) => {
                  qtyDis[i] = {
                    type: va?.quantitativeProductDiscount?.freeProduct
                      ? "freeProduct"
                      : va?.quantitativeProductDiscount?.productDiscount?.flat
                      ? "qtyFlat"
                      : va?.quantitativeProductDiscount?.productDiscount
                          ?.percentage
                      ? "qtyPercentage"
                      : null,
                    value:
                      va?.quantitativeProductDiscount?.freeProduct ||
                      va?.quantitativeProductDiscount?.productDiscount?.flat ||
                      va?.quantitativeProductDiscount?.productDiscount
                        ?.percentage ||
                      0,
                    minimumQuantity:
                      va?.quantitativeProductDiscount?.minimumQuantity,
                  };
                });

                setDiscountType(diss);
                setQtyDiscountType(qtyDis);
              } else {
                setSingleProductInfo({ loading: false, data: null });
                responseNotification(
                  data.statusText || "something went wrong",
                  "error"
                );
              }
            })
            .catch((err) => {
              setSingleProductInfo({ loading: false, data: null });
            });
        } catch (error) {
          setSingleProductInfo({ loading: false, data: null });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (saveAndNew) {
      setStartUpload("Uploading");
      setStartUpload2("Uploading");
    }
  }, [saveAndNew]);

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    if (generatedImgUrl && generatedThumbUrl) {
      setLoading(true);
      let readyData = data && {
        barCode: data.barCode,
        productType: productType || data.productType,
        sku: data.sku || skuValue,
        productOrigin: data.productOrigin,
        productVideoUrl: data.productVideoUrl,
        type: type,
        displayOrder: parseInt(data.displayOrder),
        priceCalculationType: priceCalcType || data.priceCalculationType,
        shopId: getShopId,
        categoryId: categoryId,
        brandId: data.brandId,
        campaignId: data.campaignId,
        slug: data.slug,
        manufacturerName: data.manufacturerName,
        manufacturerContactInfo: data.manufacturerContactInfo,
        description: convertedText || "<p></p>",
        faq: convertedTextForFAQ || "<p></p>",
        termsCondition: convertedTextForTermCondition || "<p></p>",
        metaTitle: data.metaTitle,
        keyword: data.keyword,
        metaDescription: data.metaDescription,
        canonicalTag: data.canonicalTag,
        imageTitle: data.imageTitle,
        imageAltrText: data.imageAltrText,
        imageCaption: data.imageCaption,
        imageDescription: data.imageDescription,
        isPreOrder: isPreOrder,
        paymentTimeInSeconds: isPreOrder
          ? data.paymentTimeInSeconds * 60 * 60
          : 0,
        prePaidAmountInPercentage: isPreOrder
          ? data.prePaidAmountInPercentage
          : 0,
        isForCheckOut: data.isForCheckOut,
        isPopular: data.isPopular,
        excludeCOD: data.excludeCOD,
        excludeFromPromo: data.excludeFromPromo,
        isAllowWarranty: data.isAllowWarranty,
        warrantyPolicy: data.warrantyPolicy,
        warrantyTimeInMonth: data.warrantyTimeInMonth,
        warrantyType: warrantyType,
        isReturnable: data.isReturnable,
        returnRefundTimeInMonth: data.returnRefundTimeInMonth,
        returnRefundPolicy: data.returnRefundPolicy,
        isActive: isActive,
        commissionType: data.commissionType,
        commissionAmount: Math.round(data.commissionAmount) || 0,
        productImage: generatedThumbUrl,
        images: generatedImgUrl,
        productVideoUrl: data.productVideoUrl,
        shippingInfo: {
          allowEconomyDelivery: allowEconomyDelivery,
          allowFreeShipping: allowFreeShipping,
          allowPriorityDelivery: allowPriorityDelivery,
          allowStandardDelivery: allowStandardDelivery,
          dimension: {
            height: data.dimensionHeight,
            length: data.dimensionLength,
            unit: dimensionUnit,
            width: data.dimensionWidth,
          },
          unit: productWeightUnit,
          weight: data.weight,
        },
        dangerousGoodsInfo: {
          isBattery: isBattery,
          isFlammable: isFlammable,
          isLiquid: isLiquid,
          safetyWarning: data.safetyWarning,
        },
        gondolaDetails: {
          rowNumber: data.rowNumber,
          selfNumber: data.selfNumber,
        },
        auction: {
          auctionType: auctionType,
          startingPrice: data.startingPrice,
          minimumIncrementAmount: data.minimumIncrementAmount,
          reservePrice: data.reservePrice,
          isBuyNowEnable: data.isBuyNowEnable,
          isSealed: data.isSealed,
          startDate: startingDate,
          endDate: endingDate,
          hasEnded: data.hasEnded,
        },
        productCondition: productCondition,
        iswrapAvailable: giftMessaged,
        handlingTimeFrom: data.handlingTimeFrom,
        handlingTimeTo: data.handlingTimeTo,
        manufactureDate: manufactureDate,
        expireDate: expireDate,
        restockDateTime: restockDateTime,

        name: productName || data.name,
        nameWithLanguages: nameWithLanguages?.map(
          (nameWithLang: any, langIndex: string) => ({
            languageCode: nameWithLang.languageCode,
            name: nameWithLang.name,
          })
        ),
        accessories: accessories?.map(
          (accessoriesProduct: any, accessPIndex: string) => ({
            id: accessoriesProduct.acessoriesName,
          })
        ),
        variations: data?.variations?.map((variant: any, i: string) => ({
          imageUrl: variant.imageUrl,
          barCode: variant.barCode,
          variationId: variant.variationId,
          variationName: variant.variationName,
          regularVariationId: data?.variations?.[i + 1]?.variationId,
          subsidyAmount: Math.round(variant?.subsidyAmount || 0),
          subsidyType: variant?.subsidyType,
          maximumOrderLimit: variant.maximumOrderLimit
            ? parseInt(variant.maximumOrderLimit)
            : undefined,
          stock: Math.round(variant.stock) || 0,
          platformProfit: Math.round(variant.platformProfit) || 0,
          sellerPurchasePrice: Math.ceil(variant.sellerPurchasePrice) * 1 || 0,
          upcNumber: variant.upcNumber,
          jsinnumber: variant.jsinnumber,
          pointAmount: variant.pointAmount,
          sellerProfit: variant.sellerProfit,
          price: {
            mrp: Math.ceil(variant.mrp) * 1 || 10,
            tp:
              priceCalcType == "TP_BASED_COMMISSION"
                ? Math.round(variant.tp) * 1
                : 0,
          },
          productDiscount: {
            flat: Math.round(variant.flat) || 0,
            percentage: Math.round(variant.percentage) || 0,
          },
          specialDiscountPrice: {
            discountPrice: variant?.discountPrice,
            endAt: variant?.endAt,
            startAt: variant?.startAt,
          },
          attributes: variant.attributes?.map(
            (attribute: any, attributeIndex: string) => ({
              attributeId: attribute.attributeId,
              imageUrl: attribute.imageUrl,
              name: attribute.name,
            })
          ),
          quantitativeProductDiscount: {
            freeProductId:
              variant?.quantitativeProductDiscount?.freeProductId || null,
            minimumQuantity:
              variant?.quantitativeProductDiscount?.minimumQuantity || 0,
            productDiscount: {
              flat:
                parseFloat(
                  variant?.quantitativeProductDiscount?.productDiscount.flat
                ) || 0,
              percentage:
                parseFloat(
                  variant?.quantitativeProductDiscount?.productDiscount
                    .percentage
                ) || 0,
            },
          },
        })),
      };

      if (singleProductInfo?.data && segment === "edit") {
        await fetch(
          `${process.env.REACT_APP_CATALOG_WRITER_API}/merchant/product${
            multi ? `/multiple-shop` : ``
          }`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...readyData,
              id: singleProductInfo?.data?.id,
              // isActive: isActive,
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Product Updated Successfully", "success");
              form.resetFields();
              fetchProductDetails((route as any)?.slug);
              // if (history?.length === 1) {
              //   navigate(`/products`);
              // } else {
              //   navigate(-1);
              // }
              navigate(-1);
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(
          `${process.env.REACT_APP_CATALOG_WRITER_API}/merchant/product`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...readyData,
              deleted: false,
              isActive: isActive,
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Product Create Successfully", "success");
              if (saveAndNew) {
                console.log(saveAndNew);
              } else {
                form.resetFields();
                navigate(-1);
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Product Thumbnail & Image required`, "error");
    }
  };

  // **********************************
  // Search Options
  // **********************************

  const getCampaignOptions = useCallback(async (val?: string) => {
    setCampaignOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/admin/campaign?type=${type}&page=0&limit=20` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCampaignOptions({
      loading: false,
      list: res?.data?.campaigns?.map((campaign: any) => {
        return {
          value: campaign?.id,
          label: campaign?.name,
        };
      }),
    });
  }, []);

  const getBrandsOptions = useCallback(async (val?: string) => {
    setBrandsOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/brand/search?type=${type}&page=0&limit=20` +
        (val ? `&key=${val}` : ``),

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandsOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => {
        return {
          value: brand?.id,
          label: brand?.name,
        };
      }),
    });
  }, []);

  const getShopsOptions = useCallback(async (val?: string) => {
    setShopsOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/shop/admin/search?type=${type}&page=0&limit=20` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShopsOptions({
      loading: false,
      list: res?.data?.shops?.map((shop: any) => {
        return {
          value: shop?.id,
          label: shop?.name,
        };
      }),
    });
  }, []);

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      setAccessoriesProduct({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/global-product?type=${type}&page=0&limit=20` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.products?.map((product: any) => ({
              label: product?.name,
              value: product?.slug,
            })),
          });

          setAccessoriesProductOptions(res.data?.products);
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("products: Error", err);
        });
    },
    [type]
  );

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "brand") getBrandsOptions(value);
          else if (field === "shop") getShopsOptions(value);
          else if (field === "product") getProductOptions(value);
          else if (field === "campaing") getCampaignOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getBrandsOptions,
    getShopsOptions,
    getProductOptions,
    getCampaignOptions,
    type,
  ]);

  const prePaidAmountPreventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const paymentTimePreventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  // **********************************
  // Search Options

  // **********************************
  useEffect(() => {
    if (showSearch) {
      getProductOptions("");
    }
  }, [showSearch]);
  useEffect(() => {
    getShopsOptions();
    getCampaignOptions();
    getBrandsOptions();
  }, [getShopsOptions, getCampaignOptions, getBrandsOptions]);

  useEffect(() => {
    fetchProductDetails((route as any)?.slug);
  }, [fetchProductDetails, route]);

  useEffect(() => {
    if (singleProductInfo?.data) {
      setShopsOptions({
        loading: false,
        list: [
          {
            value: singleProductInfo?.data?.shop?.id,
            label: singleProductInfo?.data?.shop?.name,
          },
          ...(shopsOptions.list?.filter(
            (item) => item.value !== singleProductInfo?.data?.shop?.id
          ) || []),
        ],
      });

      setBrandsOptions({
        loading: false,
        list: [
          {
            value: singleProductInfo?.data?.brand?.id,
            label: singleProductInfo?.data?.brand?.name,
          },
          ...(brandsOptions.list?.filter(
            (item) => item.value !== singleProductInfo?.data?.brand?.id
          ) || []),
        ],
      });

      setCampaignOptions({
        loading: false,
        list: [
          {
            value: singleProductInfo?.data?.campaign?.id,
            label: singleProductInfo?.data?.campaign?.name,
          },
          ...(campaignOptions.list?.filter(
            (item) => item.value !== singleProductInfo?.data?.campaign?.id
          ) || []),
        ],
      });

      form.resetFields(Object.keys(singleProductInfo?.data));
      form.resetFields();

      setPriceCalcType(singleProductInfo?.data?.priceCalculationType);
      setProductType(singleProductInfo?.data?.productType);
      setAuctionType(singleProductInfo?.data?.auction?.auctionType);
      setWarrantyType(singleProductInfo?.data?.warrantyType);
      setCommissionType(singleProductInfo?.data?.commissionType);
      setEndingDate(singleProductInfo?.data?.auction?.endDate);
      setShopId(singleProductInfo?.data?.shopId);
      setIsPreOrder(singleProductInfo?.data?.isPreOrder);
      setIsAllowWarranty(singleProductInfo?.data?.isAllowWarranty);
      setIsReturnable(singleProductInfo?.data?.isReturnable);
      setExpireDate(singleProductInfo?.data?.expireDate);
      setRestockDateTime(singleProductInfo?.data?.restockDateTime);
      setManufactureDate(singleProductInfo?.data?.manufactureDate);
      setCategoryId(singleProductInfo?.data?.category?.id);
      setProductName(singleProductInfo?.data?.name);
      setLanguages(singleProductInfo?.data?.nameWithLanguages || []);
      setAccessories(singleProductInfo?.data?.accessories || []);

      setGeneratedThumbUrl(singleProductInfo?.data?.productImage);
      setGeneratedImgUrl(singleProductInfo?.data?.images || []);
      setVariationImageUrl(
        singleProductInfo?.data?.variations?.map((item: any) => item?.imageUrl)
      );
    }
  }, [form, singleProductInfo?.data]);

  const resetData = () => {
    form?.resetFields();
    setVariationImageUrl([]);
    setGeneratedImgUrl(singleProductInfo?.data?.images || []);
    setGeneratedThumbUrl(singleProductInfo?.data?.productImage);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  const ProductWightSelector = (
    <Select
      defaultValue="kg"
      style={{ width: 100 }}
      className="text-left"
      onChange={(val) => setProductWeightUnit(val)}
    >
      <Option value="kg">Kilogram</Option>
      <Option value="g">Gram</Option>
      <Option value="mg">Milligram</Option>
      <Option value="µg">Microgram</Option>
      <Option value="t">Tonne</Option>
    </Select>
  );

  const [accessoriesProduct, setAccessoriesProduct] = useState({
    loading: false,
    list: [],
  });
  const [accessoriesProductOptions, setAccessoriesProductOptions] =
    useState<any>([]);
  const [accessories, setAccessories] = useState<any>([]);
  const [nameWithAccessories, setNameWithAccessories] = useState<any>([]);
  const removeProductAccessories = (index) => {
    const updatedAccessories = [...accessories];
    updatedAccessories.splice(index, 1);
    setAccessories(updatedAccessories);

    const updatedNameWithAccessories = [...nameWithAccessories];
    updatedNameWithAccessories.splice(index, 1);
    setNameWithAccessories(updatedNameWithAccessories);
  };

  const addProductAccessories = () => {
    setAccessories([...accessories, { acessoriesName: "" }]);
  };

  const handleProductAccessoriesChange = (index, value) => {
    const updatedAccessories = [...accessories];
    updatedAccessories[index].acessoriesName = value;
    setAccessories(updatedAccessories);
  };

  const [productName, setProductName] = useState("");
  const [languages, setLanguages] = useState<any>([]);
  const [languageOptions, setLanguageOptions] = useState<any>([]);
  const [nameWithLanguages, setNameWithLanguages] = useState<any>([]);

  const fetchLanguages = async () => {
    try {
      const response = await fetch(
        "https://driver-service.ejachai.com/api/v1/admin/country-state/all-languages"
      );
      const data = await response.json();
      if (data.statusCode === 200) {
        const options: any = Object.entries(data.languages).map(
          ([code, name]) => ({ code, name })
        );
        setLanguageOptions(options);
      } else {
        console.error("Failed to fetch languages:", data.message);
      }
    } catch (error) {
      console.error("Failed to fetch languages:", error);
    }
  };
  useEffect(() => {
    fetchLanguages();
  }, []);

  const addLanguage = () => {
    setLanguages([...languages, { language: "", translatedName: "" }]);
  };

  const removeLanguage = (index) => {
    const updatedLanguages = [...languages];
    updatedLanguages.splice(index, 1);
    setLanguages(updatedLanguages);

    const updatedNameWithLanguages = [...nameWithLanguages];
    updatedNameWithLanguages.splice(index, 1);
    setNameWithLanguages(updatedNameWithLanguages);
  };

  const handleLanguageChange = (index, value) => {
    const updatedLanguages = [...languages];
    updatedLanguages[index].language = value;
    setLanguages(updatedLanguages);
  };

  const handleTranslate = async (index) => {
    const { language } = languages[index];
    const response = await fetch(
      `https://catalog-reader.ejachai.com/api/v1/translator/translate?fromLanguageCode=en&toLanguageCode=${language}&text=${productName}`
    );
    const data = await response.json();

    const translatedText = data.translation.translatedText;

    const updatedLanguages = [...languages];
    updatedLanguages[index].translatedName = translatedText;
    setLanguages(updatedLanguages);

    const updatedNameWithLanguages = [...nameWithLanguages];
    updatedNameWithLanguages[index] = {
      languageCode: language,
      name: translatedText,
    };
    setNameWithLanguages(updatedNameWithLanguages);
  };

  return (
    <>
      <BreadCrumb
        title={singleProductInfo?.data ? "Edit Product" : "Add Product"}
        extra={[
          <Button
            size="large"
            className="bg-green-600 hover:bg-green-500 hover:text-white border-none text-white font-semibold rounded-lg mr-1"
            onClick={(e) => {
              e.preventDefault();
              setSaveAndNew(true);
            }}
            disabled={loading}
            loading={loading}
          >
            Save & New
          </Button>,
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
              setStartUpload2("Uploading");
            }}
            onClickReset={resetData}
            disabled={loading}
            loading={loading}
          />,
        ]}
      />

      <div className="content-body rounded-2xl">
        <div className="">
          <Form
            name="control-hooks"
            form={form} // like ref
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onSubmit}
            initialValues={{
              ...singleProductInfo?.data,
              type: type || singleProductInfo?.data?.shop?.type,
              startingPrice: singleProductInfo?.data?.auction?.startingPrice,
              minimumIncrementAmount:
                singleProductInfo?.data?.auction?.minimumIncrementAmount,
              reservePrice: singleProductInfo?.data?.auction?.reservePrice,
              isBuyNowEnable: singleProductInfo?.data?.auction?.isBuyNowEnable,
              isSealed: singleProductInfo?.data?.auction?.isSealed,
              hasEnded: singleProductInfo?.data?.auction?.hasEnded,
              sku: skuValue || singleProductInfo?.data?.sku,
              paymentTimeInSeconds:
                singleProductInfo?.data?.paymentTimeInSeconds / 60 / 60,
              prefix: "86",
              manufactureDate: dayjs(singleProductInfo?.data?.manufactureDate),
              expireDate: dayjs(singleProductInfo?.data?.expireDate),
              restockDateTime: dayjs(singleProductInfo?.data?.restockDateTime),
              startDate: dayjs(singleProductInfo?.data?.auction?.startDate),
              endDate: dayjs(singleProductInfo?.data?.auction?.endDate),
              selfNumber: singleProductInfo?.data?.gondolaDetails?.selfNumber,
              rowNumber: singleProductInfo?.data?.gondolaDetails?.rowNumber,
            }}
            layout="vertical"
            autoComplete="off"
          >
            <div
              className={
                inShop
                  ? "grid grid-cols-2 xl:grid-cols-1 xl:gap-6"
                  : "grid grid-cols-1 px-2 pt-4 xl:grid-cols-3 xl:gap-6"
              }
            >
              {/* add tabs */}
              <Tabs
                defaultActiveKey="1"
                activeKey={activeKey}
                onChange={onKeyChange}
              >
                <TabPane tab="Vital Info" key="1">
                  <div
                    className={
                      inShop ? "col-span-full" : `grid-cols-3 lg:col-span-2`
                    }
                  >
                    <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 grid grid-cols-2 gap-x-4">
                      <h3 className="mb-4 text-xl font-bold col-span-2">
                        General Info
                      </h3>

                      <div className="col-span-2 mb-2">
                        <Form.Item name="shop_search">
                          <Select
                            allowClear
                            showSearch
                            placeholder="Filter by Product SKU,  Name,  Barcode, JSIN, UPC, GTIN, EAN, GCID, ISBN"
                            optionFilterProp="children"
                            onChange={(val) => fetchProductDetails(val)}
                            //onChange={(val) => setProductId(val)}
                            onSearch={(e) => handleSearch(e, "product")}
                            filterOption={() => {
                              return true;
                            }}
                            options={productOptions?.list}
                          ></Select>
                        </Form.Item>
                        {/* <Search placeholder="Product SKU,  Name,  Barcode, JSIN, UPC, GTIN, EAN, GCID, ISBN" /> */}
                      </div>
                      <div className="col-span-2 mb-1">
                        <Form.Item
                          hasFeedback
                          label="Product Name ( English )"
                          name="name"
                        >
                          <Input
                            type="text"
                            id="name"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            onBlur={() => productSKU(8)}
                            className="py-1.5 px-5 rounded-md border border-gray-200 w-full"
                            placeholder="Enter Your Product Name"
                          />
                        </Form.Item>
                      </div>

                      <div>
                        {languages.map((lang, index) => (
                          <div
                            key={index}
                            className="col-span-2 mb-2"
                            style={{ display: "flex", marginBottom: 8 }}
                          >
                            <div>
                              <select
                                value={lang.language}
                                onChange={(e) =>
                                  handleLanguageChange(index, e.target.value)
                                }
                                className="mb-2 mr-2 w-40 border border-gray-200 py-2 px-5 rounded-md"
                              >
                                <option value="">Select Language</option>
                                {languageOptions.map(({ code, name }) => (
                                  <option
                                    key={code}
                                    value={code}
                                    selected={
                                      code ===
                                      singleProductInfo?.data
                                        ?.nameWithLanguages?.[index]
                                        ?.languageCode
                                    }
                                  >
                                    {name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div>
                              <input
                                type="text"
                                value={
                                  singleProductInfo?.data?.nameWithLanguages?.[
                                    index
                                  ]?.name || lang.translatedName
                                }
                                className="py-1.5 px-5 rounded-md border border-gray-200 mr-2 w-80"
                              />
                            </div>
                            <div>
                              <Button
                                onClick={() => handleTranslate(index)}
                                className="p-2.5 rounded-md mt-0.5 mr-2"
                              >
                                Translate
                              </Button>
                            </div>
                            <div>
                              <Button onClick={() => removeLanguage(index)}>
                                Remove
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="col-span-2 mb-2">
                        <Button
                          className={Styles.addButton}
                          type="dashed"
                          onClick={addLanguage}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add More Language
                        </Button>
                      </div>

                      <div>
                        <Form.Item
                          hasFeedback
                          label="Product Type"
                          rules={[
                            {
                              required: true,
                              message: "Product Type is Required!",
                            },
                          ]}
                          name="productType"
                          initialValue={productType}
                        >
                          <Select
                            options={[
                              { value: "REGULAR", label: "REGULAR" },
                              { value: "AUCTION", label: "AUCTION" },
                              {
                                value: "GIFTCARD_VOUCHER",
                                label: "GIFT CARD VOUCHER",
                              },
                            ]}
                            placeholder="Enter Product Type"
                            onChange={(val) => setProductType(val)}
                          />
                        </Form.Item>
                      </div>
                      {productType === "AUCTION" && (
                        <>
                          <div>
                            <Form.Item
                              hasFeedback
                              label="Auction Type"
                              rules={[
                                {
                                  required: true,
                                  message: "Auction Type is Required!",
                                },
                              ]}
                              name="auctionType"
                              initialValue={auctionType}
                            >
                              <Select
                                options={[
                                  { value: null, label: "NONE" },
                                  { value: "NORMAL", label: "NORMAL" },
                                  { value: "REVERSE", label: "REVERSE" },
                                ]}
                                placeholder="Enter Auction Type"
                                onChange={(val) => setAuctionType(val)}
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              hasFeedback
                              label="Starting Price"
                              rules={[
                                {
                                  required: true,
                                  message: "Starting Price is Required!",
                                },
                              ]}
                              name="startingPrice"
                            >
                              <Input
                                id="startingPrice"
                                type="number"
                                min={0}
                                placeholder="Enter Starting Price"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              hasFeedback
                              label="Minimum Increment Amount"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Minimum Increment Amount is Required!",
                                },
                              ]}
                              name="minimumIncrementAmount"
                            >
                              <Input
                                id="minimumIncrementAmount"
                                type="number"
                                min={0}
                                placeholder="Enter Minimum Increment Amount"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              hasFeedback
                              label="Reserve Price"
                              rules={[
                                {
                                  required: false,
                                  message: "Reserve Price is Required!",
                                },
                              ]}
                              name="reservePrice"
                            >
                              <Input
                                id="reservePrice"
                                type="number"
                                min={0}
                                placeholder="Enter Reserve Price"
                              />
                            </Form.Item>
                          </div>
                          <div className="">
                            <Form.Item
                              hasFeedback
                              label="IS Buy Now Enable?"
                              name="isBuyNowEnable"
                              className="mr-5"
                            >
                              <Switch
                                checkedChildren={`true`}
                                unCheckedChildren={`false`}
                                defaultChecked={
                                  (singleProductInfo as any)?.data?.auction
                                    ?.isBuyNowEnable
                                }
                              />
                            </Form.Item>
                          </div>
                          <div className="">
                            <Form.Item
                              hasFeedback
                              label="IS Sealed?"
                              name="isSealed"
                              className="mr-5"
                            >
                              <Switch
                                checkedChildren={`true`}
                                unCheckedChildren={`false`}
                                defaultChecked={
                                  (singleProductInfo as any)?.data?.auction
                                    ?.isSealed
                                }
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              hasFeedback
                              label="Starting Date"
                              rules={[
                                {
                                  required: true,
                                  message: "Starting Date is Required!",
                                },
                              ]}
                              name="startDate"
                            >
                              <DatePicker
                                showTime
                                style={{ minWidth: "100%" }}
                                showNow={false}
                                placeholder="Starting Date"
                                onChange={(val: DatePickerProps["value"]) =>
                                  setStartingDate(val)
                                }
                              />
                            </Form.Item>
                          </div>

                          <div>
                            <Form.Item
                              hasFeedback
                              label="Ending Date"
                              rules={[
                                {
                                  required: true,
                                  message: "Ending Date is Required!",
                                },
                              ]}
                              name="endDate"
                            >
                              <DatePicker
                                showTime
                                id="endDate"
                                placeholder="Enter Ending Date"
                                showNow={false}
                                style={{ minWidth: "100%" }}
                                onChange={(val: DatePickerProps["value"]) =>
                                  setEndingDate(val)
                                }
                                disabled={!startingDate}
                                disabledDate={(currentDate) =>
                                  currentDate.isBefore(dayjs(startingDate))
                                }
                              />
                            </Form.Item>
                          </div>
                          <div className="">
                            <Form.Item
                              hasFeedback
                              label="Has Ended?"
                              name="hasEnded"
                              className="mr-5"
                            >
                              <Switch
                                checkedChildren={`true`}
                                unCheckedChildren={`false`}
                                defaultChecked={
                                  (singleProductInfo as any)?.data?.auction
                                    ?.hasEnded
                                }
                              />
                            </Form.Item>
                          </div>
                        </>
                      )}
                      <div>
                        <Form.Item
                          hasFeedback
                          label="Product Barcode"
                          name="barCode"
                        >
                          <Input
                            id="barCode"
                            type="text"
                            placeholder="Scan Barcode"
                          />
                        </Form.Item>
                      </div>

                      <div className="">
                        <Form.Item
                          hasFeedback
                          label="Product Origin"
                          name="productOrigin"
                        >
                          <Input
                            id="productOrigin"
                            type="text"
                            placeholder="Enter Product Origin"
                          />
                        </Form.Item>
                      </div>
                      <div className="">
                        <Form.Item
                          hasFeedback
                          label="SKU"
                          name="sku"
                          rules={[
                            {
                              required: type !== "JC_FOOD",
                              message: "Product SKU is Required!",
                            },
                          ]}
                        >
                          <Input id="sku" type="text" placeholder="Enter SKU" />
                        </Form.Item>
                      </div>
                      <div className="">
                        <Form.Item
                          hasFeedback
                          label="Product Video Url"
                          name="productVideoUrl"
                        >
                          <Input
                            id="productVideoUrl"
                            type="text"
                            placeholder="Enter product Video url"
                          />
                        </Form.Item>
                      </div>
                      <div className="col-span-2 mb-8">
                        <Form.Item hasFeedback label="Product Slug" name="slug">
                          <Input
                            id="slug"
                            type="text"
                            placeholder="Enter Slug"
                          />
                        </Form.Item>
                      </div>
                      <div className="">
                        <Form.Item
                          hasFeedback
                          label="Display Order"
                          rules={[
                            {
                              required: false,
                              message: "displayOrder is Required!",
                            },
                          ]}
                          name="displayOrder"
                        >
                          <Input
                            id="displayOrder"
                            type="text"
                            placeholder="Enter Display Order"
                          />
                        </Form.Item>
                      </div>

                      {/* {singleProductInfo?.data && ( */}
                      <div>
                        <Form.Item
                          label="Price Calculation Type"
                          name="priceCalculationType"
                          rules={[
                            {
                              required: true,
                              message: "Price Calculation Type is Required!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Price Calculation Type"
                            onChange={(val) => setPriceCalcType(val)}
                          >
                            {Object.values(priceCalculationType)?.map(
                              (type, i) => (
                                <Option value={type} key={i}>
                                  {type?.split("_").join(" ")}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                      {/* )} */}

                      {priceCalcType === "TP_BASED_COMMISSION" && (
                        <>
                          <div>
                            <Form.Item
                              hasFeedback
                              label="Commission Type"
                              rules={[
                                {
                                  required: false,
                                  message: "Commission Type is Required!",
                                },
                              ]}
                              name="commissionType"
                              initialValue={commissionType}
                            >
                              <Select
                                options={[
                                  { value: null, label: "NONE" },
                                  { value: "PERCENTAGE", label: "PERCENTAGE" },
                                  { value: "FLAT", label: "FLAT" },
                                ]}
                                placeholder="Enter Commission Type"
                                onChange={(val) => setCommissionType(val)}
                              />
                            </Form.Item>
                          </div>

                          {commissionType != null && (
                            <div>
                              <Form.Item
                                hasFeedback
                                label="Commission Amount"
                                rules={[
                                  {
                                    required: false,
                                    message: "Commission Amount is Required!",
                                  },
                                ]}
                                name="commissionAmount"
                              >
                                <Input
                                  disabled={!discountType}
                                  id="commissionAmount"
                                  type="number"
                                  min={0}
                                  placeholder="Enter Commission Amount"
                                />
                              </Form.Item>
                            </div>
                          )}
                        </>
                      )}

                      <div>
                        <Form.Item
                          hasFeedback
                          label={`Category -(${singleProductInfo?.data?.category?.title})`}
                          name="categoryId"
                          rules={[
                            {
                              required: !categoryId,
                              message: "Category is required!",
                            },
                          ]}
                          initialValue={categoryId}
                        >
                          <TreeSelectTwo
                            setCategoryId={setCategoryId}
                            value={categoryId}
                          />
                        </Form.Item>
                      </div>

                      <div className="mb-4">
                        <Form.Item
                          hasFeedback
                          label={`Brand (${singleProductInfo?.data?.brand?.name})`}
                          name="brandId"
                          rules={[
                            {
                              required: !isNotBrand,
                              message: "Category is required!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select Brand"
                            optionFilterProp="children"
                            onSearch={(val) => {
                              handleSearch(val, "brand");
                            }}
                            filterOption={() => {
                              return true;
                            }}
                            options={brandsOptions?.list}
                          ></Select>
                        </Form.Item>
                        {/* isNotBrand */}
                        <Checkbox
                          checked={isNotBrand}
                          onChange={(e) => setIsNotBrand(e.target.checked)}
                        >
                          This product does not have a brand name
                        </Checkbox>
                      </div>

                      <div className="">
                        <Form.Item
                          hasFeedback
                          label="Campaign"
                          name="campaignId"
                        >
                          <Select
                            showSearch
                            allowClear
                            placeholder="Select Campaign"
                            optionFilterProp="children"
                            onSearch={(e) => {
                              console.log(e);
                            }}
                            filterOption={() => {
                              return true;
                            }}
                            options={campaignOptions?.list}
                          ></Select>
                        </Form.Item>
                      </div>

                      <div className="">
                        <Form.Item
                          hasFeedback
                          label="Manufacturer Name"
                          rules={[
                            {
                              required: false,
                              message: "Manufacturer is Required!",
                            },
                          ]}
                          name="manufacturerName"
                        >
                          <Input
                            id="manufacturerName"
                            type="text"
                            placeholder="Enter Manufacturer Name"
                          />
                        </Form.Item>
                      </div>
                      <div className="">
                        <Form.Item
                          hasFeedback
                          label="Manufacturer Contact 
                      Information"
                          rules={[
                            {
                              required: false,
                              message: "Contract Info is Required!",
                            },
                          ]}
                          name="manufacturerContactInfo"
                        >
                          <Input
                            id="manufacturerContactInfo"
                            type="text"
                            placeholder="Enter Manufacturer Contact 
                        Information"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  {/* <Button
                    size="large"
                    className="bg-green-600 hover:bg-green-500 hover:text-white border-none text-white font-semibold rounded-lg mr-1"
                    disabled={loading}
                    loading={loading}
                    onClick={() => onKeyChange("2")}
                  >
                    Save & Next "Tab 2"
                  </Button> */}
                </TabPane>
                <TabPane tab="Image & Video" key="2">
                  <div
                    className={cn(
                      `grid grid-cols-2 md:grid-cols-2 xl:grid-cols-1 col-span-3 md:col-span-3 lg:col-span-1 gap-6 grid-flow-row auto-rows-max mb-6`,
                      { ["grid grid-cols-2 xl:grid-cols-2 xl:gap-6"]: inShop }
                    )}
                  >
                    <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl px-4 pb-2">
                      <Divider orientation="left">
                        Thumbnail
                        <Tooltip
                          placement="bottom"
                          title={
                            "Thumbnail Image should be square. We prefer height 400px and width 400px (Ratio 1)"
                          }
                        >
                          <QuestionCircleOutlined className="px-2 -mt-2" />
                        </Tooltip>
                      </Divider>
                      <ImageUploader
                        //crop={1}
                        imgUrl={generatedThumbUrl || ""}
                        startUpload={startUpload}
                        setStartUpload={(val: any) => setStartUpload(val)}
                        setGeneratedImgUrl={(url: any) => {
                          setGeneratedThumbUrl(url);
                        }}
                      />
                      <Divider orientation="left">
                        Image Gallery
                        <Tooltip
                          placement="bottom"
                          title={
                            "Product Image should be square. We prefer height 400px and width 400px (Ratio 1)"
                          }
                        >
                          <QuestionCircleOutlined className="px-2 -mt-2" />
                        </Tooltip>
                      </Divider>
                      <ImageUploader
                        multiple
                        imgUrl={generatedImgUrl || []}
                        startUpload={startUpload2}
                        setStartUpload={(val: any) => setStartUpload2(val)}
                        setGeneratedImgUrl={(url: any) =>
                          setGeneratedImgUrl(url)
                        }
                      />
                      <Divider orientation="left">
                        Video
                        <Tooltip placement="bottom" title={"Product Video"}>
                          <QuestionCircleOutlined className="px-2 -mt-2" />
                        </Tooltip>
                      </Divider>
                      <div>
                        <Radio.Group
                          value={productVideoUrl}
                          onChange={(e) => setProductVideoUrl(e.target.value)}
                        >
                          <Radio value={1}>Local Upload</Radio>
                          <Radio value={2}>Product Video URL</Radio>
                        </Radio.Group>
                      </div>
                      {productVideoUrl === 1 && (
                        <>
                          <div className="mt-4">
                            <ImageUploader
                              // crop={1}
                              imgUrl={generatedThumbUrl || ""}
                              startUpload={startUpload}
                              setStartUpload={(val: any) => setStartUpload(val)}
                              setGeneratedImgUrl={(url: any) => {
                                setGeneratedThumbUrl(url);
                              }}
                            />
                          </div>
                        </>
                      )}

                      {productVideoUrl === 2 && (
                        <>
                          <div className="mt-4">
                            <Form.Item
                              hasFeedback
                              label="Product Video URL"
                              rules={[
                                {
                                  required: false,
                                  message: "Product Video URL is Required!",
                                },
                              ]}
                              name="productVideoUrl"
                            >
                              <Input
                                id="productVideoUrl"
                                type="text"
                                placeholder="Enter Product Video URL"
                              />
                            </Form.Item>
                          </div>
                        </>
                      )}

                      <div></div>
                    </div>

                    <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl px-4 pb-2">
                      <Divider orientation="left">Extra Options</Divider>
                      <div className="grid grid-cols-1 gap-4">
                        <div className="">
                          <Form.Item
                            hasFeedback
                            label="Allow Warranty?"
                            name="isAllowWarranty"
                          >
                            <Switch
                              checkedChildren={`Allow`}
                              unCheckedChildren={`Not Allow`}
                              defaultChecked={
                                (singleProductInfo as any)?.data
                                  ?.isAllowWarranty
                              }
                              onChange={() =>
                                setIsAllowWarranty(!isAllowWarranty)
                              }
                            />
                          </Form.Item>
                        </div>
                        {isAllowWarranty && (
                          <>
                            <div>
                              <Form.Item
                                label="Warranty Type"
                                name="warrantyType"
                              >
                                <Select
                                  placeholder="Select Warranty Type"
                                  onChange={(val) => setWarrantyType(val)}
                                >
                                  <Option value="REPLACEMENT_WARRANTY">
                                    Replacement Warranty
                                  </Option>
                                  <Option value="SERVICE_WARRANTY">
                                    Service Warranty
                                  </Option>
                                </Select>
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                hasFeedback
                                label="Warranty Time"
                                name="warrantyTimeInMonth"
                              >
                                <Input
                                  id="warrantyTimeInMonth"
                                  type="text"
                                  placeholder="Enter Warranty Time"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                hasFeedback
                                label="Warranty Policy"
                                name="warrantyPolicy"
                              >
                                <Input.TextArea
                                  id="warrantyPolicy"
                                  placeholder="Enter Warranty Policy"
                                  rows={3}
                                />
                              </Form.Item>
                            </div>
                          </>
                        )}
                        <div className="">
                          <Form.Item
                            hasFeedback
                            label="Is Returnable?"
                            name="isReturnable"
                          >
                            <Switch
                              checkedChildren={`Yes`}
                              unCheckedChildren={`No`}
                              defaultChecked={
                                (singleProductInfo as any)?.data?.isReturnable
                              }
                              onChange={() => setIsReturnable(!isReturnable)}
                            />
                          </Form.Item>
                        </div>
                        {isReturnable && (
                          <>
                            <div>
                              <Form.Item
                                hasFeedback
                                label="Return & Refund Time"
                                name="returnRefundTimeInMonth"
                              >
                                <Input
                                  id="returnRefundTimeInMonth"
                                  type="text"
                                  placeholder="Enter return and refund time"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                hasFeedback
                                label="Return & Refund  Policy"
                                name="returnRefundPolicy"
                              >
                                <Input.TextArea
                                  id="returnRefundPolicy"
                                  placeholder="Enter Return & Refund  Policy"
                                  rows={3}
                                />
                              </Form.Item>
                            </div>
                          </>
                        )}
                        <div className="">
                          <Form.Item
                            hasFeedback
                            label="Exclude From COD?"
                            name="excludeCOD"
                          >
                            <Switch
                              checkedChildren={`Exclude`}
                              unCheckedChildren={`Include`}
                              defaultChecked={
                                (singleProductInfo as any)?.data?.excludeCOD
                              }
                            />
                          </Form.Item>
                        </div>

                        <div className="">
                          <Form.Item
                            hasFeedback
                            label="Exclude From Promo?"
                            name="excludeFromPromo"
                          >
                            <Switch
                              checkedChildren={`Exclude`}
                              unCheckedChildren={`Include`}
                              defaultChecked={
                                (singleProductInfo as any)?.data
                                  ?.excludeFromPromo
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className="">
                          <Form.Item
                            hasFeedback
                            label="Popular Product?"
                            name="isPopular"
                            className="mr-5"
                          >
                            <Switch
                              checkedChildren={`Yes`}
                              unCheckedChildren={`Not Sure`}
                              defaultChecked={
                                (singleProductInfo as any)?.data?.isPopular
                              }
                            />
                          </Form.Item>
                        </div>

                        <div className="">
                          <Form.Item
                            hasFeedback
                            label="Is For Check Out?"
                            name="isForCheckOut"
                            className="mr-5"
                          >
                            <Switch
                              checkedChildren={`true`}
                              unCheckedChildren={`false`}
                              defaultChecked={
                                (singleProductInfo as any)?.data?.isForCheckOut
                              }
                            />
                          </Form.Item>
                        </div>

                        <div className="">
                          <Form.Item
                            hasFeedback
                            label="Is Pre Order?"
                            name="isPreOrder"
                            className="mr-5"
                          >
                            <Switch
                              checkedChildren={`true`}
                              unCheckedChildren={`false`}
                              defaultChecked={
                                (singleProductInfo as any)?.data?.isPreOrder
                              }
                              onChange={() => setIsPreOrder(!isPreOrder)}
                            />
                          </Form.Item>
                        </div>
                        {isPreOrder && (
                          <>
                            <div className="isPreOrder">
                              <div className="">
                                <Form.Item
                                  hasFeedback
                                  label="Pre Paid Amount Percentage"
                                  name="prePaidAmountInPercentage"
                                >
                                  <Input
                                    id="prePaidAmountInPercentage"
                                    type="number"
                                    min={0}
                                    placeholder="Enter pre paid amount percentage"
                                    onKeyPress={prePaidAmountPreventMinus}
                                  />
                                </Form.Item>
                              </div>
                              <div className="">
                                <Form.Item
                                  hasFeedback
                                  label="Payment Time hours"
                                  name="paymentTimeInSeconds"
                                >
                                  <Input
                                    id="paymentTimeInSeconds"
                                    type="number"
                                    min={0}
                                    placeholder="Enter Payment Time Hours"
                                    onKeyPress={paymentTimePreventMinus}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <button onClick={() => onKeyChange("1")}>
                    Go to "Tab 1"
                  </button> */}
                </TabPane>
                <TabPane tab="Variation & Price" key="3">
                  <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
                    <h3 className="mb-4 text-xl font-bold">Variations </h3>

                    <Form.List
                      name="variations"
                      rules={[
                        {
                          validator: async (_, names) => {
                            if (!names || names.length < 1) {
                              return Promise.reject(
                                new Error("At least 1 variation required!")
                              );
                            }
                          },
                        },
                      ]}
                      initialValue={[{}]}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map(
                            ({ key, name, fieldKey, ...restField }: any) => (
                              <Card className="mb-2" size="small" key={key}>
                                <Space wrap align="center">
                                  {/* {variationImageUrl && (
                                    <div>
                                      <Avatar
                                        src={variationImageUrl[name]}
                                        shape="square"
                                        size="large"
                                      />
                                    </div>
                                  )} */}
                                  <Form.Item
                                    {...restField}
                                    name={[name, "variationId"]}
                                    fieldKey={[fieldKey, "variationId"]}
                                    hasFeedback
                                    label="ID"
                                    style={{ width: 80 }}
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.variationId
                                    }
                                  >
                                    <Input
                                      id="variationId"
                                      placeholder="Enter ID"
                                      disabled={singleProductInfo?.data}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "variationName"]}
                                    fieldKey={[fieldKey, "variationName"]}
                                    hasFeedback
                                    label="Name"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required!",
                                      },
                                    ]}
                                    style={{ width: 90 }}
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.variationName
                                    }
                                  >
                                    <Input placeholder="1pc/5kg/1pkt" />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "imageUrl"]}
                                    fieldKey={[fieldKey, "imageUrl"]}
                                    hasFeedback
                                    label="Image"
                                    style={{ width: 90 }}
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.imageUrl
                                    }
                                  >
                                    <Input
                                      placeholder="Enter Image"
                                      onChange={async (e) => {
                                        setVariationImageUrl((prev) => {
                                          const newArr = Array.from(prev);
                                          newArr[name] = e.target.value;
                                          return newArr;
                                        });
                                      }}
                                      size="small"
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    {...restField}
                                    name={[name, "barCode"]}
                                    fieldKey={[fieldKey, "barCode"]}
                                    hasFeedback
                                    label="Barcode"
                                    style={{ width: 100 }}
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.barCode
                                    }
                                  >
                                    <Input placeholder="Enter Barcode" />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "mrp"]}
                                    fieldKey={[fieldKey, "mrp"]}
                                    hasFeedback
                                    label="MRP"
                                    style={{ width: 90 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required!",
                                      },
                                    ]}
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.price?.mrp
                                    }
                                  >
                                    <Input placeholder="Enter MRP" />
                                  </Form.Item>
                                  {priceCalcType == "TP_BASED_COMMISSION" && (
                                    <>
                                      {commissionType === null && (
                                        <Form.Item
                                          {...restField}
                                          name={[name, "tp"]}
                                          fieldKey={[fieldKey, "tp"]}
                                          hasFeedback
                                          label="Give TP"
                                          style={{ width: 90 }}
                                          initialValue={
                                            singleProductInfo?.data
                                              ?.variations?.[name]?.price?.tp
                                          }
                                        >
                                          <Input placeholder="Enter Give TP" />
                                        </Form.Item>
                                      )}

                                      <Form.Item
                                        {...restField}
                                        name={[name, "paybletp"]}
                                        fieldKey={[fieldKey, "paybletp"]}
                                        hasFeedback
                                        label="Payble TP"
                                        style={{ width: 90 }}
                                        initialValue={
                                          singleProductInfo?.data?.variations?.[
                                            name
                                          ]?.price?.paybletp
                                        }
                                      >
                                        <Input
                                          placeholder="Enter Payble TP"
                                          disabled={true}
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        {...restField}
                                        name={[name, "discount"]}
                                        fieldKey={[fieldKey, "discount"]}
                                        hasFeedback
                                        label="Discount"
                                        style={{ width: 90 }}
                                        initialValue={
                                          singleProductInfo?.data?.variations?.[
                                            name
                                          ]?.price?.discount
                                        }
                                      >
                                        <Input placeholder="Enter Discount" />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "discountType"]}
                                        fieldKey={[fieldKey, "discountType"]}
                                        hasFeedback
                                        label="Discount Type"
                                        initialValue={
                                          singleProductInfo?.data?.variations?.[
                                            name
                                          ]?.discountType
                                        }
                                        // style={{ width: 120 }}
                                      >
                                        <Select
                                          options={[
                                            { value: null, label: "NONE" },
                                            {
                                              value: "PERCENTAGE",
                                              label: "PERCENTAGE",
                                            },
                                            { value: "FLAT", label: "FLAT" },
                                          ]}
                                          placeholder="Enter Discount Type"
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "saleprice"]}
                                        fieldKey={[fieldKey, "saleprice"]}
                                        hasFeedback
                                        label="Sale Price"
                                        style={{ width: 90 }}
                                        initialValue={
                                          singleProductInfo?.data?.variations?.[
                                            name
                                          ]?.price?.saleprice
                                        }
                                      >
                                        <Input placeholder="Enter sale price" />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "revenue"]}
                                        fieldKey={[fieldKey, "revenue"]}
                                        hasFeedback
                                        label="Revenue"
                                        style={{ width: 90 }}
                                        initialValue={
                                          singleProductInfo?.data?.variations?.[
                                            name
                                          ]?.price?.revenue
                                        }
                                      >
                                        <Input
                                          placeholder="Enter Revenue"
                                          disabled
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "contract"]}
                                        fieldKey={[fieldKey, "contract"]}
                                        hasFeedback
                                        label="Contract"
                                        style={{ width: 90 }}
                                        initialValue={
                                          singleProductInfo?.data?.variations?.[
                                            name
                                          ]?.price?.contract
                                        }
                                      >
                                        <Input
                                          placeholder="Enter Contract"
                                          disabled
                                        />
                                      </Form.Item>
                                    </>
                                  )}
                                  {singleProductInfo?.data && (
                                    <Form.Item
                                      {...restField}
                                      name={[name, "discountedPrice"]}
                                      fieldKey={[fieldKey, "discountedPrice"]}
                                      hasFeedback
                                      label="Revenue"
                                      style={{ width: 90 }}
                                      rules={[
                                        {
                                          required: false,
                                          message: "Required!",
                                        },
                                      ]}
                                      initialValue={Math.ceil(
                                        singleProductInfo?.data?.variations?.[
                                          name
                                        ]?.price?.discountedPrice -
                                          singleProductInfo?.data?.variations?.[
                                            name
                                          ]?.price?.tp
                                      )}
                                    >
                                      <Input
                                        placeholder="Revenue"
                                        disabled={singleProductInfo?.data}
                                      />
                                    </Form.Item>
                                  )}

                                  {/* new field add */}
                                  <Form.Item
                                    {...restField}
                                    name={[name, "sellerSKU"]}
                                    fieldKey={[fieldKey, "sellerSKU"]}
                                    hasFeedback
                                    label="Seller SKU"
                                    style={{ width: 100 }}
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.sellerSKU
                                    }
                                  >
                                    <Input
                                      id="sellerSKU"
                                      placeholder="Enter Seller SKU"
                                      disabled={singleProductInfo?.data}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "JSINNumber"]}
                                    fieldKey={[fieldKey, "JSINNumber"]}
                                    hasFeedback
                                    label="JSIN Number"
                                    style={{ width: 120 }}
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.JSINNumber
                                    }
                                  >
                                    <Input
                                      id="JSINNumber"
                                      placeholder="Enter JSIN"
                                      disabled={singleProductInfo?.data}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "UPCNumber"]}
                                    fieldKey={[fieldKey, "UPCNumber"]}
                                    hasFeedback
                                    label="UPC Number"
                                    style={{ width: 120 }}
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.UPCNumber
                                    }
                                  >
                                    <Input
                                      id="UPCNumber"
                                      placeholder="Enter UPC"
                                      disabled={singleProductInfo?.data}
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    {...restField}
                                    name={[name, "sellerPurchasePrice"]}
                                    fieldKey={[fieldKey, "sellerPurchasePrice"]}
                                    hasFeedback
                                    label="SPP"
                                    style={{ width: 80 }}
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.sellerPurchasePrice
                                    }
                                  >
                                    <Input
                                      id="sellerPurchasePrice"
                                      placeholder="Enter SPP"
                                      disabled={singleProductInfo?.data}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "pointAmount"]}
                                    fieldKey={[fieldKey, "pointAmount"]}
                                    hasFeedback
                                    label="Point"
                                    style={{ width: 80 }}
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.pointAmount
                                    }
                                  >
                                    <Input
                                      id="pointAmount"
                                      placeholder="Enter Point Amount"
                                      disabled={singleProductInfo?.data}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "platformProfit"]}
                                    fieldKey={[fieldKey, "platformProfit"]}
                                    hasFeedback
                                    label="Platform Profit"
                                    style={{ width: 120 }}
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.platformProfit
                                    }
                                  >
                                    <Input
                                      id="platformProfit"
                                      placeholder="Platform Profit"
                                      disabled={true}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "sellerProfit"]}
                                    fieldKey={[fieldKey, "sellerProfit"]}
                                    hasFeedback
                                    label="Seller Profit"
                                    style={{ width: 120 }}
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.sellerProfit
                                    }
                                  >
                                    <Input
                                      id="sellerProfit"
                                      placeholder="Seller Profit"
                                      disabled={true}
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    hasFeedback
                                    label="Special Discount?"
                                    name="isSpecialDiscount"
                                  >
                                    <Switch
                                      checkedChildren={`Allow`}
                                      unCheckedChildren={`Not Allow`}
                                      defaultChecked={
                                        (singleProductInfo as any)?.data
                                          ?.isSpecialDiscount
                                      }
                                      onChange={() =>
                                        setIsSpecialDiscount(!isSpecialDiscount)
                                      }
                                    />
                                  </Form.Item>

                                  {isSpecialDiscount && (
                                    <>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "specialSellPrice"]}
                                        fieldKey={[
                                          fieldKey,
                                          "specialSellPrice",
                                        ]}
                                        hasFeedback
                                        label="Special Sale Price"
                                        style={{ width: 140 }}
                                        initialValue={
                                          singleProductInfo?.data?.variations?.[
                                            name
                                          ]?.specialDiscountPrice?.discountPrice
                                        }
                                      >
                                        <Input
                                          id="specialSellPrice"
                                          placeholder="Special Sale Price"
                                          disabled={singleProductInfo?.data}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "startAt"]}
                                        fieldKey={[fieldKey, "startAt"]}
                                        hasFeedback
                                        label="Start Date"
                                        style={{ width: 120 }}
                                        initialValue={
                                          singleProductInfo?.data?.variations?.[
                                            name
                                          ]?.specialDiscountPrice?.startAt
                                        }
                                      >
                                        <Input
                                          id="startAt"
                                          type="date"
                                          placeholder="Enter Date"
                                          disabled={singleProductInfo?.data}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "endAt"]}
                                        fieldKey={[fieldKey, "endAt"]}
                                        hasFeedback
                                        label="End Date"
                                        style={{ width: 120 }}
                                        initialValue={
                                          singleProductInfo?.data?.variations?.[
                                            name
                                          ]?.specialDiscountPrice?.endAt
                                        }
                                      >
                                        <Input
                                          id="endAt"
                                          type="date"
                                          placeholder="Enter Date"
                                          disabled={singleProductInfo?.data}
                                        />
                                      </Form.Item>
                                    </>
                                  )}
                                  {/* new field add end */}

                                  <Form.Item
                                    {...restField}
                                    name={[name, "maximumOrderLimit"]}
                                    fieldKey={[fieldKey, "maximumOrderLimit"]}
                                    hasFeedback
                                    label="Max Limit"
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.maximumOrderLimit
                                    }
                                    style={{ width: 80 }}
                                  >
                                    <Input placeholder="Maximum Order Limit" />
                                  </Form.Item>

                                  <Form.Item
                                    {...restField}
                                    name={[name, "stock"]}
                                    fieldKey={[fieldKey, "stock"]}
                                    hasFeedback
                                    label="Stock"
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.stock
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required!",
                                      },
                                    ]}
                                    style={{ width: 70 }}
                                  >
                                    <Input placeholder="Stock" />
                                  </Form.Item>

                                  <Form.Item
                                    {...restField}
                                    name={[name, "subsidyType"]}
                                    fieldKey={[fieldKey, "subsidyType"]}
                                    hasFeedback
                                    label="Subsidy Type"
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.subsidyType
                                    }
                                    // style={{ width: 120 }}
                                  >
                                    <Select
                                      options={[
                                        { value: null, label: "NONE" },
                                        {
                                          value: "PERCENTAGE",
                                          label: "PERCENTAGE",
                                        },
                                        { value: "FLAT", label: "FLAT" },
                                      ]}
                                      placeholder="Enter Subsidy Type"
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    hasFeedback
                                    {...restField}
                                    name={[name, "subsidyAmount"]}
                                    fieldKey={[fieldKey, "subsidyAmount"]}
                                    label="Subsidy"
                                    initialValue={
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.subsidyAmount
                                    }
                                  >
                                    <Input
                                      id="subsidyAmount"
                                      type="number"
                                      min={0}
                                      placeholder="Enter Subsidy"
                                    />
                                  </Form.Item>
                                </Space>

                                <Space
                                  wrap
                                  align="center"
                                  style={{ display: "flex" }}
                                >
                                  <h6 className="bg-green-500 rounded text-white px-2 font-bold">
                                    Product Attributes
                                  </h6>
                                  <Form.List
                                    name="attributes"
                                    initialValue={[{}]}
                                  >
                                    {(subFields, { add, remove }) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          rowGap: 16,
                                          flexDirection: "column",
                                        }}
                                      >
                                        {/* Nest Form.List */}
                                        <>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              rowGap: 16,
                                            }}
                                          >
                                            {subFields.map((subField) => (
                                              <Space key={subField.key}>
                                                <Form.Item
                                                  {...restField}
                                                  name={[name, "attributeId"]}
                                                  fieldKey={[
                                                    subField,
                                                    "attributeId",
                                                  ]}
                                                  initialValue={
                                                    singleProductInfo?.data
                                                      ?.variations?.[name]
                                                      ?.attributes?.attributeId
                                                  }
                                                  hasFeedback
                                                  label="AttributeId"
                                                  style={{
                                                    width: 100,
                                                    marginBottom: 0,
                                                  }}
                                                >
                                                  <Input
                                                    placeholder="Attribute Id"
                                                    size="small"
                                                  />
                                                </Form.Item>

                                                <Form.Item
                                                  {...restField}
                                                  name={[name, "name"]}
                                                  fieldKey={[fieldKey, "name"]}
                                                  hasFeedback
                                                  initialValue={
                                                    singleProductInfo?.data
                                                      ?.variations?.[name]
                                                      ?.attributes?.name
                                                  }
                                                  label="Name"
                                                  style={{
                                                    width: 100,
                                                    marginBottom: 0,
                                                  }}
                                                >
                                                  <Input
                                                    placeholder="Name"
                                                    size="small"
                                                  />
                                                </Form.Item>
                                                <Form.Item
                                                  {...restField}
                                                  name={[name, "imageUrl"]}
                                                  fieldKey={[
                                                    fieldKey,
                                                    "imageUrl",
                                                  ]}
                                                  hasFeedback
                                                  initialValue={
                                                    singleProductInfo?.data
                                                      ?.variations?.[name]
                                                      ?.attributes?.imageUrl
                                                  }
                                                  label="Image Url"
                                                  style={{
                                                    width: 100,
                                                    marginBottom: 0,
                                                  }}
                                                >
                                                  <Input
                                                    placeholder="Image Url"
                                                    size="small"
                                                  />
                                                </Form.Item>
                                                <CloseOutlined
                                                  onClick={() => {
                                                    remove(subField.name);
                                                  }}
                                                />
                                              </Space>
                                            ))}
                                            <Button
                                              type="dashed"
                                              onClick={() => add()}
                                              block
                                            >
                                              + Add Attributes
                                            </Button>
                                          </div>
                                        </>
                                      </div>
                                    )}
                                  </Form.List>
                                </Space>

                                <Space wrap align="center" className="mb-3">
                                  <h6 className="bg-green-500 rounded text-white px-2 font-bold">
                                    General Discount
                                  </h6>
                                  <Radio.Group
                                    value={discountType[name]?.type}
                                    onChange={(e: any) => {
                                      setDiscountType(() => {
                                        const temp = {} as any;
                                        temp[name] = {
                                          type: e.target.value,
                                          value: undefined,
                                        };
                                        return temp;
                                      });
                                    }}
                                    name="productDiscount"
                                  >
                                    <Radio value={undefined}>None</Radio>
                                    <Radio value={`disFlat`}>Flat</Radio>

                                    <Radio value={`disPercentage`}>
                                      Percentage
                                    </Radio>
                                  </Radio.Group>
                                  <div className="">
                                    {discountType[name]?.type === "disFlat" && (
                                      <Form.Item
                                        {...restField}
                                        name={[name, "flat"]}
                                        fieldKey={[fieldKey, "flat"]}
                                        initialValue={
                                          discountType?.[name]?.value
                                        }
                                        hasFeedback
                                        style={{
                                          width: 100,
                                          marginBottom: 0,
                                        }}
                                      >
                                        <Input
                                          placeholder="Flat (Tk.)"
                                          size="small"
                                        />
                                      </Form.Item>
                                    )}

                                    {discountType?.[name]?.type ===
                                      "disPercentage" && (
                                      <Form.Item
                                        {...restField}
                                        name={[name, "percentage"]}
                                        fieldKey={[fieldKey, "percentage"]}
                                        hasFeedback
                                        initialValue={
                                          discountType?.[name]?.value
                                        }
                                        style={{
                                          width: 100,
                                          marginBottom: 0,
                                        }}
                                      >
                                        <Input
                                          placeholder="Percent (%)"
                                          size="small"
                                        />
                                      </Form.Item>
                                    )}
                                  </div>
                                </Space>

                                <Space wrap key={key} align="center">
                                  <h6 className="bg-green-500 rounded text-white px-2 font-bold">
                                    Quantitive Discount
                                  </h6>

                                  <Radio.Group
                                    value={qtyDiscountType?.[name]?.type}
                                    onChange={(val) => {
                                      setQtyDiscountType(() => {
                                        const temp = {} as any;
                                        temp[name] = {
                                          type: val.target.value,
                                          value: undefined,
                                          minimumQuantity: undefined,
                                        };
                                        return temp;
                                      });
                                    }}
                                    name="quantitiveProductDiscount"
                                  >
                                    <Radio value={undefined}>None</Radio>
                                    <Radio value={`qtyFlat`}>Flat</Radio>
                                    <Radio value={`qtyPercentage`}>
                                      Percentage
                                    </Radio>
                                    <Radio value="freeProduct">
                                      Free Product
                                    </Radio>
                                  </Radio.Group>

                                  <Space wrap key={key} align="center">
                                    {qtyDiscountType?.[name]?.type !== null && (
                                      <Form.Item
                                        {...restField}
                                        name={[name, "minimumQuantity"]}
                                        fieldKey={[fieldKey, "minimumQuantity"]}
                                        hasFeedback
                                        initialValue={
                                          qtyDiscountType?.[name]
                                            ?.minimumQuantity
                                        }
                                        style={{
                                          width: 100,
                                          marginBottom: 0,
                                        }}
                                      >
                                        <Input
                                          placeholder="Min Quantity"
                                          size="small"
                                        />
                                      </Form.Item>
                                    )}

                                    {qtyDiscountType?.[name]?.type ===
                                      "freeProduct" &&
                                      (qtyDiscountType?.[name]?.value ||
                                      selectedProduct?.productImage ? (
                                        <div className="d-flex align-items-start">
                                          {discountImageUrl && (
                                            <Avatar
                                              src={
                                                <Image
                                                  src={
                                                    selectedProduct?.productImage ||
                                                    discountImageUrl[name]
                                                  }
                                                />
                                              }
                                              shape="square"
                                              size="large"
                                              className="border"
                                            />
                                          )}
                                          <Button
                                            danger
                                            type="text"
                                            onClick={() =>
                                              setSelectedProduct(undefined)
                                            }
                                            size="large"
                                          >
                                            <CloseOutlined />
                                          </Button>
                                        </div>
                                      ) : (
                                        <Button
                                          type="dashed"
                                          onClick={() => setVisible(true)}
                                          size="large"
                                        >
                                          <GiftOutlined /> Free Product
                                        </Button>
                                      ))}

                                    {qtyDiscountType?.[name]?.type ===
                                      "qtyFlat" && (
                                      <Form.Item
                                        {...restField}
                                        name={[name, "qtyFlat"]}
                                        fieldKey={[fieldKey, "flat"]}
                                        hasFeedback
                                        initialValue={
                                          qtyDiscountType?.[name]?.value
                                        }
                                        style={{
                                          width: 100,
                                          marginBottom: 0,
                                        }}
                                      >
                                        <Input
                                          placeholder="Flat (Tk.)"
                                          size="small"
                                        />
                                      </Form.Item>
                                    )}

                                    {qtyDiscountType?.[name]?.type ===
                                      "qtyPercentage" && (
                                      <Form.Item
                                        {...restField}
                                        name={[name, "qtyPercentage"]}
                                        fieldKey={[fieldKey, "percentage"]}
                                        hasFeedback
                                        // label="Percentage Discount"
                                        initialValue={
                                          qtyDiscountType?.[name]?.value
                                        }
                                        style={{
                                          width: 100,
                                          marginBottom: 0,
                                        }}
                                      >
                                        <Input
                                          placeholder="Percent (%)"
                                          size="small"
                                        />
                                      </Form.Item>
                                    )}
                                  </Space>
                                </Space>

                                <Button
                                  type="primary"
                                  danger
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    bottom: 0,
                                  }}
                                  onClick={() => {
                                    remove(name);
                                    setVariationImageUrl((prev) => {
                                      const newArr = prev.filter(
                                        (_, i) => i !== name
                                      );
                                      return newArr;
                                    });
                                  }}
                                >
                                  <CloseOutlined />
                                </Button>
                              </Card>
                            )
                          )}

                          <Form.Item
                            style={{
                              width: "100%",
                              flex: "1 1 100%",
                              marginBottom: 5,
                            }}
                          >
                            <Button
                              type="dashed"
                              onClick={() => {
                                add();
                                setVariationImageUrl((prev) => {
                                  const newArr = Array.from(prev);
                                  newArr.push("");
                                  return newArr;
                                });
                              }}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add Variation
                            </Button>
                          </Form.Item>

                          <Form.ErrorList errors={errors} />
                        </>
                      )}
                    </Form.List>
                  </div>
                </TabPane>
                <TabPane tab="Description" key="4">
                  <div
                    className={
                      inShop ? "col-span-full" : `grid-cols-3 lg:col-span-2`
                    }
                  >
                    <div className="col-span-2 mb-1">
                      <Divider className="float-right w-full">
                        Product Accessories
                      </Divider>
                    </div>
                    <div>
                      {accessories.map((productAccess, index) => (
                        <div
                          key={index}
                          className="col-span-2 mb-2"
                          style={{ display: "flex", marginBottom: 4 }}
                        >
                          <div>
                            <select
                              value={productAccess.acessoriesName}
                              onChange={(e) =>
                                handleProductAccessoriesChange(
                                  index,
                                  e.target.value
                                )
                              }
                              className="py-1.5 px-5 rounded-md border border-gray-200 mr-2 w-90"
                            >
                              <option value="">Select Product</option>
                              {accessoriesProductOptions.map(
                                (getProductName, pIndex) => (
                                  <option
                                    key={pIndex}
                                    value={getProductName?.id}
                                    selected={
                                      getProductName?.id ===
                                      singleProductInfo?.data?.accessories?.[
                                        index
                                      ]?.id
                                    }
                                  >
                                    {getProductName?.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <div>
                            <Button
                              onClick={() => removeProductAccessories(index)}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-span-2 mb-2">
                      <Button
                        className={Styles.addButton}
                        type="dashed"
                        onClick={addProductAccessories}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add More Accessories
                      </Button>
                    </div>

                    <div className="col-span-2 mb-8">
                      <h4 className="font-semibold mb-1">Product Details</h4>
                      <JoditEditor
                        ref={editor}
                        value={convertedText}
                        config={jodiConfig as any}
                        // tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) => setConvertedText(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {}}
                      />
                    </div>
                    <div className="col-span-2 mb-4">
                      <h4 className="font-semibold mb-1">FAQ</h4>
                      <JoditEditor
                        ref={editor}
                        value={convertedTextForFAQ}
                        config={jodiConfig as any}
                        onBlur={(newContent) =>
                          setConvertedTextForFAQ(newContent)
                        }
                        onChange={(newContent) => {}}
                      />
                    </div>
                    <div className="col-span-2 mb-4">
                      <h4 className="font-semibold mb-1">Term & Conditions</h4>
                      <JoditEditor
                        ref={editor}
                        value={convertedTextForTermCondition}
                        config={jodiConfig as any}
                        onBlur={(newContent) =>
                          setConvertedTextForTermCondition(newContent)
                        }
                        onChange={(newContent) => {}}
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Service" key="5">
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6  grid grid-cols-2 gap-x-4">
                  <h3 className="mb-4 text-xl font-bold col-span-2">Service</h3>

                  <div className="col-span-2">
                    <Form.Item
                      hasFeedback
                      label="Product Condition"
                      name="productCondition"
                    >
                      <Radio.Group
                        onChange={(e) => setProductCondition(e.target.value)}
                      >
                        <Radio value="NEW_PRODUCT">New Product</Radio>
                        <Radio value="USE_PRODUCT">Use Product</Radio>
                        <Radio value="OTHERS">Others</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  {/* <div className="">
                    <Form.Item
                      hasFeedback
                      label="Expire Date(optional)"
                      name="metaTitle"
                    >
                      <DatePicker
                        showTime
                        style={{ minWidth: "100%" }}
                        showNow={false}
                        placeholder="Starting Date"
                        onChange={(val: DatePickerProps["value"]) =>
                          setExpireDate(val)
                        }
                      />
                    </Form.Item>
                  </div> */}
                  {/* <div className="">
                    <Form.Item
                      hasFeedback
                      label="Manufacture Date(optional)"
                      name="keyword"
                    >
                      <DatePicker
                        showTime
                        style={{ minWidth: "100%" }}
                        showNow={false}
                        placeholder="Starting Date"
                        onChange={(val: DatePickerProps["value"]) =>
                          setManufactureDate(val)
                        }
                      />
                    </Form.Item>
                  </div> */}

                  {/* <div className="">
                    <Form.Item
                      hasFeedback
                      label="Restock Date & time"
                      name="metaTitle"
                    >
                      <DatePicker
                        showTime
                        style={{ minWidth: "100%" }}
                        showNow={false}
                        placeholder="Starting Date"
                        onChange={(val: DatePickerProps["value"]) =>
                          setRestockDateTime(val)
                        }
                      />
                    </Form.Item>
                  </div> */}
                  <div className="">
                    <Form.Item
                      label="Product Handling Time"
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 12px)",
                        }}
                      >
                        <Input
                          type="text"
                          name="handlingTimeFrom"
                          placeholder="From"
                          style={{ width: 100 }}
                        />
                      </Form.Item>
                      <span
                        style={{
                          display: "inline-block",
                          width: "24px",
                          lineHeight: "32px",
                          textAlign: "center",
                          padding: " 5px auto",
                        }}
                      >
                        TO
                      </span>
                      <Form.Item
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 12px)",
                        }}
                      >
                        <Input
                          type="text"
                          name="handlingTimeTo"
                          placeholder="TO"
                          style={{ width: 100 }}
                        />
                      </Form.Item>
                    </Form.Item>
                  </div>

                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Offering can be gift massaged"
                      rules={[
                        {
                          required: false,
                          message: "Discount Type is Required!",
                        },
                      ]}
                      name="minimumDiscountType"
                    >
                      <Select
                        onChange={(val) => setGiftMessaged(val)}
                        options={[
                          { value: "true", label: "YES" },
                          { value: "false", label: "NO" },
                        ]}
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Is Gift wrap Avelable"
                      rules={[
                        {
                          required: false,
                          message: "Discount Type is Required!",
                        },
                      ]}
                      name="minimumDiscountType"
                      initialValue={discountType}
                    >
                      <Select
                        onChange={(val) => setDiscountType(val)}
                        options={[
                          { value: "PERCENTAGE", label: "PERCENTAGE" },
                          { value: "FLAT", label: "FLAT" },
                        ]}
                        placeholder="Enter Discount Type"
                      />
                    </Form.Item>
                  </div>
                </div>
                </TabPane>
                <TabPane tab="Shipping" key="6">
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6  grid grid-cols-2 gap-x-4">
                  <h3 className="mb-4 text-xl font-bold col-span-2">
                    Shipping
                  </h3>

                  <div className="">
                    <div className={Styles.productTitle}>
                      <Space direction="vertical">
                        <Form.Item
                          name="weight"
                          label="Product Weight"
                          className="border-none overflow-hidden"
                          //rules={[{ required: false, message: 'Please input donation amount!' }]}
                        >
                          <InputNumber addonBefore={ProductWightSelector} />
                        </Form.Item>
                      </Space>
                    </div>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Product Dimensions"
                      name="keyword"
                    >
                      <span>
                        <Space>
                          <Input
                            type="text"
                            name="dimensionLength"
                            placeholder="Length"
                            style={{ width: 100 }}
                          />
                          <Input
                            type="text"
                            name="dimensionWidth"
                            placeholder="Width"
                            style={{ width: 100 }}
                          />
                          <Input
                            type="text"
                            name="dimensionHeight"
                            placeholder="Height"
                            style={{ width: 100 }}
                          />

                          <Select
                            defaultValue="cm"
                            onChange={(val) => setDimensionUnit(val)}
                          >
                            <Option value="cm">CM</Option>
                            <Option value="m">M</Option>
                            <Option value="inc">Inc</Option>
                          </Select>
                        </Space>
                      </span>
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Dangerous Goods"
                      name="dangerousGoods"
                      valuePropName="checked"
                    >
                      <Radio.Group>
                        <Checkbox
                          value="true"
                          onChange={(val) => setIsBattery(val.target.checked)}
                        >
                          Battery
                        </Checkbox>
                        <Checkbox
                          value="true"
                          onChange={(val) => setIsFlammable(val.target.checked)}
                        >
                          Flammable
                        </Checkbox>
                        <Checkbox
                          value="true"
                          onChange={(val) => setIsLiquid(val.target.checked)}
                        >
                          Liquid
                        </Checkbox>
                        <Checkbox
                          value="true"
                          onChange={(val) => setIsNone(val.target.checked)}
                        >
                          None
                        </Checkbox>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Safety Warning"
                      name="safetyWarning"
                    >
                      <Input
                        id="safetyWarning"
                        type="text"
                        placeholder="Enter Safety Warning"
                      />
                    </Form.Item>
                  </div>

                  <div>
                    <Form.Item
                      hasFeedback
                      label="Shipping Type"
                      name="shippingType"
                      valuePropName="checked"
                      initialValue={shippingTypes}
                    >
                      <Radio.Group>
                        <Checkbox
                          value="true"
                          onChange={(val) =>
                            setAllowFreeShipping(val.target.checked)
                          }
                        >
                          Free Shipping
                        </Checkbox>
                        <Checkbox
                          value="true"
                          onChange={(val) =>
                            setAllowPriorityDelivery(val.target.checked)
                          }
                        >
                          Priority Delivery
                        </Checkbox>
                        <Checkbox
                          value="true"
                          onChange={(val) =>
                            setAllowStandardDelivery(val.target.checked)
                          }
                        >
                          Standard Delivery
                        </Checkbox>
                        <Checkbox
                          value="true"
                          onChange={(val) =>
                            setAllowEconomyDelivery(val.target.checked)
                          }
                        >
                          Economy Delivery
                        </Checkbox>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      hasFeedback
                      label="Shipping Zone"
                      name="shippingZone"
                      valuePropName="checked"
                    >
                      <Radio.Group>
                        <Checkbox value="Local_Shipping">
                          Local Shipping
                        </Checkbox>
                        <Checkbox value="Local_Pickup">Local Pickup</Checkbox>
                        <Checkbox value="International_Shipping">
                          International Shipping
                        </Checkbox>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
                </TabPane>
                <TabPane tab="Gondola" key="7">
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6  grid grid-cols-2 gap-x-4">
                  <h3 className="mb-4 text-xl font-bold col-span-2">
                    Gondola Details
                  </h3>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Self Number"
                      name="selfNumber"
                    >
                      <Input
                        id="selfNumber"
                        type="text"
                        placeholder="Enter Self Number"
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Row Number"
                      name="rowNumber"
                      rules={[
                        {
                          required: false,
                          message: "Row Number is Required!",
                        },
                      ]}
                    >
                      <Input
                        id="rowNumber"
                        type="text"
                        placeholder="Enter Row umber"
                      />
                    </Form.Item>
                  </div>
                </div>
                </TabPane>
                <TabPane tab="SEO" key="8">
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6  grid grid-cols-2 gap-x-4">
                  <h3 className="mb-4 text-xl font-bold col-span-2">SEO</h3>

                  <div className="col-span-2">
                    <Form.Item hasFeedback label="Meta Title" name="metaTitle">
                      <Input
                        id="metaTitle"
                        type="text"
                        placeholder="Enter Meta Title"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-2">
                    <Form.Item hasFeedback label="Keywords" name="keyword">
                      <Input.TextArea
                        id="keyword"
                        placeholder="Enter Product Keywords"
                        rows={3}
                      />
                    </Form.Item>
                  </div>

                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Meta Description"
                      name="metaDescription"
                    >
                      <Input.TextArea
                        id="metaDescription"
                        placeholder="Enter Meta Description"
                        rows={3}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-2">
                    <Form.Item
                      hasFeedback
                      label="Canonical Tag"
                      name="canonicalTag"
                    >
                      <Input
                        id="canonicalTag"
                        type="text"
                        placeholder="Enter Canonical Tag"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-2">
                    <Form.Item
                      hasFeedback
                      label="Image Title"
                      name="imageTitle"
                    >
                      <Input
                        id="imageTitle"
                        type="text"
                        placeholder="Enter Image Title"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-2">
                    <Form.Item
                      hasFeedback
                      label="Image Altr Text"
                      name="imageAltrText"
                    >
                      <Input
                        id="imageAltrText"
                        type="text"
                        placeholder="Enter Image Altr Text"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-2">
                    <Form.Item
                      hasFeedback
                      label="Image Caption"
                      name="imageCaption"
                    >
                      <Input
                        id="imageCaption"
                        type="text"
                        placeholder="Enter Image Caption"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-2">
                    <Form.Item
                      hasFeedback
                      label="Image Description"
                      name="imageDescription"
                    >
                      <Input.TextArea
                        id="imageDescription"
                        placeholder="Enter Image Description"
                        rows={2}
                      />
                    </Form.Item>
                  </div>
                </div>
                </TabPane>
              </Tabs>
              {/* end tabs */}

             
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddRequestedProduct;
