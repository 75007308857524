import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import MerchantLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { SET_MERCHANT_INFO } from "../../../redux/auth/authType";
import Loader from "../../common/Loader";
import moment from "moment";
import { Button } from "antd";
import PersonalDetailsAdd from "./add";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const MerchantDetails = (): ReactElement => {
  const { token, merchantId } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [add, setAdd] = useState(false);
  const dispatch = useDispatch();
  const [singleMerchantInfo, setSingleMerchantInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchMerchantDetails = useCallback(() => {
   
      try {
        setSingleMerchantInfo({ loading: true, data: null });
        axios
          .get(`${process.env.REACT_APP_RIDER_API}/details`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((data) => {
            if (data.status === 200) {
              setSingleMerchantInfo({
                loading: false,
                data: data?.data?.merchant,
              });

              dispatch({
                type: SET_MERCHANT_INFO,
                payload: {
                  merchantInfo: data?.data?.merchant,
                  merchantId: data?.data?.merchant?.merchantId,
                },
              });
            } else {
              setSingleMerchantInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleMerchantInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleMerchantInfo({ loading: false, data: null });
      }
    
    
  }, []); 

  useEffect(() => {
    fetchMerchantDetails();
  }, [fetchMerchantDetails,token ]);

  const merchant = singleMerchantInfo?.data;
  if (singleMerchantInfo.loading) return <Loader />;

  return (
    <React.Fragment>
      <MerchantLayout
        merchantId={merchant?.id}
        title={`Merchant Details`}
        subTitle={`Details`}
      >
        <div className="content-body rounded-2xl bg-white p-4 min-h-full">
          <div className="flex justify-between border-b pb-3 mb-8 border-gray-100">
            <h4 className="font-semibold text-xl text-gray-600">
              Personal Details
            </h4>
           
              {/* <Button shape="round" type="dashed" onClick={() => setAdd(true)}>
                Add
              </Button> */}
              <Link
              to={`/account/my-profile-update`}
              className="rounded-full h-8 w-8 border-2 border-white hover:bg-slate-50 text-center text-red-600 align-center"
            >
              <FontAwesomeIcon icon={faPenToSquare} />
              {/* <span className="ml-2">Edit</span> */}
            </Link>
        
          </div>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="col-span-1">
                <dt className="text-sm font-medium text-gray-800 mb-1">
                  Merchant Name
                </dt>
                <dd className="text-sm font-medium text-gray-500">
                  {merchant?.merchantPersonalDetail?.name}
                </dd>
              </div>

              <div className="col-span-1">
                <dt className="text-sm font-medium text-gray-800 mb-1">
                  Email Address
                </dt>
                <dd className="text-sm font-medium text-gray-500">
                  {merchant?.merchantPersonalDetail?.email}
                </dd>
              </div>

              {merchant?.mobileNumber && (
                <div className="">
                  <dt className="text-sm font-medium text-gray-900 mb-1">
                    Mobile Number
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    {merchant?.mobileNumber?.replace("+88", "")}
                  </dd>
                </div>
              )}

              {merchant?.merchantPersonalDetail?.emergencyContactNumber && (
                <div>
                  <dt className="text-sm font-medium text-gray-800 mb-1">
                    Emergency Contact Number
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    {merchant?.merchantPersonalDetail?.emergencyContactNumber}
                  </dd>
                </div>
              )}

              {merchant?.merchantPersonalDetail?.nationalIdOrPassportNumber && (
                <div>
                  <dt className="text-sm font-medium text-gray-800 mb-1">
                    National Id Or Passport Number
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    {
                      merchant?.merchantPersonalDetail
                        ?.nationalIdOrPassportNumber
                    }
                  </dd>
                </div>
              )}

              {merchant?.merchantPersonalDetail?.dateOfBirth && (
                <div>
                  <dt className="text-sm font-medium text-gray-800 mb-1">
                    Date Of Birth
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    {moment(
                      merchant?.merchantPersonalDetail?.dateOfBirth
                    ).format("lll")}
                  </dd>
                </div>
              )}

              {merchant?.merchantPersonalDetail?.gender && (
                <div>
                  <dt className="text-sm font-medium text-gray-800 mb-1">
                    Gender
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    {merchant?.merchantPersonalDetail?.gender}
                  </dd>
                </div>
              )}

              {merchant?.merchantPersonalDetail?.city && (
                <div>
                  <dt className="text-sm font-medium text-gray-800 mb-1">
                    City
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    {merchant?.merchantPersonalDetail?.city}
                  </dd>
                </div>
              )}

              {merchant?.merchantPersonalDetail?.country && (
                <div>
                  <dt className="text-sm font-medium text-gray-800 mb-1">
                    Country
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    {merchant?.merchantPersonalDetail?.country}
                  </dd>
                </div>
              )}

              {merchant?.serviceType && (
                <div>
                  <dt className="text-sm font-medium text-gray-800 mb-1">
                    Service Type
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
               
                    {merchant?.serviceType.replace("_", " ")} 
                  </dd>
                </div>
              )}

              <div>
                <dt className="text-sm font-medium text-gray-800 mb-1">
                  Status
                </dt>
                <dd className="text-sm font-medium text-gray-500">
                  {merchant?.status}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-800 mb-1">
                  State
                </dt>
                <dd className="text-sm font-medium text-gray-500">
                  {merchant?.state?.split("_").join(" ")}
                </dd>
              </div>
            </dl>
        </div>
      </MerchantLayout>
    </React.Fragment>
  );
};

MerchantDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default MerchantDetails;
