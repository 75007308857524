import * as React from 'react';
import { FAQItem } from '../../../type';

interface FAQSectionProps {
  faqs: FAQItem[];
}

export const FAQSection: React.FC<FAQSectionProps> = ({ faqs }) => {
  return (
    <div className="flex flex-col items-start pt-6 pr-20 pb-20 pl-6 mt-5 max-w-full text-sm font-medium bg-white rounded-xl shadow-sm text-zinc-800 w-[970px] max-md:px-5">
      <div className="text-base font-bold text-cyan-700">FAQ</div>
      {faqs.map((faq, index) => (
        <div key={index} className="mt-4">
          {faq.question}
        </div>
      ))}
    </div>
  );
};