import * as React from "react";
import { MembershipType } from "../../type";

interface MembershipCardProps {
  membership: MembershipType;
}

export function MembershipCard({ membership }: MembershipCardProps) {
  return (
    <div className="flex gap-2 mt-5">
      <img
        loading="lazy"
        src={membership.icon}
        className="object-contain shrink-0 self-start aspect-square w-[18px]"
        alt=""
      />
      <div className="basis-auto">
        {membership.type} {membership.price && `(${membership.price})`}
      </div>
    </div>
  );
}