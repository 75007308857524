import React from "react";
import MerchantLayout from "../../components/MerchantLayouts";
import MembershipUpgrade from "../../components/MemberShipProfile/MembershipUpgrade";

const MembershipUpgradePage = () => {
  return (
    <MerchantLayout>
      <MembershipUpgrade />
    </MerchantLayout>
  ); 
};

export default MembershipUpgradePage;
