import React from "react";
import MerchantLayout from "../../components/MerchantLayouts";
import MembershipLavel from "../../components/MemberShipProfile/MembershipLavel";

const MembershipLavelPage = () => {
  return (
    <MerchantLayout>
      <MembershipLavel />
    </MerchantLayout>
  ); 
};

export default MembershipLavelPage;
