import { Rate, Dropdown, Menu, Modal, Tabs } from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import { useParams } from "react-router";
import ShopLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { SET_SHOP_INFO } from "../../../redux/auth/authType";
import moment from "moment";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ShopStatus } from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
const { confirm } = Modal;
const { TabPane } = Tabs;

const ShopDetails = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const dispatch = useDispatch();
  const route = useParams();
  const [singleShopInfo, setSingleShopInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchShopDetails = useCallback((getShopId: any) => {
    if (getShopId) {
      try {
        setSingleShopInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/shop/details?shopId=${getShopId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleShopInfo({
                loading: false,
                data: data?.data?.shop,
              });
              dispatch({
                type: SET_SHOP_INFO,
                payload: {
                  shopInfo: {
                    id: data?.data?.shop?.id,
                    name: data?.data?.shop?.name,
                    type: data?.data?.shop?.type,
                    mobileNumber: data?.data?.shop?.mobileNumber,
                    logo: data?.data?.shop?.logo,
                    banner: data?.data?.shop?.banner,
                    rating: data?.data?.shop?.rating,
                    status: data?.data?.shop?.status,
                    hubId: data?.data?.shop?.hub?.id,
                    merchantId: data?.data?.shop?.merchantId,
                    deleted: data?.data?.shop?.deleted,
                  },
                },
              });
            } else {
              setSingleShopInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleShopInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleShopInfo({ loading: false, data: null });
      }
    }
  }, []);

  const onStatusChange = async (status: any) => {
    await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/shop`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: singleShopInfo?.data?.id,
        status: status,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Status Updated Successfully", "success");
          dispatch({
            type: SET_SHOP_INFO,
            payload: {
              shopInfo: {
                status: status,
              },
            },
          });
          fetchShopDetails((route as any)?.shopId);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  function showConfirm(status: any) {
    confirm({
      title: `Do you Want to change Status "${status}"?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onStatusChange(status);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "Change Status",
    });
  }

  useEffect(() => {
    fetchShopDetails((route as any)?.shopId);
  }, [fetchShopDetails]);

  const shop = singleShopInfo?.data;

  return (
    <React.Fragment>
      <ShopLayout
        shopId={shop?.id}
        title={`Shop Details`}
        subTitle={`Details`}
        extra={
          <div className="bg-white shadow-md rounded-md p-1">
            <Dropdown.Button
              overlay={
                <Menu
                  onClick={(val) => {
                    showConfirm(val?.key);
                  }}
                >
                  {Object?.values(ShopStatus)?.map((status, i) => (
                    <Menu.Item key={status}>{status}</Menu.Item>
                  ))}
                </Menu>
              }
            >
              {shop?.status?.split("_").join(" ") || "Status"}
            </Dropdown.Button>
          </div>
        }
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
            <Tabs
              onTabScroll={() => {
                direction: top;
              }}
              tabPosition="top"
              tabBarExtraContent={
                <Link
                  to={`/shops/${shop?.id}/edit`}
                  className="rounded-full h-8 w-8 border-2 border-white hover:bg-slate-50 text-center text-red-600 align-center"
                >
                  <FontAwesomeIcon icon={faPenToSquare} />
                  <span className="ml-2">Edit</span>
                </Link>
              }
            >
              <TabPane tab="GENERAL INFO" key="General">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Shop Name
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {shop?.name}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Shop Slug
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {shop?.slug}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Shop ID
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {shop?.id}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      E-mail
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {shop?.email}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Contact Number
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {shop?.contactNumber?.split("+88")}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Mobile Number
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {shop?.mobileNumber?.split("+88")}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Current Balance
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      &#2547; {shop?.balance || 0}
                    </dd>
                  </div>

                  {shop?.baseDeliveryCharge && (
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Base Delivery Charge
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {shop?.baseDeliveryCharge}
                      </dd>
                    </div>
                  )}
                  {shop?.isFreeDelivery && (
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Free Delivery ?
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {shop?.isFreeDelivery}
                      </dd>
                    </div>
                  )}

                  {shop?.minimumAmountForFreeDelivery && (
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Minimum Amount For Free Delivery
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {shop?.minimumAmountForFreeDelivery}
                      </dd>
                    </div>
                  )}

                  {shop?.prepareTime && (
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Prepare Time
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {moment
                          .duration({
                            minutes: shop?.prepareTime,
                          })
                          .humanize()}
                        {/* .fixed()} */}
                      </dd>
                    </div>
                  )}

                  {shop?.status && (
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Status
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {shop?.status?.split("_").join(" ")}
                      </dd>
                    </div>
                  )}

                  {shop?.type && (
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Type
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {shop?.type?.replace("_", " ")}
                      </dd>
                    </div>
                  )}

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Joining Date
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {moment(shop?.createdAt).format("lll")}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Rattings
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      <Rate value={shop?.rating || 1} disabled />
                      <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                        {shop?.numberOfRating || 0}
                      </span>
                    </dd>
                  </div>

                  {shop?.versionCode && (
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Version Code
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {shop?.versionCode}
                      </dd>
                    </div>
                  )}
                  <div className="sm:col-span-2__">
                    <dt className="text-md font-medium text-gray-900">
                      Description
                    </dt>
                    <dd className="mt-1 space-y-3 text-sm text-gray-500">
                      <p>{shop?.description}</p>
                    </dd>
                  </div>

                  <div className="sm:col-span-2__">
                    <dt className="text-md font-medium text-gray-900">
                      Keywords
                    </dt>
                    <dd className="mt-1 space-y-3 text-sm text-gray-500">
                      <p>{shop?.keywords}</p>
                    </dd>
                  </div>
                </dl>
              </TabPane>
            

        
            </Tabs>
          </div>
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};

ShopDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopDetails;
