import { Layout, PageHeader } from "antd";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getImgUrl } from "../../../../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "./sidebar";
import { responseNotification } from "../../../../utils/notify";
import axios from "axios";
import { SET_CAMPAIGN_INFO } from "../../../../redux/auth/authType";
import { CountdownTimer } from "../../../common/CountDown";
import cn from "classnames";
const { Content } = Layout;

const CampaignLayout = ({
  children,
  campaignId,
  title,
  extra,
  subTitle,
}: {
  children: ReactElement;
  campaignId: string;
  title: string;
  subTitle?: string;
  extra?: ReactElement;
}): ReactElement => {
  const navigate = useNavigate();
  const { campaignInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const route = useParams();
  const shopId = (route as any)?.shopId;
  const dispatch = useDispatch();


  const [singleCampaignInfo, setSingleCampaignInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchCampaignDetails = useCallback((getCampaignId: any) => {
    if (getCampaignId) {
      try {
        setSingleCampaignInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/campaign/details/${getCampaignId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleCampaignInfo({
                loading: false,
                data: data?.data?.campaign,
              });
              dispatch({
                type: SET_CAMPAIGN_INFO,
                payload: {
                  campaignInfo: data?.data?.campaign,
                  // campaignInfo: {
                  //   id: data?.data?.campaign?.id,
                  //   name: data?.data?.campaign?.name,
                  //   campaignType: data?.data?.campaign?.campaignType,
                  //   appsImg: data?.data?.campaign?.banner,
                  //   webImg: data?.data?.campaign?.bannerWeb,
                  //   status: data?.data?.campaign?.status,
                  //   description: data?.data?.campaign?.description,
                  // },
                },
              });
            } else {
              setSingleCampaignInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleCampaignInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleCampaignInfo({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    fetchCampaignDetails((route as any)?.campaignId);
  }, [fetchCampaignDetails]);

  const campaign = singleCampaignInfo?.data;

  return (
    <React.Fragment>
      <Layout className="min-vh-100">
        <main>
          <div className="grid grid-cols-3 px-2 pt-4 xl:grid-cols-10 gap-4 xl:gap-4">
            <div className="col-span-full">
              <PageHeader
                className="site-page-header px-0 py-0"
                onBack={() => navigate(-1)}
                title={
                  <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                    {title}
                  </h1>
                }
                extra={extra}
              />
              <nav className="flex mb-0" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2">
                  <li className="inline-flex items-center">
                    <Link
                      to="/promotion/campaign"
                      className="inline-flex items-center text-gray-700 hover:text-gray-900"
                    >
                      Campaign
                    </Link>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span
                        className="ml-1 text-sm font-medium text-gray-400 md:ml-2"
                        aria-current="page"
                      >
                        {subTitle}
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>

            <div className="col-span-full xl:col-span-3">
              <div className="grid grid-cols-2 md:grid-cols-2 gap-4 xl:grid-cols-1">
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl">
                  <div className="sm:flex__ xl:block sm:space-x-4 xl:space-x-0">
                    <div className="w-full max-h-64 overflow-hidden">
                      <img
                        src={campaignInfo?.bannerWeb}
                        alt=""
                        className="rounded-t-xl w-full object-cover"
                      />
                    </div>
                    <div className="p-4">
                      <div>
                        <div className="text-center">
                          <h2 className="text-lg font-bold">
                            {campaignInfo?.name?.toUpperCase()}
                          </h2>
                          <span className="text-slate-400 text-xs">
                            {campaignInfo?.campaignType?.split("_").join(" ")}
                          </span>
                        </div>

                        <div className="py-4">
                          <CountdownTimer targetDate={campaignInfo?.endAt} />
                        </div>

                        <div className="-mt-14__ flex justify-center items-baseline text-center">
                          <div className="">
                            <div className="flex">
                              {campaignInfo?.isFlashSale && (
                                <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-pink-500 hover:to-yellow-500 text-white font-bold py-1 px-4 mr-2 rounded-full shadow-md">
                                  {campaignInfo?.isFlashSale && "FLASH SALE"}
                                </div>
                              )}
                              <div
                                className={cn(
                                  "hover:from-pink-500 hover:to-yellow-500 text-white font-bold py-1 px-4 rounded-full shadow-md bg-gradient-to-r from-indigo-500 via-pink-500 to-red-500",
                                  {
                                    ["bg-gradient-to-r from-green-400 to-blue-500"]:
                                      campaignInfo?.isActive,
                                  }
                                  // {campaignInfo?.isActive
                                  //   ? "bg-gradient-to-r from-green-400 to-blue-500"
                                  //   : "bg-gradient-to-r from-indigo-500 via-pink-500 to-red-500"}
                                )}
                              >
                                {campaignInfo?.isActive ? "ACTIVE" : "INACTIVE"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Sidebar campaignId={campaignId} />
              </div>
            </div>
            <div className="col-span-3 xl:col-span-7">
              <Layout>
                <Content className="main-content-layout">{children}</Content>
              </Layout>
            </div>
          </div>
        </main>
      </Layout>
    </React.Fragment>
  );
};

CampaignLayout.propType = {
  children: PropTypes.element,
};

export default CampaignLayout;
