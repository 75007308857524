import { BenefitItem, ContactInfo, FAQItem, HeaderAction, MembershipType, UserInfo } from "../../type";

export const membershipTypes: MembershipType[] = [
    { type: "Free Membership", price: "", isSelected: true, icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/852a8724c0a159907c7d4c9d9456763b765190cc9f92c84c692f40897141d938?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7" },
    { type: "Bronze", price: "1 Month 100 Tk", isSelected: false, icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3b69c096665147730e50d44f3c4d8b73bdca07118610c004987f9b0d84f820dc?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7" },
    { type: "Silver", price: "1 Month 200 Tk", isSelected: false, icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5376a51f8ba43b2bce69ddce018ef03f6d9ad0f1dae2da11bab4e3effeb6b3a5?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7" },
    { type: "Titanium", price: "2 Month 250 Tk", isSelected: false, icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5376a51f8ba43b2bce69ddce018ef03f6d9ad0f1dae2da11bab4e3effeb6b3a5?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7" },
    { type: "Gold", price: "2 Month 300 Tk", isSelected: false, icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5376a51f8ba43b2bce69ddce018ef03f6d9ad0f1dae2da11bab4e3effeb6b3a5?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7" },
    { type: "Platinum", price: "2 Month 320 Tk", isSelected: false, icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5376a51f8ba43b2bce69ddce018ef03f6d9ad0f1dae2da11bab4e3effeb6b3a5?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7" },
    { type: "Diamond", price: "5 Month 500 Tk", isSelected: false, icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5376a51f8ba43b2bce69ddce018ef03f6d9ad0f1dae2da11bab4e3effeb6b3a5?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7" }
  ];
  
  export const benefits: BenefitItem[] = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8356e925f6d9e9e6ce7f21f217b7b1c526d2836ab49d9b33e1b19d02c481e8b4?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7",
      description: "Earn points with every eligible ride and order"
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f968f1e865c03b6ed2b92ec7e23a38fc839a50793675c7e607757f3541f58a5c?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7",
      description: "Redeem points on in-app deals, which can be used for discount on future jachai order"
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e3193594ffc82a508933857f2172b37c6b5d948614904c5ef2fb46008b90f1e5?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7",
      description: "Access special benefits, such as discounts at popular restaurants and fashionable brands"
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/aed14ac0d9692e59e39bc245e1edc98a32a602bddcef0c3c06f36ca3958089c0?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7",
      description: "Unlock exclusive in-app perks"
    }
  ];
  export const faqs: FAQItem[] = [
    { question: "What is jachai points?" },
    { question: "How will I benefit as a jachai points user?" },
    { question: "How can i earn points?" },
    { question: "Can I earn points on promo rides?" }
  ];
  export const userInfo: UserInfo = {
    id: "JM 5421547",
    level: "BRONZE",
    points: 485
  };
  export const contactInfo: ContactInfo[] = [
    { phone: "09638111600", email: "support@jachai.com", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d95c0b4f4bf24aa24211352f9c458bb22119127c35893e890892c2e4ae45cc23?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7" }
  ];
  
  export const headerActions: HeaderAction[] = [
    { label: "Help", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5a302d33060f273d65611dc947f62f00b06cb4f0ce2f585cbbb14256bebf3dc3?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7" },
    { label: "App!", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d13b6d77b082b9905a5bfb067fd775435ad5afda521fc9d8372300a553d2bc36?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7" },
    { label: "Wishlist", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3e428cf0cb6dce4f6789b9fb521e0bea584ec1c812ba91c625ebefca95b617ca?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7" },
    { label: "Sign up", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d161c8bcb5053fadfbdac7d6c79893a565c88612ee346abb38c658be1faa60fe?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7" }
  ];