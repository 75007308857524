import {
  EditOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import styles from "./Profile.module.css";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Switch,
  Tooltip,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
interface EmptyProps {
  height?: string;
  data?: string;
}
import { debounce } from "lodash";
import axios from "axios";
import { responseNotification } from "../../../utils/notify";
import { Link, useNavigate, useParams } from "react-router-dom";
import MapBox from "../../common/MapBox";
import BreadCrumb from "../../Layouts/Breadcrumb";
import ShopLayout from "../Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const BusinessContractAddress = () => {
  const { type, shopInfo, merchantInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const route = useParams();
  const shopId = shopInfo?.id;
  const { businessLat } = useParams();
  const initialState = useSelector((state) => (state as any)?.authReducer);
  
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [merchantShopCheck, setMerchantShopCheck] = useState<any>();
  const [businessCountryId, setBusinessCountryId] = useState<any>(0);

  const [swhCountryId, setSwhCountryId] = useState<any>(0);
  const [pwCountryId, setPwCountryId] = useState<any>(0);
  const [spCountryId, setSpCountryId] = useState<any>(0);
  const [srCountryId, setSrCountryId] = useState<any>(0);
  const [countryId, setCountryId] = useState<any>(0);
  const [prCountryId, setPrCountryId] = useState<any>(0);
  const [ppCountryId, setPpCountryId] = useState<any>(0);
  const [businessCityId, setBusinessCityId] = useState<any>(0);
  const [swhCityId, setSwhCityId] = useState<any>(0);
  const [pwCityId, setPwCityId] = useState<any>(0);
  const [spCityId, setSpCityId] = useState<any>(0);
  const [ppCityId, setPpCityId] = useState<any>(0);
  const [srCityId, setSrCityId] = useState<any>(0);
  const [prCityId, setPrCityId] = useState<any>(0);
  const [cityId, setCityId] = useState<any>(0);
  const [stateId, setStateId] = useState<any>();
  const [businessStateId, setBusinessStateId] = useState<any>();
  const [prStateId, setPrStateId] = useState<any>("");
  const [srStateId, setSrStateId] = useState<any>("");
  const [ppStateId, setPpStateId] = useState<any>("");
  const [spStateId, setSpStateId] = useState<any>("");
  const [swhStateId, setSwhStateId] = useState<any>("");
  const [pwStateId, setPwStateId] = useState<any>("");
  const [businessAddress, setBusinessAddress] = useState<any>(true);
  const [setOnMap, setSetOnMap] = useState<any>(false);
  const [sellerWarehouseAddress, setSellerWarehouseAddress] =
    useState<any>(true);
  const [platformWarehouseAddress, setPlatformWarehouseAddress] =
    useState<any>(true);
  const [sellerPickupAddress, setSellerPickupAddress] = useState<any>(true);
  const [platformPickupAddress, setPlatformPickupAddress] = useState<any>(true);
  const [sellerReturnAddess, setSellerReturnAddess] = useState<any>(true);
  const [platformReturnAddress, setPlatformReturnAddress] = useState<any>(true);
  const [operationType, setOperationType] = useState<any>("INDIVIDUAL");
  const [businessType, setBusinessType] = useState<any>();
  const [serviceId, setServiceId] = useState<any>();
  const [companyId, setCompanyId] = useState<any>();
  const [isChecked, setIsChecked] = useState(false);
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const [singleBusinessCountry, setSingleBusinessCountry] =
  useState<any>({
    loading: false,
    data: null,
  });
  const [singleBusinessState, setSingleBusinessState] =
  useState<any>({
    loading: false,
    data: null,
  });
  const [singleBusinessCity, setSingleBusinessCity] =
  useState<any>({
    loading: false,
    data: null,
  });


  const [singleBusinessContactInfo, setSingleBusinessContactInfo] =
    useState<any>({
      loading: false,
      data: null,
    });
  const getMerchantShopCheck = useCallback(async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/merchant/all-shops-by-merchant`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMerchantShopCheck(res?.data?.shops);
  }, []);



  const fetchRef = useRef(0);


  const fetchBusinessContactDetails = useCallback((shopId: any) => {
    if (shopId) {
      try {
        setSingleBusinessContactInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/merchant-shop/my-shop-details?shopId=${shopId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleBusinessContactInfo({
                loading: false,
                data: data?.data?.shop,
              });
              setBusinessCountryId(
                data?.data?.shop?.merchantBusinessAndContactInfo?.country
              );
              setBusinessStateId(
                data?.data?.shop?.merchantBusinessAndContactInfo?.state
              );
              setBusinessCityId(
                data?.data?.shop?.merchantBusinessAndContactInfo?.city
              );
              setSwhCountryId(data?.data?.shop?.warehouseAddressInfos?.country);
              setSwhStateId(data?.data?.shop?.warehouseAddressInfos?.state);
              setSwhCityId(data?.data?.shop?.warehouseAddressInfos?.city);
              setPwCountryId(
                data?.data?.shop?.platformWarehouseAddress?.country
              );
              setPwStateId(data?.data?.shop?.platformWarehouseAddress?.state);
              setPwCityId(data?.data?.shop?.platformWarehouseAddress?.city);
              setSpCountryId(data?.data?.shop?.pickupAddressInfos?.country);
              setSpStateId(data?.data?.shop?.pickupAddressInfos?.state);
              setSpCityId(data?.data?.shop?.pickupAddressInfos?.city);
              setPpCountryId(data?.data?.shop?.platformPickUpAddress?.country);
              setPpStateId(data?.data?.shop?.platformPickUpAddress?.state);
              setPpCityId(data?.data?.shop?.platformPickUpAddress?.city);
              setSrCountryId(data?.data?.shop?.returnAddressInfos?.country);
              setSrStateId(data?.data?.shop?.returnAddressInfos?.state);
              setSrCityId(data?.data?.shop?.returnAddressInfos?.city);
              setPrCountryId(data?.data?.shop?.platformReturnAddress?.country);
              setPrStateId(data?.data?.shop?.platformReturnAddress?.state);
              setPrCityId(data?.data?.shop?.platformReturnAddress?.city);
            } else {
              setSingleBusinessContactInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleBusinessContactInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleBusinessContactInfo({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    fetchBusinessContactDetails(shopId);
  }, [fetchBusinessContactDetails, shopId]);

  const fetchBusinessContactCountryDetails = useCallback((businessCountryId: any) => {
    if (businessCountryId) {
      try {
        setSingleBusinessCountry({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/admin/country-state/country-details?countryId=${businessCountryId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
                setSingleBusinessCountry({
                loading: false,
                data: data?.data?.country,
              });
            } else {
                setSingleBusinessCountry({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleBusinessCountry({ loading: false, data: null });
          });
      } catch (error) {
        setSingleBusinessCountry({ loading: false, data: null });
      }
    }
  }, []);
  const fetchBusinessContactStateDetails = useCallback((businessStateId: any) => {
    if (businessStateId) {
      try {
        setSingleBusinessState({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/admin/country-state/state-details?stateId=${businessStateId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
                setSingleBusinessState({
                loading: false,
                data: data?.data?.state,
              });
            } else {
                setSingleBusinessState({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleBusinessState({ loading: false, data: null });
          });
      } catch (error) {
        setSingleBusinessState({ loading: false, data: null });
      }
    }
  }, []);

  const fetchBusinessContactCityDetails = useCallback((businessCityId: any) => {
    if (businessCityId) {
      try {
        setSingleBusinessCity({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/admin/country-state/city-details?cityId=${businessCityId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
                setSingleBusinessCity({
                loading: false,
                data: data?.data?.city,
              });
            } else {
                setSingleBusinessCity({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleBusinessCity({ loading: false, data: null });
          });
      } catch (error) {
        setSingleBusinessCity({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    fetchBusinessContactCityDetails(businessCityId);
  }, [fetchBusinessContactCityDetails, businessCityId]);

  useEffect(() => {
    fetchBusinessContactStateDetails(businessStateId);
  }, [fetchBusinessContactStateDetails, businessStateId]);

  useEffect(() => {
    fetchBusinessContactCountryDetails(businessCountryId);
  }, [fetchBusinessContactCountryDetails, businessCountryId]);


  useEffect(() => {
    if (singleBusinessContactInfo?.data) {
      form.resetFields();

    }
  }, [form, singleBusinessContactInfo?.data]);
  useEffect(() => {
    getMerchantShopCheck();
  }, [getMerchantShopCheck]);

  const resetData = () => {
    form?.resetFields();
  };

  return (
    <>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Bank List"
        subTitle="Bank List"
        extra={
        <>
          <Link
              to={`/shops/${singleBusinessContactInfo?.data?.id}/contact-update`}
              className="rounded-full h-8 w-8 border-2 border-white hover:bg-slate-50 text-center text-red-600 align-center"
            >
              <FontAwesomeIcon icon={faPenToSquare} />
              <span className="ml-2">Edit</span>
            </Link>
        </>
        }
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
            <h3 className="mb-4 text-xl font-bold">Business Info</h3>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Business name 
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  {singleBusinessContactInfo?.data?.name}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-900">Address</dt>
                <dd className="text-sm font-semibold text-gray-500">
                  {singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo?.address}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Country/Region
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  {singleBusinessCountry?.data?.name}
                  </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-900">
                  State/Province
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                {singleBusinessState?.data?.name}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-900">City</dt>
                <dd className="text-sm font-semibold text-gray-500">
                {singleBusinessCity?.data?.name}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Postal/Gip Code
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                {singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo?.postalCode}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Nearest Landmark
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                {singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo?.nearestLandMark}
                </dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Contact Person Name
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                {singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo?.contactPersonName}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-900">
                Contact Person Number
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                {singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo?.contactPersonNumber}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-900">
                Email
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                {singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo?.contactPersonEmail}
                </dd>
              </div>

           
            </dl>
          </div>
        </div>

   
      </ShopLayout>
    </>
  );
};

export default BusinessContractAddress;
