import React from "react";
import MerchantLayout from "../../components/MerchantLayouts";
import BusinessContractList from "../../components/Business/BusinessContractList";

const BusinessContractListPage = () => {
  return (
    <MerchantLayout>
      <BusinessContractList />
    </MerchantLayout>
  ); 
};

export default BusinessContractListPage;
