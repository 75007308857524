import * as React from 'react';
import { BenefitItem } from '../../../type';

interface BenefitsListProps {
  benefits: BenefitItem[];
}

export const BenefitsList: React.FC<BenefitsListProps> = ({ benefits }) => {
  return (
    <div className="flex flex-col items-start pt-4 pr-20 pb-11 pl-6 mt-5 max-w-full text-sm font-medium bg-white rounded-xl shadow-sm text-zinc-800 w-[970px] max-md:px-5">
      <div className="text-base font-bold text-cyan-700">How it works</div>
      {benefits.map((benefit, index) => (
        <div key={index} className="flex gap-3.5 mt-4">
          <img
            loading="lazy"
            src={benefit.icon}
            alt=""
            className="object-contain shrink-0 self-start aspect-[1.06] fill-amber-400 w-[19px]"
          />
          <div className="flex-auto">{benefit.description}</div>
        </div>
      ))}
    </div>
  );
};