import * as React from "react";
import { MembershipCard } from "./MembershipCard";
import { membershipTypes, headerActions, contactInfo } from "./MembershipLavelData";

export default function MembershipPage() {
  return (
    <div className="flex overflow-hidden flex-col bg-stone-50 pb-[1513px] max-md:pb-24">
  

      <main className="flex flex-col self-center mt-8 max-w-full w-[970px]">
        <section className="flex gap-5 justify-between pr-6 bg-orange-100 rounded-xl shadow-sm max-md:pr-5 max-md:max-w-full">
          <div className="flex flex-col items-center py-4 pr-4 pl-12 bg-amber-400 rounded-xl max-md:pl-5">
            <div className="flex gap-5 justify-between self-end max-w-full w-[148px]">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3999017625fdadb0717b10a5e66154d5905b3cf936b8d7c53ea3d110a8c0b655?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
                className="object-contain shrink-0 w-20 aspect-square"
                alt="User avatar"
              />
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/46ed3c01b4cfe3b8f328be78f35dbfeb060f03a3c056c210974a1fae76d918dc?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
                className="object-contain shrink-0 self-start aspect-square w-[18px]"
                alt=""
              />
            </div>
            <div className="mt-2 text-lg font-bold text-center text-zinc-800">
              New User
            </div>
            <div className="text-sm font-medium text-center text-zinc-800">
              JM 5421547
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/edd84c2caa5cd4f04b160d63a6dc8d11426a17533e354563a23f47aa53177210?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
              className="object-contain rounded-none aspect-[5.68] w-[68px]"
              alt=""
            />
          </div>
          <div className="flex flex-col my-auto text-xs max-md:max-w-full">
            <div className="flex flex-wrap gap-5 justify-between items-start px-4 py-3 bg-cyan-700 rounded-md max-md:max-w-full">
              <div className="flex flex-col mt-1.5 text-white">
                <div className="self-start font-bold">jachai.com Membership</div>
                <div className="mt-3 font-medium">
                  Explore US Tk 10 of wih a new seller and more benefits
                </div>
              </div>
              <button className="px-3.5 py-1.5 font-medium text-center text-cyan-700 whitespace-nowrap bg-white rounded-[36px]">
                Explore
              </button>
            </div>
            <div className="mt-3.5 font-medium text-gray-700 max-md:max-w-full">
              <span className="text-sm font-bold leading-5">
                Verification in progress:
              </span>{" "}
              <span className="text-sm leading-5">
                our team is review your Documents. Expect a response within 48
                hours. Thank you for your patience.
              </span>
            </div>
          </div>
        </section>

        <section className="mt-8">
          <h2 className="text-base font-bold text-zinc-800">
            jachai.com Membership
          </h2>
          <p className="mt-2 text-xs font-medium text-zinc-800">
            Explore US Tk 10 of wih a new seller and more benefits
          </p>
          <div className="flex flex-wrap gap-10 items-start pt-5 pr-20 pb-11 pl-7 mt-4 w-full bg-white rounded-md border border-solid border-neutral-200 max-md:px-5">
            <div className="flex flex-col items-start self-start text-sm font-medium text-zinc-800">
              <div className="font-bold text-black">Membership Type</div>
              {membershipTypes.map((membership, index) => (
                <MembershipCard key={index} membership={membership} />
              ))}
            </div>
          </div>
        </section>

        <div className="flex gap-8 self-end mt-8 text-sm font-bold text-center text-white whitespace-nowrap">
          <button className="px-14 py-4 bg-amber-600 rounded-md max-md:px-5">
            Cancel
          </button>
          <button className="px-12 py-4 bg-rose-700 rounded-md max-md:px-5">
            Continue
          </button>
        </div>
      </main>
    </div>
  );
}