import * as React from "react";
import { FileUploadProps } from "../../../type";

export const FileUpload: React.FC<FileUploadProps> = ({ label }) => (
  <div className="grow px-8 py-4 rounded border border-solid bg-neutral-200 border-neutral-300 w-fit max-md:px-5">
    <label className="cursor-pointer">
      <input type="file" className="hidden" aria-label={label} />
      <span className="text-xs font-medium text-neutral-500">{label}</span>
    </label>
  </div>
);