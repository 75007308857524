import {
  faBullhorn,
  faShop,
  faChartLine,
  faBagShopping,
  faCartShopping,
  faPercentage,
  faCartFlatbed,
  faClone,
  faUserCog,
  faComment,
  faCogs,
  faCube,
  faCog,
  faList12,
  faList,
  faShopSlash,
  faBusinessTime,
  faBriefcase,
  faJetFighterUp,
  faCodeBranch,
  faUserShield,
  faUser,
  faBangladeshiTakaSign,
  faTruckFast,
  faUpload,
  faGifts,
  faTicket,
  faAlignLeft,
  faBell,
  faAlignCenter,
  faClipboardList,
  faUsers,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import UserManagementPage from "../pages/administration";
import ServiceGroupPage from "../pages/administration/groups";
import UserServicePage from "../pages/administration/services";
import { ServiceType } from "../type/service";
import OrderPage from "../pages/orders";
import ShopPage from "../pages/shops";
import ShopDetailsPage from "../pages/shops/details";
import ShopOrderPage from "../pages/shops/orders";
import ShopManualOrderPage from "../pages/shops/orders/manual-order-create";
import ShopProductOutOfStockPage from "../pages/shops/products/out-of-stock";
import ShopBrandsPage from "../pages/shops/brands";
import ShopUserPage from "../pages/shops/users/index";
import ShopBanksPage from "../pages/shops/banks";
import ShopBusinessInfoPage from "../pages/shops/businessInfo";
import ShopBusinessInfoUpdatePage from "../pages/shops/businessInfo/edit";
import ShopContractAddressPage from "../pages/shops/contract-address";
import ShopFacebookPageGroupPage from "../pages/shops/facook/index";
import ShopContractAddressUpdatePage from "../pages/shops/contract-address/edit";
import ShopDeliveryShippingPage from "../pages/shops/delivery-shipping/index";
import ShopShippingAddRatePage from "../pages/shops/delivery-shipping/add-rate";
import ShopShippingProfilePage from "../pages/shops/delivery-shipping/add-rate";
import BrandDetailsPage from "../pages/brands/details";
import ShopAddPage from "../pages/shops/add"; 
import ProductPage from "../pages/products";
import ProductDetailsPage from "../pages/products/details";
import ProductEditPage from "../pages/products/edit";
import ProductAddPage from "../pages/products/create";
import SingleOrderPage from "../pages/orders/details";
import ShopProductPage from "../pages/shops/products";
import ShopAffiliateProductPage from "../pages/shops/affiliate/index";
import ShopRequestedProductPage from "../pages/shops/products/requested-products";
import ShopProductCataloguePage from "../pages/shops/products/productCatalogue";
import ShopRequestProductDetailsPage from "../pages/requested-products/details";
import ShopProductDetailsPage from "../pages/shops/products/details";
import ShopCategoryPage from "../pages/shops/categories";
import ShopOrderDetailsPage from "../pages/shops/orders/details";
import ShopProductAddPage from "../pages/shops/products/add";
import ShopRequestProductAddPage from "../pages/shops/products/add-req-product";
import ShopProductBulkUploadPage from "../pages/shops/products/bulkupload";
import ShopProductBulkUpdatePage from "../pages/shops/products/buklupdate";
import ShopRequestProductEditPage from "../pages/shops/products/request-product-edit";
import ShopProductEditPage from "../pages/shops/products/edit";
import ShopCampaignPage from "../pages/shops/promotions/campaign";
import ShopPromoCodePage from "../pages/shops/promotions/campaign/promo-codes";
import CampaignPage from "../pages/promotions/campaign";
import CampaignDetailsPage from "../pages/promotions/campaign/details";
import OrderCreatePage from "../pages/orders/manual-order-create";
import DashboardPage from "../pages/dashboard";
import MainSellerDashboardPage from "../pages/main-seller-dashboard";
import CampaignAssignProductPage from "../pages/promotions/campaign/assign-products";
import CampaignProductPage from "../pages/promotions/campaign/products";
import RequestedProductPage from "../pages/requested-products";
import AddRequestedProductPage from "../pages/requested-products/create";
import GlobalProductPage from "../pages/global-product";
import FlightSearchPage from "../pages/flight-tour/flight-search";
import GlobalProductAddPage from "../pages/global-product/create";
import GlobalProductEditPage from "../pages/global-product/edit";
import GlobalProductDetailsPage from "../pages/global-product/details";
import BankDetailsPage from "../pages/account-settings/bank";
import MerchantDetailsPage from "../pages/account-settings";
import MerchantProfileUpdatePage from "../pages/account-settings/profile-update";
import WarehouseInfoPage from "../pages/account-settings/warehouse";
import ReqProductDetailsPage from "../pages/requested-products/details";
import ChatPage from "../pages/chats";
import ShopChatPage from "../pages/shops/chats/index";
import RequestProductEditPage from "../pages/requested-products/edit";
import KeyAccountPage from "../pages/account-settings/key-account-manager";
import ReferralsPage from "../pages/account-settings/referrals";
import DeliveryConfigPage from "../pages/account-settings/delivery-configuration";
import ResaleProductPage from "../pages/resale-products";
import OrderImagePage from "../pages/orders-image";

import SellerStorePage from "../pages/seller/index";
import MerchantProfilePage from "../pages/merchant-profile-old/index";
import MemberShipAddPage from "../pages/merchant-profile-old/membership-add";
import MemberShipLavelPage from "../pages/merchant-profile-old/membership-lavel";
import BusinessDetailsPage from "../pages/business-details/index";
import FlightTourPage from "../pages/flight-tour/index";
import SelectSearchTicketReviewPage from "../pages/flight-tour/search-tricket-review";
import FlightMarkupPage from "../pages/flight-tour/flight-markup";
import CreateFlightPage from "../pages/flight-tour/create-flight-tricket";
import BranchManagementPage from "../pages/branch-management/index";

import BusinessCreatePage from "../pages/merchant-profile-old/business-create";
import BusinessContractAddressPage from "../pages/merchant-profile-old/business-contract-address";
import BusinessInfoPage from "../pages/merchant-profile-old/business-info";

import FinancePaymentPage from "../pages/finance/index";
import DeliveryShippingPage from "../pages/delivery-shipping/index";
import ManualShippingOrdersPage from "../pages/delivery-shipping/index";
import ShippingRatesPage from "../pages/delivery-shipping/shipping-rates";
import ShippingProfilePage from "../pages/delivery-shipping/shipping-profile";
import ProcessingTimePage from "../pages/delivery-shipping/processing-time";
import ShippingZonePage from "../pages/delivery-shipping/shipping-zone";
import ShippingPlacePage from "../pages/delivery-shipping/shipping-place";
import AddPackagePage from "../pages/delivery-shipping/add-package";
import AddRatePage from "../pages/delivery-shipping/add-rate";
import PromoCodePage from "../pages/promotions/campaign/promo-codes";

import GroupTicketPage from "../pages/group-ticket";
import GroupTicketAddPage from "../pages/group-ticket/add";
import GroupTicketSearchPage from "../pages/group-ticket/SearchTicket";
import SelectGroupTicketReviewPage from "../pages/group-ticket/groupTricketReview";
import MyTransactionPage from "../pages/reports/transactions";
import MySettlementPage from "../pages/reports/my-settlements";
import VRHistoryPage from "../pages/reports/vr-history";
import DeliveryReportPage from "../pages/reports/delivery-report";
import SalesReportPage from "../pages/reports/sales-report";
import RevenueReportPage from "../pages/reports/revenue-report";
import OrderVatTaxPage from "../pages/reports/order-vat-tax";
import VatTaxDeliveryReportPage from "../pages/reports/delivery-vat-tax";
import UserPage from "../pages/users";
import UserDetailsPage from "../pages/users/details";
import FileUploaderPage from "../pages/file-uploader";
import MembershipPage from "../pages/membership";
export const isAllowedService = (name: string) => {
  const services = localStorage.getItem("services");

  if (services) {
    const listfromApi: ServiceType[] = JSON.parse(services);
    const data = listfromApi.find((itemfromApi) => {
      return itemfromApi.serviceName === name;
    });
    if (data) {
      return true;
    }
  }
  return false;
};

export const allowedActions = (name: string) => {
  const services = localStorage.getItem("services");

  if (services) {
    const list: ServiceType[] = JSON.parse(services);
    const data = list.filter((item) => item.serviceName === name);
    if (data.length > 0) {
      data.map((d) => d.action);
    }
  }
  return [];
};

export const isEditableService = (name: string) => {
  const services = localStorage.getItem("services");

  if (services) {
    const list: ServiceType[] = JSON.parse(services);
    const data = list.find(
      (item) => item.serviceName === name && item.action === "all"
    );

    if (data) {
      return true;
    }
  }
  return false;
};

export const getAllServices = () => {
  const services = localStorage.getItem("services");
  if (services) {
    return JSON.parse(services);
  }
  return [];
};

type AdminService = {
  name: string;
  path?: string;
  icon?: any;
  element?: any;
  sub?: AdminService[];
  isParent?: boolean;
};
 
//type userType = "SUPER_MERCHANT" | "MERCHANT" | "SHOP";
// type type =
//   | "JC_GROCERY"
//   | "JC_FOOD"
//   | "JC_MALL"
//   | "JC_PAYMENT"
//   | "JC_BOOKING"
//   | "JC_RIDER"
//   | "JC_PARCEL"
//   | "JC_MEDICINE"
//   | "JC_COMMERCE";



export const adminServices: AdminService[] = [
  // { 
  //   name: "Dashboard",
  //   path: "/",
  //   icon: faChartLine,
  //   element: DashboardPage,
  //   type: ["JC_BOOKING"],
  //    userTypes: ['SUPER_MERCHANT', 'MERCHANT','SHOP'],
  // },

  //Main Seller Dashboard
  {
    name: "Dashboard",
    path: "/",
    icon: faChartLine,
    element: MainSellerDashboardPage,
  },
 
  // Store Routes
  {
    name: "Seller/Store Management",
    path: "/shops",
    icon: faShop,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faShop,
        element: ShopPage,
        isParent: true,
      },
      {
        name: "Create",
        path: "/create",
        element: ShopAddPage,
      },

      {
        name: "Edit",
        path: "/:shopId/edit",
        element: ShopAddPage,
      },
      {
        name: "Details",
        path: "/:shopId/details",
        element: ShopDetailsPage,
      },
      {
        name: "Orders",
        path: "/:shopId/orders",
        element: ShopOrderPage,
      },
      {
        name: "Manual Orders",
        path: "/:shopId/manual-orders",
        element: ShopManualOrderPage,
      },
      {
        name: "Orders Details",
        path: "/:shopId/orders/:orderId/details",
        element: ShopOrderDetailsPage,
      },
      {
        name: "Out-Of-Stock",
        path: "/:shopId/out-of-stock",
        element: ShopProductOutOfStockPage,
      },
      {
        name: "Brands",
        path: "/:shopId/brands",
        element: ShopBrandsPage,
      },
      {
        name: "Bank",
        path: "/:shopId/banks",
        element: ShopBanksPage,
      },
      {
        name: "Buiness Info",
        path: "/:shopId/business-info",
        element: ShopBusinessInfoPage,
      },
      {
        name: "Buiness Info Update",
        path: "/:shopId/business-info-update",
        element: ShopBusinessInfoUpdatePage,
      },
      {
        name: "Business & Contact Address",
        path: "/:shopId/contact-address",
        element: ShopContractAddressPage,
      },
      {
        name: "Facebook Page & Group",
        path: "/:shopId/facebook-page",
        element: ShopFacebookPageGroupPage,
      },
      {
        name: "Business & Contact update",
        path: "/:shopId/contact-update",
        element: ShopContractAddressUpdatePage,
      },
      {
        name: "Shipping & Delivery Settings",
        path: "/:shopId/shop-delivery-settings",
        element: ShopDeliveryShippingPage,
      },
      {
        name: "Add Rate",
        path: "/:shopId/shipping/:zoneId/add-rate",
        element: ShopShippingAddRatePage,
      },
      {
        name: "Edit Rate",
        path: "/:shopId/shipping/:zoneId/edit-rate",
        element: ShopShippingAddRatePage,
      },
      {
        name: "shipping-profile",
        path: "/:shopId/shipping-profile",
        element: ShopShippingProfilePage,
      },
      {
        name: "Products",
        path: "/:shopId/products",
        element: ShopProductPage,
      },
      {
        name: "Affiliate Products",
        path: "/:shopId/affiliate-products",
        element: ShopAffiliateProductPage,
      },
      {
        name: "Requested Products",
        path: "/:shopId/requested-products",
        element: ShopRequestedProductPage,
      },
      {
        name: "Requested Product Add",
        path: "/:shopId/request/products/create",
        element: ShopRequestProductAddPage,
      },
      {
        name: "Request Product Details",
        path: "/:shopId/request-products/:slug/details",
        element: ShopRequestProductDetailsPage,
      },
      {
        name: "Request Product Edit",
        path: "/:shopId/request-products/:slug/edit",
        element: ShopRequestProductEditPage,
      },
      {
        name: "Product Catalogue",
        path: "/:shopId/product-catalogue",
        element: ShopProductCataloguePage,
      },
      {
        name: "Product Details",
        path: "/:shopId/products/:slug/details",
        element: ShopProductDetailsPage,
      },
      {
        name: "Product Add",
        path: "/:shopId/products/create",
        element: ShopProductAddPage,
      },
      {
        name: "Product Bulkupload",
        path: "/:shopId/products/bulkupload",
        element: ShopProductBulkUploadPage,
      },
      {
        name: "Product Bulkupdate",
        path: "/:shopId/products/bulkupdate",
        element: ShopProductBulkUpdatePage,
      },
      {
        name: "Product Edit",
        path: "/:shopId/products/:slug/edit",
        element: ShopProductEditPage,
      },
      {
        name: "Campaign",
        path: "/:shopId/campaign",
        element: ShopCampaignPage,
      },
      {
        name: "Campaign Details",
        path: "/:shopId/campaign/:campaignId/details",
        element: CampaignDetailsPage,
      },
      {
        name: "Campaign Product",
        path: "/:shopId/campaign/:campaignId/campaign-products",
        element: CampaignProductPage,
      },
      {
        name: "Campaign Product Assign",
        path: "/:shopId/campaign/:campaignId/assign-products",
        element: CampaignAssignProductPage,
      },
      {
        name: "Promo Code",
        path: "/:shopId/promo-code",
        element: ShopPromoCodePage,
      },
      {
        name: "Categories",
        path: "/:shopId/categories",
        element: ShopCategoryPage,
      },
      {
        name: "Users",
        path: "/:shopId/users",
        element: ShopUserPage,
      },
      {
        name: "Chats",
        path: "/:shopId/chats",
        element: ShopChatPage,
      },
    ],
  },

  //Seller/Store  Routes
  // {
  //   name: "Seller/Store",
  //   path: "/seller-store",
  //   icon: faShop,
  //   sub: [
  //     {
  //       name: "All",
  //       path: "/",
  //       icon: faShop,
  //       element: SellerStorePage,
  //       isParent: true,
  //       //type: ["JC_RIDER"],
  //       // userTypes: ['SUPER_MERCHANT', 'MERCHANT'],
  //     },
  //   ],
  // },

  // Products Routes
  // {
  //   name: "Business Details",
  //   path: "/business",
  //   icon: faBagShopping,
  //   sub: [
  //     {
  //       name: "Business & Contact Address",
  //       path: "/",
  //       icon: faList,
  //       element: ProductPage,
  //       // userTypes: ['MERCHANT'],
  //       isParent: true,
  //     },
  //     {
  //       name: "Business Info Page",
  //       path: "/create",
  //       element: ProductAddPage,
  //       // userTypes: ["MERCHANT"],
  //       isParent: true,
  //     },
  //     {
  //       name: "Personal Details Page",
  //       path: "/:slug/edit",
  //       element: ProductEditPage,
  //       // userTypes: ["MERCHANT"],
  //       isParent: true,
  //     },

  //     {
  //       name: "Bank Details Page",
  //       path: "/resale-products",
  //       icon: faList,
  //       element: ResaleProductPage,
  //       // userTypes: ['MERCHANT'],
  //       isParent: true,
  //     },
  //     {
  //       name: "Add New Branch",
  //       path: "/:slug/details",
  //       element: ProductDetailsPage,
  //       // userTypes: ['MERCHANT'],
  //       isParent: true,
  //     },
  //     {
  //       name: "Web & Social Media",
  //       path: "/:slug/details",
  //       element: ProductDetailsPage,
  //       // userTypes: ['MERCHANT'],
  //       isParent: true,
  //     },
  //     {
  //       name: "KAM Details",
  //       path: "/:slug/details",
  //       element: ProductDetailsPage,
  //       // userTypes: ['MERCHANT'],
  //       isParent: true,
  //     },
  //   ],
  // },

  // Orders Routes
  // {
  //   name: "Orders & Reviews",
  //   path: "/orders",
  //   icon: faCartShopping,
  //   sub: [
  //     {
  //       name: "List",
  //       path: "/",
  //       icon: faCartShopping,
  //       element: OrderPage,
  //       isParent: true,
  //     },
  //     {
  //       name: "Order by Images",
  //       path: "/order-image",
  //       icon: faCopy,
  //       element: OrderImagePage,
  //       isParent: true,
  //     },
  //     {
  //       name: "Details",
  //       path: "/:orderId/details",
  //       element: SingleOrderPage,
  //     },
  //     {
  //       name: "Manual Order",
  //       path: "/create",
  //       element: OrderCreatePage,
  //     },
  //     {
  //       name: "Extra",
  //     },
  //   ],
  // },
  // Products Routes
  // {
  //   name: "Inventory & Products",
  //   path: "/products",
  //   icon: faBagShopping,
  //   sub: [
  //     {
  //       name: "Manage Products",
  //       path: "/",
  //       icon: faList,
  //       element: ProductPage,
  //       isParent: true,
  //     },
  //     {
  //       name: "Create",
  //       path: "/create",
  //       element: ProductAddPage,
  //     },
  //     {
  //       name: "Edit",
  //       path: "/:slug/edit",
  //       element: ProductEditPage,
  //     },

  //     {
  //       name: "Resale Products",
  //       path: "/resale-products",
  //       icon: faList,
  //       element: ResaleProductPage,
  //     },
  //     {
  //       name: "Details",
  //       path: "/:slug/details",
  //       element: ProductDetailsPage,
  //     },
  //   ],
  // },

  //Create Flight & Tour Package  Routes
  //  {
  //   name: "Flight & Tour Package",
  //   path: "/flight-tour",
  //   icon: faJetFighterUp,
  //   sub: [
  //     // {
  //     //   name: "All",
  //     //   path: "/",
  //     //   icon: faJetFighterUp,
  //     //   element: FlightTourPage,
  //     //   isParent: true,
  //     // },
  //     // {
  //     //   name: "Create Flight Ticket",
  //     //   path: "/create-flight-ticket",
  //     //   icon: faCartShopping,
  //     //   element: CreateFlightPage,
  //     // },
  //     {
  //       name: "Flight Markup",
  //       path: "/flight-markup",
  //       icon: faCartShopping,
  //       isParent: true,
  //       element: FlightMarkupPage,

  //     },
  //   ],
  // },
  //Group tricket

  //Group Ticket Routes
  // {
  //   name: "Group Ticket",
  //   path: "/group-ticket",
  //   icon: faCube,
  //   sub: [
  //     {
  //       name: "All",
  //       path: "/",
  //       icon: faCube,
  //       element: GroupTicketPage,
  //       isParent: true,
  //     },
  //     {
  //       name: "Create",
  //       path: "/create",
  //       element: GroupTicketAddPage,
  //     },
  //     {
  //       name: "Search Ticket",
  //       path: "/search",
  //       element: GroupTicketSearchPage,
  //     },
  //     {
  //       name: "Group Ticket Review",
  //       path: "/select-group-tricket-review",
  //       element: SelectGroupTicketReviewPage,
  //     },
  //     {
  //       name: "Edit",
  //       path: "/:ticketId/edit",
  //       element: GroupTicketAddPage,
  //     },
  //   ],
  // },

  //Delivery & Shipping Routes
  // {
  //   name: "Delivery & Shipping",
  //   path: "/delivery-shipping",
  //   icon: faTruckFast,
  //   sub: [
  //     {
  //       name: "Shipping & Delivery Settings",
  //       path: "/settings",
  //       icon: faTruckFast,
  //       element: DeliveryShippingPage,
  //       isParent: true,
  //     },
  //     {
  //       name: "General Shipping Rates",
  //       path: "/:shopId/add-rate",
  //       element: ShippingRatesPage,
  //     },
  //     {
  //       name: "Shipping Profile",
  //       path: "/shipping-profile",
  //       element: ShippingProfilePage,
  //     },
  //     {
  //       name: "Processing Time",
  //       path: "/processing-time",
  //       element: ProcessingTimePage,
  //     },
  //     {
  //       name: "New Shipping Zone",
  //       path: "/shipping-zone",
  //       element: ShippingZonePage,
  //     },
  //     {
  //       name: "Shipping Place",
  //       path: "/shipping-place",
  //       element: ShippingPlacePage,
  //     },
  //     {
  //       name: "Add Package",
  //       path: "/add-package",
  //       element: AddPackagePage,
  //     },

  //   ],
  // },

  //Business Details
  // {
  //   name: "Business Details",
  //   path: "/business",
  //   //icon: faTruckFast,
  //   sub: [
  //     // {
  //     //   name: "Business & Contact Address",
  //     //   path: "/contact-address",
  //     //   icon: faCodeBranch,
  //     //   element: BusinessContractAddressPage,
  //     //   //isParent: true,
  //     // },
  //     {
  //       name: "Buiness Info",
  //       path: "/info",
  //       icon: faCodeBranch,
  //       element: BusinessInfoPage,
  //      // isParent: true,
  //     },
  //     {
  //       name: "Personal Details",
  //       path: "/personal-details",
  //      //icon: faCartShopping,
  //       element: MerchantProfilePage,
  //       //isParent: true,
  //     },
  //     {
  //       name: "Personal Details",
  //       path: "/personal-info",
  //       icon: faCartShopping,
  //       element: MerchantDetailsPage,
  //      // isParent: true,
  //     },
  //     {
  //       name: "Business Create",
  //       path: "/create",
  //       icon: faCodeBranch,
  //       element: BusinessCreatePage,
  //      // isParent: true,
  //     },
  //   ],
  // },

  //Merchant Profile  Routes
  // {
  //   name: "Merchant Profile",
  //   path: "/merchant-profile",
  //   icon: faShop,
  //   sub: [
  //     {
  //       name: "All",
  //       path: "/",
  //       icon: faShop,
  //       element: MerchantProfilePage,
  //       isParent: true,
  //       // userTypes: ['SUPER_MERCHANT', 'MERCHANT'],
  //     },
  //     {
  //       name: "MemberShip Create",
  //       path: "/membership-create",
  //       element: MemberShipAddPage,
  //       // userTypes: ["MERCHANT"],
  //     },
  //     {
  //       name: "MemberShip Lavel",
  //       path: "/membership-lavel",
  //       element: MemberShipLavelPage,
  //       // userTypes: ["MERCHANT"],
  //     },
  //   ],
  // },

  // Flight Search
  // {
  //   name: "Flight Search",
  //   path: "flight-search",
  //   element: FlightSearchPage,
  //   // userTypes: ['MERCHANT'],
  // },
  //search trick review
  // {
  //   name: "search Ticket Review",
  //   path: "search-tricket-review",
  //   element: SelectSearchTicketReviewPage,
  // },

  //Business & Contact Address Pending
  // {
  //   name: "Business & Contact Address",
  //   path: "/business-contact-address",
  //   icon: faCodeBranch,
  //   sub: [
  //     {
  //       name: "All",
  //       path: "/",
  //       icon: faCodeBranch,
  //       element: BusinessContractAddressPage,
  //       isParent: true,
  //       // userTypes: ['SUPER_MERCHANT', 'MERCHANT'],
  //     },
  //   ],
  // },

  //Business Info
  // {
  //   name: "Business Info",
  //   path: "/business-info",
  //   icon: faCodeBranch,
  //   sub: [
  //     {
  //       name: "All",
  //       path: "/",
  //       icon: faCodeBranch,
  //       element: BusinessInfoPage,
  //       isParent: true,
  //       // userTypes: ['SUPER_MERCHANT', 'MERCHANT'],
  //     },
  //   ],
  // },

  //Branch Management  Routes
  // {
  //   name: "Branch Management",
  //   path: "/branch-management",
  //   icon: faCodeBranch,
  //   sub: [
  //     {
  //       name: "All",
  //       path: "/",
  //       icon: faCodeBranch,
  //       element: BranchManagementPage,
  //       isParent: true,
  //       // userTypes: ['SUPER_MERCHANT', 'MERCHANT'],
  //     },
  //   ],
  // },

  // Request Products Routes
  // {
  //   name: "Request Products",
  //   path: "/request-products",
  //   icon: faCartFlatbed,
  //   sub: [
  //     {
  //       name: "All",
  //       path: "/",
  //       icon: faCartFlatbed,
  //       element: RequestedProductPage,
  //       isParent: true,
  //     },
  //     {
  //       name: "Create",
  //       path: "/create",
  //       element: AddRequestedProductPage,
  //     },
  //     {
  //       name: "Edit",
  //       path: "/:slug/edit",
  //       element: RequestProductEditPage,
  //     },
  //     {
  //       name: "Details",
  //       path: "/:slug/details",
  //       element: ReqProductDetailsPage,
  //     },
  //   ],
  // },

  // Global Products Routes
  // {
  //   name: "Product Catalogue",
  //   path: "/merchant-product-catalogue",
  //   icon: faClone,
  //   sub: [
  //     {
  //       name: "All",
  //       path: "/",
  //       icon: faClone,
  //       element: GlobalProductPage,
  //       isParent: true,
  //     },
  //     {
  //       name: "Details",
  //       path: "/:slug/details",
  //       element: GlobalProductDetailsPage,
  //     },
  //     {
  //       name: "Add",
  //       path: "/create",
  //       element: GlobalProductAddPage,
  //     },
  //     {
  //       name: "Edit",
  //       path: "/:slug/edit",
  //       element: GlobalProductEditPage,
  //     },
  //   ],
  // },

  // Promotions Routes
  // {
  //   name: "Marketing & Promotions",
  //   path: "/promotion",
  //   icon: faPercentage,
  //   // userTypes: ['MERCHANT'],
  //   sub: [
  //     {
  //       name: "Campaign",
  //       path: "/campaign",
  //       icon: faBullhorn,
  //       element: CampaignPage,
  //       //userTypes: ["MERCHANT"],
  //     },
  //     {
  //       name: "Campaign Details",
  //       path: "/campaign/:campaignId/details",
  //       element: CampaignDetailsPage,
  //       // userTypes: ['MERCHANT'],
  //     },
  //     {
  //       name: "Campaign Products",
  //       path: "/campaign/:campaignId/products",
  //       element: CampaignProductPage,
  //       // userTypes: ['MERCHANT'],
  //     },
  //     {
  //       name: "Campaign Assign Product",
  //       path: "/campaign/:campaignId/assign-products",
  //       element: CampaignAssignProductPage,
  //       // userTypes: ['MERCHANT'],
  //     },
  //     {
  //       name: "Promo Code",
  //       path: "/promo-code",
  //       icon: faTicket,
  //       element: PromoCodePage,
  //     },
  //   ], 
  // }, 
  // Chat
  // {
  //   name: "Chats",
  //   path: "/chats",
  //   icon: faComment,
  //   element: ChatPage,
  // },
  // Reports Routes
  {
    name: "Finance",
    path: "/reports",
    icon: faList,
    sub: [
      {
        name: "My Transactions",
        path: "/transactions",
        icon: faAlignLeft,
        element: MyTransactionPage,
      },
      {
        name: "Settlement Request",
        path: "/my-settlement",
        icon: faBell,
        element: MySettlementPage,
      },
      {
        name: "Delivery Report",
        path: "/delivery-report",
        icon: faAlignCenter,
        element: DeliveryReportPage,
      },
      {
        name: "Sales Report",
        path: "/sales-report",
        icon: faAlignCenter,
        element: SalesReportPage,
      },
      {
        name: "Revenue Report",
        path: "/revenue-report",
        icon: faAlignCenter,
        element: RevenueReportPage,
      },
    ],
  },
  // Account & Settings
  {
    name: "Account & Settings",
    path: "/account",
    icon: faCog,
    sub: [
      {
        name: "Personal Details",
        path: "/my-profile",
        icon: faUserCog,
        element: MerchantDetailsPage,
        isParent: true,
      },
      {
        name: "Profile Update",
        path: "/my-profile-update",
        element: MerchantProfileUpdatePage,
      },
      {
        name: "Bank Information",
        path: "/bank-info",
        element: BankDetailsPage,
      },
      {
        name: "Warehouse Info",
        path: "/warehouse-info",
        element: WarehouseInfoPage,
      },
      {
        name: "Key Account Manager",
        path: "/key-account-manager",
        element: KeyAccountPage,
      },
      {
        name: "Referrals",
        path: "/referrals",
        icon: faCube,
        element: ReferralsPage,
      },
      {
        name: "Details",
        path: "/:brandId/details",
        element: BrandDetailsPage,
      },
    ],
  },



  //Administration Routes
  {
    name: "Administration",
    path: "/administration",
    icon: faUserShield,
    sub: [
      {
        name: "User Management",
        icon: faUser,
        path: "/admins",
        element: UserManagementPage,
      },
      {
        name: "User Groups",
        icon: faUsers,
        path: "/groups",
        element: ServiceGroupPage,
      },
      {
        name: "User Services",
        icon: faList,
        path: "/services",
        element: UserServicePage,
      },
    ],
  },

  //Users Routes
  // {
  //   name: "Customer",
  //   path: "/customer",
  //   icon: faUsers,
  //   element: UserPage,
  //   sub: [
  //     {
  //       name: "All",
  //       path: "/",
  //       icon: faUsers,
  //       element: UserPage,
  //       isParent: true,
  //     },
  //     {
  //       name: "Details",
  //       path: "/:userId/details",
  //       element: UserDetailsPage,
  //     },
  //   ],
  // },

  // {
  //   name: "File Uploader",
  //   path: "/file-uploader",
  //   icon: faUpload,
  //   element: FileUploaderPage,
  // },
];

export const getAllServicesList = () => {
  return adminServices?.flatMap((service) => {
    if (service.sub && service.sub.length) {
      return service.sub.map((sub) => `${service.name} ${sub.name}`);
    } else {
      return service.name;
    }
  });
};

export const getServicePaths = (path: string | undefined) => {
  if (path) {
    const parentService = adminServices?.find(
      (service) => service.path === path
    );
    if (parentService) {
      return parentService?.path || "";
    } else {
      const parentService = adminServices?.find((service) =>
        service.sub?.find((sub) => `${service.path}${sub.path}` === path)
      );
      return parentService?.path || "";
    }
  }
  return "";
};
