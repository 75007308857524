import * as React from "react";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Divider, Form, Input, Radio, Select, Switch, Tooltip } from "antd";
import axios from "axios";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";
import { jcBankTypeArray } from "../../utils";
import { QuestionCircleOutlined } from "@ant-design/icons";

export default function PersonalDetails() {
  const { token, shopInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const navigate = useNavigate();
  const [error, setError] = useState("");
  
  const [merchantShopCheck, setMerchantShopCheck] = useState<any>();
  const shopId = merchantShopCheck?.[0].id || 0;
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [bankType, setBankType] = useState<any>("BANKING");
  const [isDefault, setIsDefault] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string>();
  const [shopData, setShopData] = useState<any>(undefined);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [form] = Form.useForm();
  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      bankingType: bankType,
      accountName: `${data.accountName}`,
      accountNumber: `${data.accountNumber}`,
      bankName: `${data.name}`,
      branchName: `${data.branchName}`,
      routingNumber: `${data.routingNumber}`,
      isDefault: isDefault,
      shopId: shopId,
      uploadBankChequeCopy: generatedImgUrl ? generatedImgUrl : "",
    };

    if (shopData) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/shop-bank/update-bank`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: shopData?.id,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Updated Successfully", "success");
            form.resetFields();
            navigate("/merchant-profile");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/shop-bank/add-bank`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Create Successfully", "success");
            form.resetFields();
            // window.location.reload();
            navigate("/merchant-profile");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const getMerchantShopCheck = useCallback(async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/merchant/all-shops-by-merchant`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMerchantShopCheck(res?.data?.shops);
  }, []);

  useEffect(() => {
    getMerchantShopCheck();
  }, [
    getMerchantShopCheck,
  ]);
  return (
    <div className="flex overflow-hidden flex-col bg-stone-50 pb-[740px] max-md:pb-24">
      <main className="flex flex-col self-center mt-5 max-w-full w-[1170px]">
        <div className="flex gap-2 self-start text-base font-bold text-zinc-800">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d7fe58accc7d9847083a3f1a5fef304a601ebb28b9ffa035b153f38c0dce7be1?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
            alt=""
            className="object-contain shrink-0 w-6 aspect-square"
          />
          <h1>Add Bank Account</h1>
        </div>

        <Form
          name="control-hooks"
          onFinish={onSubmit}
          form={form}
          layout="vertical"
          className="flex flex-col items-start pt-8 pr-20 pb-12 pl-8 mt-2.5 w-full bg-white rounded-md shadow-sm max-md:px-5 max-md:max-w-full"
          initialValues={{
            ...shopData,
            accountName: shopData?.accountName || "",
            bankName: shopData?.name || "",
          }}
        >
          <div className="flex justify-between border-b pb-3 mb-8 border-gray-100">
  
            <Radio.Group
              onChange={(e) => setBankType(e.target.value)}
              defaultValue={bankType}
            >
              <Radio value={"BANKING"} checked>
                BANKING
              </Radio>
              <Radio value="MOBILE_BANKING">MOBILE BANKING</Radio>
            </Radio.Group>
          
          </div>
          <div className="grid grid-cols-2 gap-4">
            
            <Form.Item
              name="accountName"
              hasFeedback
              label="Account Name"
              rules={[
                {
                  required: true,
                  message: "Account Name is Required!",
                },
              ]}
            >
              <Input
                type="text"
                id="accountName"
                className="input-box"
                placeholder="Enter account name"
              />
            </Form.Item>

            <Form.Item
              name="accountNumber"
              hasFeedback
              label="Account Number"
              rules={[
                {
                  required: true,
                  message: "Account Number is Required!",
                },
              ]}
            >
              <Input
                id="accountNumber"
                type="text"
                className="input-box"
                placeholder="Enter account number"
              />
            </Form.Item>

            <Form.Item
              name="name"
              hasFeedback
              label="Bank Name"
              rules={[
                {
                  required: true,
                  message: "Bank Name is Required!",
                },
              ]}
            >
              <Input
                id="name"
                type="text"
                className="input-box"
                placeholder="Enter bank name"
              />
            </Form.Item>

            {bankType === "BANKING" && (
              <>
                <Form.Item
                  name="branchName"
                  hasFeedback
                  label="Branch Name"
                  rules={[
                    {
                      required: true,
                      message: "Branch Name is Required!",
                    },
                  ]}
                >
                  <Input
                    id="branchName"
                    type="text"
                    className="input-box"
                    placeholder="Enter branch name"
                  />
                </Form.Item>

                <Form.Item
                  name="routingNumber"
                  hasFeedback
                  label="Routing Number"
                  rules={[
                    {
                      required: true,
                      message: "Routing Number is Required!",
                    },
                  ]}
                >
                  <Input
                    id="routingNumber"
                    type="text"
                    className="input-box"
                    placeholder="Enter routing number"
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              hasFeedback
              label="IS Defaul?"
              name="isDefault"
              style={{ minWidth: "100%" }}
            >
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked={shopData?.isDefault}
                onChange={setIsDefault}
              />
            </Form.Item>

            <div className="mr-4">
              <Divider orientation="left">
                {bankType === "BANKING" && <>Upload Bank Cheque Copy</>}
                {bankType === "MOBILE_BANKING" && <>Logo</>}

                <Tooltip
                  placement="bottom"
                  title={
                    "Upload Bank Cheque Copy should be square. We prefer height 200px and width 200px (Ratio 1)"
                  }
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>
              <ImageUploader
                imgUrl={generatedImgUrl || ""}
                startUpload={startUpload}
                setStartUpload={(val: any) => {
                  setStartUpload(val);
                }}
                setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
              />
            </div>
          </div>

          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
            }}
            onClickReset={() => {
              form?.resetFields();
              setStartUpload(undefined);
            }}
            disabled={loading || !!error}
            loading={loading}
          />
        </Form>
      </main>
    </div>
  );
}
