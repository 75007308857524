import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopFacebookPageList from "../../../components/Shop/Facebook/index";

const ShopFacebookPage = () => {
  return (
    <MainLayout>
      <ShopFacebookPageList />
    </MainLayout>
  );
};

export default ShopFacebookPage;
