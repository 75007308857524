import { Disclosure, Menu, Transition } from "@headlessui/react";
import { logout } from "../../../utils/auth";
import { Avatar, Form, Input, Layout, Modal } from "antd";
import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT } from "../../../redux/auth/authType";
import { responseNotification } from "../../../utils/notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Client } from "@stomp/stompjs";
import tabStyle from './SearchBox.module.css'
import {
  faBars,
  faBusSimple,
  faCar,
  faClose,
  faHotel,
  faJetFighter,
  faJetFighterUp,
  faKey,
  faPowerOff,
  faTrain,
} from "@fortawesome/free-solid-svg-icons";
import noImage from "../../../images/noImage.png";
import { BellOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import ringer from "./flipdish-ringer.mp3";

const { Header } = Layout;

const MainHeader = (): ReactElement => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { merchantInfo, token, shopInfo, shopId } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [orderId, setOrderId] = useState();
  const [merchantServiceId, setMerchantServiceId] = useState<any>("flight");
  const audio = new Audio(ringer);
  audio.loop = false;

  const Logout = () => {
    navigate("/");
    logout("/");
    dispatch({
      type: LOGOUT,
      payload: {
        token: null,
      },
    });
  };

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      mobileNumber: data?.mobileNumber,
      oldPassword: data?.oldPassword,
      newPassword: data?.newPassword,
    };

    await fetch(`${process.env.REACT_APP_AUTH_API}/merchant/update-password`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          form.resetFields();
          setVisible(false);
          responseNotification("Password Updated Successfully", "success");
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const onClose = () => {
    setVisible(false);
  };

  // SocketJs Connection
  // Object.assign(global, { WebSocket });

  const stompConnect = () => {
    const client = new Client({
      brokerURL: `${process.env.REACT_APP_SOCKET_API}/merchant`,
      debug: function (str) {
        console.log(str);
      },
      reconnectDelay: 20000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
    });

    client.onConnect = function (frame) {
      // Do something, all subscribes must be done is this callback
      // This is needed because this will be executed after a (re)connect
   
      client.subscribe("/user/queue/merchant", (message) => {
        if (JSON.parse(message.body).orderId) {
          setOrderId(JSON.parse(message.body).orderId);
          Cookies.set("newOrders", JSON.parse(message.body).orderId);
          audio.play();
        }
      });

      client.publish({
        destination: "/app/merchant/status",
        body: JSON.stringify({
          status: shopInfo?.status,
          token: token,
        }),
      });
    };

    if (token) {
      client.activate();
    } else {
      client.deactivate();
    }
  };

  useEffect(() => {
    if (shopId) {
      stompConnect();
    }
  }, [shopId]);

  return (
    <header className="flex relative flex-col items-start px-20 pt-3.5 pb-6 w-full text-xs font-medium min-h-[131px] text-neutral-600 max-md:px-5 max-md:max-w-full">
    <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1dcef3d23945d0661147d93b7acdb2de66d0ea0905ad855ec8900322d7a9fd6a?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
      alt=""
      className="object-cover absolute inset-0 size-full"
    />
    <div className="flex relative flex-col w-full max-w-[1444px] max-md:max-w-full">
      <div className="flex flex-wrap gap-5 justify-between self-end max-w-full w-[1167px]">
        <div className="flex gap-2.5 whitespace-nowrap">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/eab2c6aa7c908d10eda6fbc4d8b8b04bc7efe5cca2684c5c672f3b702bd7e13d?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
            alt=""
            className="object-contain shrink-0 self-start aspect-square w-[18px]"
          />
          <div>09638111600</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ec73317e475492b752dadd20164fc91464d0eeed6b6dc5ca1eb87e7f71f8f5d1?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
            alt=""
            className="object-contain shrink-0 self-start aspect-square w-[18px]"
          />
          <div className="basis-auto">support@jachai.com</div>
        </div>
        <nav className="flex gap-5">
          {/* <button className="flex gap-1.5 text-sm whitespace-nowrap items-center">
            <span className="grow">Help</span>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/45bf4e2d9dd526a9142c28713933c329efe5ee2392dafb292e5582511fe125f5?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
              alt=""
              className="object-contain shrink-0 w-5 aspect-square"
            />
          </button> */}
          <button className="flex gap-2 self-start whitespace-nowrap items-center">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c82002e5bc31e02c999e6931cc81a178d72a0bebacae5e2af7ff26bc665653bf?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
              alt=""
              className="object-contain shrink-0 aspect-square w-[18px]"
            />
            <span>App!</span>
          </button>
          {/* <button className="my-auto text-zinc-700">Wishlist</button> */}
          <button className="flex gap-2.5 self-start text-zinc-700 items-center pb-6">
          <div className="hidden md:block">
                    <div className="ml-2 flex items-center">
                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="max-w-xs bg-gray-200 rounded-full flex items-center text-sm focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-400 focus:ring-white">
                            <span className="sr-only">Open user menu</span>

                            <span className="px-3 font-medium text-sm">
                              {shopInfo?.name ||
                                merchantInfo?.merchantPersonalDetail?.name ||
                                merchantInfo?.mobileNumber?.split("+88")}
                            </span>

                            {merchantInfo?.profilePicture ? (
                              <img
                                className="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                                src={
                                  merchantInfo?.storeDetails?.storeLogo ||
                                  merchantInfo?.merchantPersonalDetail
                                    ?.profilePicture ||
                                  shopInfo?.logo ||
                                  noImage
                                }
                                alt=""
                              />
                            ) : (
                              <svg
                                className="mx-auto w-9 h-9 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                            )}
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                            {!!merchantInfo?.profilePicture && (
                              <div className="px-4 text-center pb-2 border-b z-50">
                                <Avatar
                                  size={120}
                                  src={
                                    merchantInfo?.storeDetails?.storeLogo ||
                                    merchantInfo?.merchantPersonalDetail
                                      ?.profilePicture ||
                                    shopInfo?.logo ||
                                    noImage
                                  }
                                  alt=""
                                  className="border mb-3"
                                />
                                <p className="font-medium text-sm">
                                  {merchantInfo?.fullName || shopInfo?.name}
                                </p>
                                <p className="font-light text-sm">
                                  {merchantInfo?.email}
                                </p>
                              </div>
                            )}
                            
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="#"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "flex items-center px-4 py-2 text-sm text-gray-500 hover:text-gray-600 font-medium "
                                  )}
                                  onClick={() => setVisible(true)}
                                >
                                  <FontAwesomeIcon icon={faKey} />
                                  <span className="ml-2">Change Password</span>
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="#"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "flex items-center px-4 py-2 text-sm text-gray-500 hover:text-red-500 font-bold "
                                  )}
                                  onClick={Logout}
                                >
                                  <FontAwesomeIcon icon={faPowerOff} />
                                  <span className="ml-2">Logout</span>
                                </Link>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
            {/* <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/73a4d2e53bc94a45caebfc2467467ca5e1669ec9e990e28062f1d766b27f2e42?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
              alt=""
              className="object-contain shrink-0 aspect-square w-[18px]"
            />
            <span>Sign up</span> */}
          </button>
        </nav>
      </div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/ea4c26a74b02db0dd27625d95e75148c411cd8cfc3189e65ed44d128d9fe8073?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
        alt="Company Logo"
        className="object-contain mt-8 max-w-full aspect-[3.1] w-[130px]"
      />
    </div>
  </header>
    // <Header
    //   className="site-layout-background bg-red-200 sticky top-0 z-10"
    //   style={{
    //     padding: 0,
    //   }}
    // >
    //   <div className="min-h-full">
    //     <Disclosure as="nav" className="bg-white shadow-sm">
         
    //       {({ open }) => (
    //         <>
           
    //           <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
    //             <div className="flex items-center justify-end h-16">
    //               <div className="flex items-center">
    //                 <div className="-rotate-45">
    //                   <span className="text-slate-200">
    //                     {initialState?.type?.replace("JC_", "")}
    //                   </span>
    //                 </div>
    //               </div>
                

    //               <div className="hidden md:block">
    //                 <div className="ml-2 flex items-center">
    //                   {/* Profile dropdown */}
    //                   <Menu as="div" className="ml-3 relative">
    //                     <div>
    //                       <Menu.Button className="max-w-xs bg-gray-200 rounded-full flex items-center text-sm focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-400 focus:ring-white">
    //                         <span className="sr-only">Open user menu</span>

    //                         <span className="px-3 font-medium text-sm">
    //                           {shopInfo?.name ||
    //                             merchantInfo?.merchantPersonalDetail?.name ||
    //                             merchantInfo?.mobileNumber?.split("+88")}
    //                         </span>

    //                         {merchantInfo?.profilePicture ? (
    //                           <img
    //                             className="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
    //                             src={
    //                               merchantInfo?.storeDetails?.storeLogo ||
    //                               merchantInfo?.merchantPersonalDetail
    //                                 ?.profilePicture ||
    //                               shopInfo?.logo ||
    //                               noImage
    //                             }
    //                             alt=""
    //                           />
    //                         ) : (
    //                           <svg
    //                             className="mx-auto w-9 h-9 text-gray-400"
    //                             fill="currentColor"
    //                             viewBox="0 0 20 20"
    //                             xmlns="http://www.w3.org/2000/svg"
    //                           >
    //                             <path
    //                               fillRule="evenodd"
    //                               d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
    //                               clipRule="evenodd"
    //                             ></path>
    //                           </svg>
    //                         )}
    //                       </Menu.Button>
    //                     </div>
    //                     <Transition
    //                       as={Fragment}
    //                       enter="transition ease-out duration-100"
    //                       enterFrom="transform opacity-0 scale-95"
    //                       enterTo="transform opacity-100 scale-100"
    //                       leave="transition ease-in duration-75"
    //                       leaveFrom="transform opacity-100 scale-100"
    //                       leaveTo="transform opacity-0 scale-95"
    //                     >
    //                       <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
    //                         {!!merchantInfo?.profilePicture && (
    //                           <div className="px-4 text-center pb-2 border-b z-50">
    //                             <Avatar
    //                               size={120}
    //                               src={
    //                                 merchantInfo?.storeDetails?.storeLogo ||
    //                                 merchantInfo?.merchantPersonalDetail
    //                                   ?.profilePicture ||
    //                                 shopInfo?.logo ||
    //                                 noImage
    //                               }
    //                               alt=""
    //                               className="border mb-3"
    //                             />
    //                             <p className="font-medium text-sm">
    //                               {merchantInfo?.fullName || shopInfo?.name}
    //                             </p>
    //                             <p className="font-light text-sm">
    //                               {merchantInfo?.email}
    //                             </p>
    //                           </div>
    //                         )}
    //                         <Menu.Item>
    //                           {({ active }) => (
    //                             <Link
    //                               to="#"
    //                               className={classNames(
    //                                 active ? "bg-gray-100" : "",
    //                                 "flex items-center px-4 py-2 text-sm text-gray-500 hover:text-red-500 font-bold "
    //                               )}
    //                               onClick={Logout}
    //                             >
    //                               <FontAwesomeIcon icon={faPowerOff} />
    //                               <span className="ml-2">Logout</span>
    //                             </Link>
    //                           )}
    //                         </Menu.Item>
    //                       </Menu.Items>
    //                     </Transition>
    //                   </Menu>
    //                 </div>
    //               </div>
    //               <div className="-mr-2 flex md:hidden">
    //                 {/* Mobile menu button */}
    //                 <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
    //                   <span className="sr-only">Open main menu</span>
    //                   {open ? (
    //                     <FontAwesomeIcon icon={faClose} />
    //                   ) : (
    //                     <FontAwesomeIcon icon={faBars} />
    //                   )}
    //                 </Disclosure.Button>
    //               </div>
    //             </div>
    //           </div>

    //           <Disclosure.Panel className="md:hidden">
    //             <div className="pt-4 pb-0 border-t shadow-md border-gray-700 z-50 absolute right-2 bg-white">
    //               <div className="px-4 text-center pb-2 border-b">
    //                 <Avatar
    //                   size={120}
    //                   src={merchantInfo?.profilePicture || shopInfo?.logo}
    //                   alt=""
    //                   className="border mb-3 shadow-md"
    //                 />
    //                 <p className="font-medium text-sm">{merchantInfo?.name}</p>
    //                 <p className="font-light text-sm">{merchantInfo?.email}</p>
    //               </div>
    //               <div className="p-2 space-y-1">
    //                 <Disclosure.Button
    //                   as="a"
    //                   href="#"
    //                   className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
    //                   onClick={() => setVisible(true)}
    //                 >
    //                   <FontAwesomeIcon icon={faKey} />
    //                   <span className="ml-2">Change Password</span>
    //                 </Disclosure.Button>
    //                 <Disclosure.Button
    //                   as="a"
    //                   href="#"
    //                   className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-red-700"
    //                   onClick={Logout}
    //                 >
    //                   <FontAwesomeIcon icon={faPowerOff} />
    //                   <span className="ml-2">Logout</span>
    //                 </Disclosure.Button>
    //               </div>
    //             </div>
    //           </Disclosure.Panel>
    //         </>
    //       )}
    //     </Disclosure>
      

   
    //   </div>
    // </Header>
  );
};

export default MainHeader;
