import { Layout } from "antd";
import React, { ReactElement } from "react";
const { Footer } = Layout;

const HomeFooter = (): ReactElement => {
  var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/66a60bcbbecc2fed692c1729/1ihfm46j9';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();

  return <Footer className="text-center">Powered by Zachai.com Ltd.</Footer>;
};



export default HomeFooter;
