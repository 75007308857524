import React, { useCallback, useEffect, useState } from "react";
import { DatePicker, Form, Input, Select } from "antd";
import { responseNotification } from "../../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../../common";
import { useSelector } from "react-redux";
import axios from "axios";
import MerchantLayout from "../Layout";
import { useNavigate } from "react-router";

const PersonalDetailsAdd = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [identificationUrl, setIdentificationUrl] = useState<string>();
  const navigate = useNavigate();
  const [singleMerchantInfo, setSingleMerchantInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const [frontSidePicture, setFrontSidePicture] = useState<string>();
  const [backSidePicture, setBackSidePicture] = useState<string>();
  const [profilePicture, setProfilePicture] = useState<string>();
  
  const [startAt, setStartAt] = useState<any>();
  const [uploadFrontSidePicture, setUploadFrontSidePicture] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [uploadBackSidePicture, setUploadBackSidePicture] = useState<
  "Initiated" | "Uploading" | "Uploaded"
>();
  const [uploadProfilePicture, setUploadProfilePicture] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState("NID");
  const [form] = Form.useForm();

  const fetchMerchantDetails = useCallback(() => {
    try {
      setSingleMerchantInfo({ loading: true, data: null });
      axios
        .get(`${process.env.REACT_APP_RIDER_API}/details`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((data) => {
          if (data.status === 200) {
            setSingleMerchantInfo({
              loading: false,
              data: data?.data?.merchant,
            });
            setFrontSidePicture(
              data?.data?.merchant?.merchantPersonalDetail?.identificationDocumentUrl
            );
            setBackSidePicture(
              data?.data?.merchant?.merchantPersonalDetail?.identificationDocumentUrl2
            );
            setProfilePicture(
              data?.data?.merchant?.merchantPersonalDetail?.profilePicture
            );
          
          } else {
            setSingleMerchantInfo({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setSingleMerchantInfo({ loading: false, data: null });
        });
    } catch (error) {
      setSingleMerchantInfo({ loading: false, data: null });
    }
  }, [token]);

  useEffect(() => {
    fetchMerchantDetails();
  }, [fetchMerchantDetails, token]);
  useEffect(() => {
    if (singleMerchantInfo.data) {
      form.resetFields(Object.keys(singleMerchantInfo.data as any));
      setIdentificationUrl(
        singleMerchantInfo?.data?.merchantBusinessInfo?.attachTinDocuments
      );
    }
  }, [form, singleMerchantInfo.data]);

  function handleChange(value) {
    setDocumentType(value);
  }
  useEffect(() => {
    if (uploadFrontSidePicture === "Uploaded" &&uploadBackSidePicture === "Uploaded" && uploadProfilePicture === "Uploaded") {
      form?.submit();
    }
  }, [form,uploadFrontSidePicture,uploadBackSidePicture, uploadProfilePicture]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      name: data.name,
      dateOfBirth: data.dateOfBirth,
      email: data.email,
      gender: data.gender,
      identificationDocumentNumber: data.nidNumber,
      identificationDocumentType: documentType,
      identificationDocumentUrl: frontSidePicture || "",
      identificationDocumentUrl2: backSidePicture || "",
      profilePicture: profilePicture || "",
    };

    await fetch(`${process.env.REACT_APP_RIDER_API}/merchant/personal-info`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification(data?.message, "success");
          form.resetFields();
          //window.location.reload();
          navigate(-1);
          // navigate("/account/my-profile");
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const resetData = () => {
    form?.resetFields();
  };

  useEffect(() => {
    if (singleMerchantInfo?.data) {
      form.resetFields();
      setFrontSidePicture(
        singleMerchantInfo?.data?.merchantPersonalDetail?.identificationDocumentUrl
      );
      setBackSidePicture(
        singleMerchantInfo?.data?.merchantPersonalDetail?.identificationDocumentUrl2
      );
      setProfilePicture(
        singleMerchantInfo?.data?.merchantPersonalDetail?.profilePicture
      );
    }
  }, [form, singleMerchantInfo?.data]);

  return (
    <MerchantLayout
      merchantId={singleMerchantInfo?.data?.id}
      title={`Merchant Profile Update`}
      subTitle={`Merchant Profile Update`}
    >
      <div className="content-body rounded-2xl bg-white p-4 min-h-full">
        <Form
          name="control-hooks"
          onFinish={onSubmit}
          form={form}
          layout="vertical"
          initialValues={{
            ...singleMerchantInfo?.data?.merchantPersonalDetail,
            name: singleMerchantInfo?.data?.merchantPersonalDetail?.name || "",
            email:
              singleMerchantInfo?.data?.merchantPersonalDetail?.email || "",
          }}
        >
          <div className="grid grid-cols-2 gap-4">
            <Form.Item
              name="name"
              hasFeedback
              label="Owner Name"
              rules={[
                {
                  required: true,
                  message: "Name is Required!",
                },
              ]}
            >
              <Input type="text" id="name" placeholder="Enter name" />
            </Form.Item>

            <Form.Item
              name="email"
              hasFeedback
              label="Email Address"
              rules={[
                {
                  required: true,
                  message: "Email is Required!",
                },
              ]}
            >
              <Input type="text" id="email" placeholder="Enter email" />
            </Form.Item>

            <Form.Item
              name="gender"
              hasFeedback
              label="Select Gender"
              rules={[
                {
                  required: true,
                  message: "Gender is Required!",
                },
              ]}
            >
              <Select
                placeholder="Select Gender"
                optionFilterProp="children"
                options={[
                  { label: "MALE", value: "MALE" },
                  { label: "FEMALE", value: "FEMALE" },
                ]}
              ></Select>
            </Form.Item>

            {/* <Form.Item
              hasFeedback
              label="Date of Birth"
              name="dateOfBirth"
              rules={[
                {
                  required: true,
                  message: "Date of birth is Required!",
                },
              ]}
            >
              <DatePicker
                id="dateOfBirth"
                placeholder="Enter Date of Birth"
                className="w-full"
              />
            </Form.Item> */}

            <Form.Item
              name="identificationDocumentType"
              hasFeedback
              label="Document Type"
              rules={[
                {
                  required: true,
                  message: "Document Type is Required!",
                },
              ]}
            >
              <Select
                defaultValue="Select"
                onChange={(val: any) => setDocumentType(val)}
                options={[
                  {
                    label: "NID",
                    value: "NID",
                  },
                  {
                    label: "PASSPORT",
                    value: "PASSPORT",
                  },
                  {
                    label: "BIRTH CERTIFICATE",
                    value: "BIRTH_CERTIFICATE",
                  },
                ]}
              ></Select>
            </Form.Item>
            <Form.Item
              hasFeedback
              label={`${documentType.replace("_", " ")} Number`}
              name="nidNumber"
            >
              <Input
                id="nidNumber"
                type="number"
                min={0}
                placeholder="Enter Number"
              />
            </Form.Item>
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div className="basis-0">
              <span className="text-md">Front Image</span>
              <ImageUploader
                imgUrl={frontSidePicture || ""}
                startUpload={uploadFrontSidePicture}
                setStartUpload={(val: any) => setUploadFrontSidePicture(val)}
                setGeneratedImgUrl={(url: any) => setFrontSidePicture(url)}
              />
            </div>
            <div className="basis-0 pr-2">
              <span className="text-md">Back Image</span>
              <ImageUploader
                imgUrl={backSidePicture || ""}
                startUpload={uploadBackSidePicture}
                setStartUpload={(val: any) => setUploadBackSidePicture(val)}
                setGeneratedImgUrl={(url: any) => setBackSidePicture(url)}
              />
            </div>

            <div className="basis-0">
              <span className="text-md">Profile Picture</span>
              <ImageUploader
                imgUrl={profilePicture || ""}
                startUpload={uploadProfilePicture}
                setStartUpload={(val: any) => setUploadProfilePicture(val)}
                setGeneratedImgUrl={(url: any) => setProfilePicture(url)}
              />
            </div>
          </div>

          <div className="mt-2">
            <SubmitResetBtn
              onClickSubmit={(e) => {
                e.preventDefault();
                setUploadFrontSidePicture("Uploading");
                setUploadBackSidePicture("Uploading");
                setUploadProfilePicture("Uploading");
              }}
              onClickReset={resetData}
              disabled={
                loading ||
                uploadFrontSidePicture === "Uploading" ||
                uploadBackSidePicture === "Uploading"
              }
              loading={
                loading ||
                uploadFrontSidePicture === "Uploading" ||
                uploadBackSidePicture === "Uploading"
              }
            />
          </div>
        </Form>
      </div>
    </MerchantLayout>
  );
};

export default PersonalDetailsAdd;
