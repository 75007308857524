import * as React from 'react';
import { UserCard } from './common/UserCard';
import { BenefitsList } from './common/BenefitsList';
import { FAQSection } from './common/FAQSection';
import { contactInfo, userInfo, benefits, faqs } from './MembershipLavelData';

export const MembershipLavelPage: React.FC = () => {
  return (
    <div className="flex overflow-hidden flex-col items-center bg-stone-50 pb-[1033px] max-md:pb-24">
    
    <UserCard userInfo={userInfo} />
      <div className="flex flex-wrap gap-5 justify-between py-2.5 pr-5 pl-20 mt-8 max-w-full font-bold bg-orange-100 rounded-xl shadow-sm w-[970px] max-md:pl-5">
        <div className="flex gap-5 text-zinc-800">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e11d4dc72243d4ed334d887976b143366fad56aa892f71558b2f2de3a0359604?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
            alt="User level icon"
            className="object-contain shrink-0 w-20 rounded-none aspect-square"
          />
          <div className="flex flex-col self-start">
            <div className="self-start text-sm font-medium">
              You are a Jachai
            </div>
            <div className="mt-1 text-base max-md:mr-1.5">BRONZE USER</div>
            <div className="flex gap-0.5 mt-1 text-xs">
              <div className="grow">POINTS HISTORY</div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2bdb458c944a42109462caad7fe934c95f5d55dd0ddc1f1936cd3e0837d6ff41?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
                alt=""
                className="object-contain shrink-0 w-4 aspect-square"
              />
            </div>
          </div>
        </div>
        <button className="self-start px-5 py-1.5 mt-1.5 text-sm text-center text-cyan-700 whitespace-nowrap bg-white rounded-md max-md:pl-5">
          Update
        </button>
      </div>
      <div className="flex flex-col py-7 pr-1.5 mt-6 max-w-full text-sm font-medium text-black bg-white rounded-xl shadow-sm w-[970px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7b14e45321692cbbca11df121daabc42ef23da371deeea926a4b32c735b141c5?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
          alt="Points information"
          className="object-contain w-full rounded-none aspect-[5.59] max-md:max-w-full"
        />
        <div className="self-start mt-3 ml-6 max-md:ml-2.5">
          Redeeming points won't affect your progress to the next level
        </div>
      </div>
      <BenefitsList benefits={benefits} />
      <FAQSection faqs={faqs} />
    </div>
  );
};

export default MembershipLavelPage;