import React from "react";
import MerchantLayout from "../../components/MerchantLayouts";
import BusinessCreate from "../../components/Business/BusinessCreate";

const BusinessCratePage = () => {
  return (
    <MerchantLayout>
      <BusinessCreate />
    </MerchantLayout>
  ); 
};

export default BusinessCratePage;
