import {
  BarcodeOutlined,
  CloseOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Button,
  Steps,
  Select,
  Empty,
  Avatar,
  Spin,
} from "antd";
import axios from "axios";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { responseNotification } from "../../../utils/notify";
import BreadCrumb from "../../Layouts/Breadcrumb";
import OrderProductList from "./products";
import styles from "../../../styles/tailwind/List.module.css";
import { Loader } from "../../common";
import ShopLayout from "../Layout";
const { Step } = Steps;

const ManualOrderCreate = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const route = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = React.useState(0);
  const [countryId, setCountryId] = useState<any>(0);
  const [stateId, setStateId] = useState<any>(0);
  const [cityId, setCityId] = useState<any>(0);
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [firstStepData, setFirstStepData] = useState<any>();
  const [userHub, setUserHub] = useState<any>();
  const [hubName, setHubName] = useState<any>();
  const [mobileNumber, setMobileNumber] = useState("");

  const [areaOptions, setAreaOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [stateOptions, setStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [cityOptions, setCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [usersOptions, setUsersOptions] = useState<any>({
    loading: false,
    list: null,
  });

  const [locationOptions, setLocationOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [currentLocation, setCurrentLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const getCountryOptions = useCallback(async (key?: string) => {
    setCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
  }, []);

  const getStateOptions = useCallback(
    async (key?: string) => {
      setStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${countryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [countryId]
  );

  const getCityOptions = useCallback(
    async (key?: string) => {
      setCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${stateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [stateId]
  );

  const onSubmitFirst = (data: any) => {
    setFirstStepData(data);
    if (type === "JC_MART" && userHub) {
      if (userHub) {
        next();
      } else {
        responseNotification(
          "No hub found in this area. Search again...",
          "error"
        );
      }
    } else {
      next();
    }
  };

  useEffect(() => {
    if (type === "JC_MART") {
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      fetch(
        `${encodedUri}/hub/nearest?type=${
          firstStepData?.type || type
        }&latitude=${currentLocation.lat}&longitude=${currentLocation.lng}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setUserHub(res?.hub?.id);
          setHubName(res?.hub?.name);
        })
        .catch((e) => {
          console.error("Error: Fetch Hub - ", e);
        });
    }
  }, [currentLocation.lat, currentLocation.lng, type]);

  //
  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      products: selectedProducts?.map((product: any) => ({
        productId: product.id,
        quantity: product.quantity * 1 || 1,
        variationId: product?.variation?.variationId,
      })),
      customerMobileNumber: `+88${firstStepData.customerMobileNumber?.replace(
        "+88",
        ""
      )}`,
      orderNote: firstStepData.orderNote,
      countryId: countryId,
      stateId: stateId,
      cityId: cityId,
      shippingAddress: firstStepData.shippingAddress,
      shippingLocation: {
        latitude: currentLocation.lat,
        longitude: currentLocation.lng,
      },
      type: type,
    };

    await fetch(`${process.env.REACT_APP_ORDER_API}/reseller/order/new-order`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...readyData,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Order Places Successfully", "success");
          form.resetFields();
          navigate(-1);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const getUsersOptions = useCallback(async (mobileNumber: string) => {
    setUsersOptions({ loading: true, list: null });
    const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
    axios
      .get(
        `${encodedUri}/admin/user?` +
          `page=${0}` +
          `&limit=${20}` +
          (mobileNumber
            ? `&mobileNumber=%2B88${mobileNumber?.replace("+88", "")}`
            : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        setUsersOptions({
          loading: false,
          list: res.data?.users?.map((user: any) => ({
            label: user.fullName,
            value: user.mobileNumber.replace("+88", ""),
          })),
        });
      })

      .catch((err) => {
        setUsersOptions({ loading: false, list: [] });
        console.error("Users: Error", err);
      });
  }, []);

  const getLocationOptions = useCallback(async (key?: string) => {
    setLocationOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_MAP_API}`;
    const res = await axios.get(
      `${encodedUri}/autocomplete` + (key ? `?key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setLocationOptions({
      loading: false,
      list: res?.data?.places,
    });
  }, []);

  const getArea = useCallback(async (key?: string) => {
    setAreaOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/area` + (key ? `?name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAreaOptions({
      loading: false,

      list: res?.data?.areas?.map((area: { name: any }) => {
        return {
          value: area?.name,
          label: area?.name,
        };
      }),
    });
  }, []);

  useEffect(() => {
    getArea();
  }, [getArea]);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "locations") getLocationOptions(value);
        if (field === "user") getUsersOptions(value);
        if (field === "area") getArea(value);
        if (field === "country") getCountryOptions(value);
        if (field === "state") getStateOptions(value);
        if (field === "city") getCityOptions(value);
      }
    };

    return debounce(loadOptions, 1200);
  }, [
    getLocationOptions,
    getUsersOptions,
    getArea,
    getCountryOptions,
    getStateOptions,
    getCityOptions,
  ]);
  useEffect(() => {
    getCountryOptions();
    getStateOptions();
    getCityOptions();
  }, [getCountryOptions, getStateOptions, getCityOptions]);

  const hasProduct = (id: string) =>
    selectedProducts?.find((item: any) => item?.id === id);

  const steps = [
    {
      title: "Customers Info",
      content: (
        <Card size="default">
          <p>Create Manual Order</p>
          <Form.Item
            label="Inhouse Manual Order"
            name="shippingAddress"
            rules={[
              {
                required: false,
                message: "Shipping Address is required!",
              },
            ]}
          >
            <Input
              type="radio"
              size="large"
              placeholder="Enter Delivery Location"
            />
          </Form.Item>
          <Form.Item
            label="Out site Manual Order"
            name="shippingAddress"
            rules={[
              {
                required: false,
                message: "Shipping Address is required!",
              },
            ]}
          >
            <Input
              type="radio"
              size="large"
              placeholder="Enter Delivery Location"
            />
          </Form.Item>

          <Form
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="ant-form ant-form-vertical"
            layout="vertical"
            onFinish={onSubmitFirst}
            form={form} // like ref
          >
            <div className="grid grid-cols-2 gap-4">
              <Form.Item
                label="Customer Name"
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: "Customer name is required!",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Customer Name" />
              </Form.Item>

              <Form.Item
                hasFeedback
                label="Customer Mobile Number"
                rules={[
                  {
                    required: true,
                    message: "Mobile Number is required!",
                  },
                  {
                    validator: async (_, names) => {
                      if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                        return Promise.reject(
                          new Error("Enter valid mobile number")
                        );
                      }
                    },
                  },
                ]}
                name="customerMobileNumber"
                initialValue={"01"}
              >
                <Input
                  //disabled={shopData?.data || false}
                  id="customerMobileNumber"
                  type="contact"
                  addonBefore={"+88"}
                  placeholder="Enter Mobile Number"
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                label={`Country/Region`}
                rules={[
                  {
                    required: false,
                    message: "Region is required!",
                  },
                ]}
                name="countryId"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Country"
                  optionFilterProp="children"
                  onChange={(e) => setCountryId(e)}
                  onSearch={(val) => {
                    handleSearch?.(val, "country");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={countryOptions?.list}
                ></Select>
              </Form.Item>
              <Form.Item
                hasFeedback
                label={`State/Province`}
                rules={[
                  {
                    required: false,
                    message: "State is required!",
                  },
                ]}
                name="stateId"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select State"
                  optionFilterProp="children"
                  onChange={(val) => setStateId(val)}
                  onSearch={(val) => {
                    handleSearch?.(val, "state");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={stateOptions?.list}
                ></Select>
              </Form.Item>
              <Form.Item
                hasFeedback
                label={`City`}
                rules={[
                  {
                    required: false,
                    message: "City is required!",
                  },
                ]}
                name="cityId"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select City"
                  optionFilterProp="children"
                  onChange={(val) => setCityId(val)}
                  onSearch={(val) => {
                    handleSearch?.(val, "city");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={cityOptions?.list}
                ></Select>
              </Form.Item>

              <Form.Item
                label="Full Address"
                name="shippingAddress"
                rules={[
                  {
                    required: true,
                    message: "Full Address is required!",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Full Address" />
              </Form.Item>
              <Form.Item label="Note" name="orderNote">
                <Input size="large" placeholder="Enter note..." />
              </Form.Item>
            </div>

            {hubName && <h4>Hub: {hubName || "No Hub Found!"}</h4>}
          </Form>
        </Card>
      ),
    },
    {
      title: "Select Product",
      content: (
        <OrderProductList
          selectedProducts={selectedProducts}
          setSelectedProducts={async (products: any) => {
            setSelectedProducts(products);
          }}
          hubId={userHub}
          orderType={firstStepData?.type}
        />
      ),
    },
    {
      title: "Review & Place Order",
      content: (
        <div>
          <div
            className="order_details_area"
            style={{ maxHeight: `calc(100vh - 200px)` }}
          >
            <Row gutter={10} className="mt-0">
              <Col span={24}>
                <Card
                  title={<h4 className="mb-0">Review Order</h4>}
                  className="h-100"
                  size="small"
                >
                  <div className="product-information-tab-menu">
                    <Row gutter={20}>
                      <Col span={12}>
                        <h3 className="font-semibold text-gray-600 text-lg mb-2">
                          Customer Info
                        </h3>

                        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                          {firstStepData?.name && (
                            <div className="">
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                Customer Name:
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                {firstStepData?.name}
                              </dd>
                            </div>
                          )}

                          <div className="">
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Contact Number:
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {firstStepData?.customerMobileNumber?.split(
                                "+88"
                              )}
                            </dd>
                          </div>

                          {firstStepData?.name && (
                            <div className="">
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                Type:
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                {type?.split("_").join(" ")}
                              </dd>
                            </div>
                          )}
                        </dl>
                      </Col>
                      <Col span={12}>
                        <h3 className="font-semibold text-gray-600 text-lg mb-2">
                          Delivery Address
                        </h3>
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                          {firstStepData?.shippingAddress && (
                            <div className="">
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                Shipping Address:
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                {firstStepData?.shippingAddress}
                              </dd>
                            </div>
                          )}
                        </dl>
                      </Col>
                    </Row>

                    <Form
                      name="control-hooks"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      className="ant-form ant-form-vertical"
                      onFinish={onSubmit}
                      initialValues={
                        {
                          // ...order?.data,
                          // type: type || singleProductInfo?.data?.shop?.type,
                        }
                      }
                      form={form}
                      layout="vertical"
                      autoComplete="off"
                    >
                      <Row>
                        <Col span={24} className="mt-6">
                          <h3 className="font-semibold text-lg text-gray-700">
                            Selected Items
                          </h3>
                          <div
                            className="content-body"
                            style={{
                              maxHeight: "60vh",
                              height: "60vh",
                              overflow: "scroll",
                            }}
                          >
                            <table className={styles.mainTable}>
                              <thead>
                                <tr>
                                  <th scope="col">Product</th>
                                  <th scope="col">Barcode</th>
                                  <th scope="col">TP</th>
                                  <th scope="col">Price</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Total</th>
                                </tr>
                              </thead>
                              {selectedProducts.loading ? (
                                <Loader />
                              ) : (
                                <tbody>
                                  {selectedProducts?.length ? (
                                    selectedProducts?.map(
                                      (product: any, index: any) => (
                                        <tr
                                          className="border-t hover:bg-gray-100"
                                          key={index}
                                        >
                                          <td className="flex items-center gap-2">
                                            <Avatar
                                              size={45}
                                              src={product?.productImage}
                                              shape="square"
                                            />

                                            <div className="flex flex-col">
                                              <span className="font-medium text-gray-500">
                                                {product?.name?.slice(0, 50)}
                                              </span>
                                              {product?.barCode && (
                                                <span className="font-light text-gray-500 text-xs flex items-center gap-1">
                                                  <BarcodeOutlined />{" "}
                                                  {product?.barCode}
                                                </span>
                                              )}
                                            </div>
                                          </td>

                                          <td>
                                            <small className="text-center">
                                              {
                                                product?.variation
                                                  ?.variationName
                                              }
                                              <br />
                                              {product?.variation?.barCode ? (
                                                <>
                                                  {" "}
                                                  <BarcodeOutlined />{" "}
                                                  {product?.variation?.barCode}
                                                </>
                                              ) : undefined}
                                            </small>
                                          </td>
                                          <td>
                                            <span>
                                              &#2547;
                                              {product?.variation?.price?.tp}
                                            </span>
                                          </td>
                                          <td>
                                            {" "}
                                            <small className="name">
                                              &#2547;
                                              {product?.variation?.price?.mrp}
                                              {product?.variation?.price
                                                ?.discountedPrice !==
                                              product?.variation?.price?.mrp ? (
                                                <>
                                                  <span className="text-danger px-1">
                                                    {product?.variation
                                                      ?.productDiscount
                                                      ?.flat ? (
                                                      <>
                                                        {" - "}
                                                        <del>
                                                          &#2547;
                                                          {
                                                            product?.variation
                                                              ?.productDiscount
                                                              ?.flat
                                                          }
                                                        </del>
                                                      </>
                                                    ) : undefined}
                                                    {product?.variation
                                                      ?.productDiscount
                                                      ?.percentage ? (
                                                      <>
                                                        -
                                                        <del>
                                                          {
                                                            product?.variation
                                                              ?.productDiscount
                                                              ?.percentage
                                                          }
                                                          %
                                                        </del>
                                                      </>
                                                    ) : undefined}
                                                  </span>
                                                  {product?.variation?.price
                                                    ?.discountedPrice ? (
                                                    <span className=" px-1">
                                                      {"= "}
                                                      &#2547;
                                                      {
                                                        product?.variation
                                                          ?.price
                                                          ?.discountedPrice
                                                      }
                                                    </span>
                                                  ) : (
                                                    <span className="px-1">
                                                      {"= "}
                                                      &#2547;
                                                      {
                                                        product?.variation
                                                          ?.price?.mrp
                                                      }
                                                    </span>
                                                  )}
                                                </>
                                              ) : undefined}
                                            </small>
                                          </td>
                                          <td>
                                            <span className="">
                                              {hasProduct(product.id) && (
                                                <span className="order_input_area">
                                                  <div className="flex items-center">
                                                    <Form.Item
                                                      hasFeedback
                                                      name="productId"
                                                      initialValue={product?.id}
                                                      hidden
                                                    >
                                                      <Input
                                                        type="hidden"
                                                        style={{
                                                          minWidth: 50,
                                                        }}
                                                        disabled={
                                                          product?.quantity >=
                                                          product?.variation
                                                            ?.stock
                                                        }
                                                      />
                                                    </Form.Item>

                                                    <Button
                                                      onClick={() => {
                                                        setSelectedProducts(
                                                          (
                                                            prevProducts: any
                                                          ) => {
                                                            const arr =
                                                              Array.from(
                                                                prevProducts
                                                              );
                                                            const newArr =
                                                              arr.map(
                                                                (item: any) => {
                                                                  if (
                                                                    item?.id ===
                                                                    product?.id
                                                                  ) {
                                                                    if (
                                                                      item &&
                                                                      (
                                                                        item as any
                                                                      )
                                                                        .quantity >
                                                                        1
                                                                    ) {
                                                                      return {
                                                                        ...item,
                                                                        quantity:
                                                                          ((
                                                                            item as any
                                                                          )
                                                                            .quantity ||
                                                                            0) -
                                                                          1,
                                                                      };
                                                                    } else {
                                                                      return item;
                                                                    }
                                                                  }
                                                                  return item;
                                                                }
                                                              );

                                                            return newArr;
                                                          }
                                                        );
                                                      }}
                                                      disabled={
                                                        product?.quantity < 2
                                                      }
                                                      size="small"
                                                    >
                                                      <MinusOutlined />
                                                    </Button>

                                                    <Input
                                                      style={{
                                                        margin:
                                                          "0px !important",
                                                        width: 70,
                                                        textAlign: "center",
                                                      }}
                                                      size="small"
                                                      name="quantity"
                                                      type="number"
                                                      value={
                                                        hasProduct(product.id)
                                                          ?.quantity
                                                      }
                                                      onChange={(e) => {
                                                        const qty =
                                                          parseInt(
                                                            e.target.value
                                                          ) || 0;
                                                        setSelectedProducts(
                                                          (
                                                            prevProducts: any
                                                          ) => {
                                                            const arr =
                                                              Array.from(
                                                                prevProducts
                                                              );
                                                            const newArr =
                                                              arr.map(
                                                                (item: any) => {
                                                                  if (
                                                                    item?.id ===
                                                                    product?.id
                                                                  ) {
                                                                    if (
                                                                      qty > 0
                                                                    ) {
                                                                      return {
                                                                        ...item,
                                                                        quantity:
                                                                          qty,
                                                                      };
                                                                    } else {
                                                                      return item;
                                                                    }
                                                                  }
                                                                  return item;
                                                                }
                                                              );

                                                            return newArr;
                                                          }
                                                        );
                                                      }}
                                                      min={1}
                                                      minLength={1}
                                                    />

                                                    <Button
                                                      size="small"
                                                      onClick={() => {
                                                        setSelectedProducts(
                                                          (
                                                            prevProducts: any
                                                          ) => {
                                                            const arr =
                                                              Array.from(
                                                                prevProducts
                                                              );
                                                            const newArr =
                                                              arr.map(
                                                                (item: any) => {
                                                                  if (
                                                                    item?.id ===
                                                                    product?.id
                                                                  ) {
                                                                    if (
                                                                      item &&
                                                                      (
                                                                        item as any
                                                                      )
                                                                        .quantity >
                                                                        0
                                                                    ) {
                                                                      return {
                                                                        ...item,
                                                                        quantity:
                                                                          ((
                                                                            item as any
                                                                          )
                                                                            .quantity ||
                                                                            0) +
                                                                          1,
                                                                      };
                                                                    } else {
                                                                      return item;
                                                                    }
                                                                  }
                                                                  return item;
                                                                }
                                                              );

                                                            return newArr;
                                                          }
                                                        );
                                                      }}
                                                      disabled={
                                                        product?.quantity >=
                                                        product?.variation
                                                          ?.stock
                                                      }
                                                    >
                                                      <PlusOutlined />
                                                    </Button>
                                                    <Button
                                                      type="dashed"
                                                      size="small"
                                                      shape="circle"
                                                      danger
                                                      className="ml-2"
                                                      onClick={async () => {
                                                        await setSelectedProducts(
                                                          (
                                                            prevProducts: any
                                                          ) => {
                                                            const arr =
                                                              Array.from(
                                                                prevProducts
                                                              );
                                                            const newArr =
                                                              arr.filter(
                                                                (item: any) =>
                                                                  item?.id !==
                                                                  product?.id
                                                              );
                                                            return newArr;
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <CloseOutlined />
                                                    </Button>
                                                  </div>
                                                </span>
                                              )}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-right font-semibold text-gray-600">
                                              {product?.variation?.price
                                                ?.discountedPrice ? (
                                                <span className="name">
                                                  {product?.variation?.price
                                                    ?.mrp * product?.quantity}
                                                  {""}&#2547;
                                                </span>
                                              ) : (
                                                <span className="name">
                                                  {product?.variation?.price
                                                    ?.mrp *
                                                    product?.quantity}{" "}
                                                  &#2547;
                                                </span>
                                              )}
                                            </span>
                                          </td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <tr>
                                      <td>
                                        <Empty />
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              )}
                            </table>
                          </div>
                        </Col>
                      </Row>

                      <Row justify="end" align="top">
                        <Col span={9} className="mb-5 ml-auto max-w-xs">
                          <div className="flex justify-between py-1">
                            <span className="font-semibold">Sub Total:</span>
                            <span className="info-desc text-right">
                              &#2547;
                              {selectedProducts?.reduce((a, b) => {
                                return (
                                  a +
                                  b?.quantity *
                                    (b?.variation?.price?.mrp ||
                                      b?.variation?.price?.mrp)
                                );
                              }, 0)}
                            </span>
                          </div>
                          {/* <div className="single px-2">
                              <span className="info-name text-right">Vat :</span>
                              <span className="info-desc text-right">
                                &#2547;{order?.vat}
                              </span>
                            </div> */}

                          <div className="flex justify-between py-1">
                            <span className="font-semibold">Discount: </span>
                            <span className="info-desc text-right">
                              &#2547;
                              {selectedProducts?.reduce((a, b) => {
                                return (
                                  a +
                                  b?.quantity *
                                    (b?.variation?.price?.mrp -
                                      (b?.variation?.price?.discountedPrice ||
                                        b?.variation?.price
                                          ?.sellerDiscountedPrice ||
                                        0))
                                );
                              }, 0)}
                            </span>
                          </div>
                          <div className="flex justify-between py-1">
                            <span className="font-semibold">Total:</span>
                            <span className="info-desc text-right">
                              &#2547;
                              {
                                selectedProducts?.reduce((a, b) => {
                                  return (
                                    a +
                                    b?.quantity *
                                      (b?.variation?.price?.discountedPrice ||
                                        b?.variation?.price?.mrp)
                                  );
                                }, 0)
                                // + firstStepData?.deliveryCharge +
                                //   firstStepData?.vat
                              }
                            </span>
                          </div>
                        </Col>
                      </Row>

                      {/* <Row gutter={25}>
                            <Col span={24}>
                              <h3 className="title">Notes</h3>
                              <div>
                                <Form.Item
                                  hasFeedback
                                  // label="Notes"
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Notes is Required!",
                                  //   },
                                  // ]}
                                  name="notes"
                                >
                                  <TextArea
                                    id="notes"
                                    placeholder="Write your note..."
                                    rows={6}
                                  />
                                </Form.Item>
  
                                <Form.Item
                                  style={{
                                    width: "100%",
                                    flex: "1 1 100%",
                                    marginBottom: 5,
                                  }}
                                >
                                  <Button
                                    disabled={loading}
                                    loading={
                                      (loading ? "loading" : undefined) as any
                                    }
                                    type="primary"
                                    htmlType="submit"
                                    className="add-submit-btn text-center px-5"
                                  >
                                    Submit
                                  </Button>
                                </Form.Item>
                              </div>
                            </Col>
                          </Row> */}
                    </Form>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Out of Stock List"
        subTitle="Out of Stock List"
        extra={<></>}
      >
        <BreadCrumb title="Create Order" />

        <div className="mt-4">
          <Row gutter={10}>
            <Col span={24}>
              <Steps current={current}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <div className="steps-content mt-3">{steps[current].content}</div>
              <div className="steps-action_ mt-2">
                {current > 0 && (
                  <Button size="large" className="mr-3" onClick={() => prev()}>
                    Back
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button
                    size="large"
                    className="bg-green-500"
                    type="primary"
                    onClick={() => {
                      if (current === 0) {
                        form.validateFields();
                        form.submit();
                      } else {
                        next();
                      }
                    }}
                    disabled={
                      (current === 1 && selectedProducts?.length === 0) ||
                      (type === "JC_MART" && !userHub)
                    }
                  >
                    <span className="px-6">Next</span>
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button type="primary" danger size="large" onClick={onSubmit}>
                    Place Order
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};

ManualOrderCreate.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ManualOrderCreate;
