import * as React from "react";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Form, Input, Radio, Select } from "antd";
import axios from "axios";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";

export default function PersonalDetails() {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [identificationUrl, setIdentificationUrl] = useState<string>();
  const navigate = useNavigate();
  const [gender, setGender] = useState<any>();
  const [profilePicUrl, setProfilePicUrl] = useState<string>();
  const [accountCreatedBy, setAccountCreatedBy] = useState<any>("FOUNDER");
  const [singleMerchantInfo, setSingleMerchantInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const [profilePictureUrl, setProfilePictureUrl] = useState<string>();
  const [identificationType, setIdentificationType] = useState<any>("NID");
  const [error, setError] = useState("");
  const [identificationUrl2, setIdentificationUrl2] = useState<string>();
  const [utilityImageUrl, setUtilityImageUrl] = useState<string>();
  const [identificationDocumentUrl, setIdentificationDocumentUrl] =
    useState<string>();

  const [startUploadProfilePic, setStartUploadProfilePic] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [startIdentificationUpload2, setStartIdentificationUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload3, setStartUpload3] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [uploadProfilePicture, setUploadProfilePicture] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [uploadUtilityImage, setUploadUtilityImage] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadFront, setStartUploadFront] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [form] = Form.useForm();

  const fetchMerchantDetails = useCallback(() => {
    try {
      setSingleMerchantInfo({ loading: true, data: null });
      axios
        .get(`${process.env.REACT_APP_RIDER_API}/details`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((data) => {
          if (data.status === 200) {
            setSingleMerchantInfo({
              loading: false,
              data: data?.data?.merchant,
            });
           
            setProfilePictureUrl(
              data?.data?.merchant?.merchantPersonalDetail?.profilePicture
            );
            setIdentificationUrl2(
              data?.data?.merchant?.merchantPersonalDetail
                ?.identificationDocumentUrl2
            );
            setIdentificationDocumentUrl(
              data?.data?.merchant?.merchantPersonalDetail
                ?.identificationDocumentUrl
            );
          } else {
            setSingleMerchantInfo({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setSingleMerchantInfo({ loading: false, data: null });

          console.log(err);
        });
    } catch (error) {
      setSingleMerchantInfo({ loading: false, data: null });

      console.log(error, "error");
    }
  }, [token]);

  useEffect(() => {
    fetchMerchantDetails();
  }, [fetchMerchantDetails, token]);
  useEffect(() => {
    if (singleMerchantInfo.data) {
      form.resetFields(Object.keys(singleMerchantInfo.data as any));

    setProfilePictureUrl(
      singleMerchantInfo?.data?.merchantPersonalDetail?.profilePicture
    );
    setIdentificationDocumentUrl(singleMerchantInfo?.data?.merchantPersonalDetail?.identificationDocumentUrl)
    setIdentificationUrl2(singleMerchantInfo?.data?.merchantPersonalDetail?.identificationDocumentUrl2);
    setUtilityImageUrl(singleMerchantInfo?.data?.merchantPersonalDetail?.utilityImage);
    setAccountCreatedBy(singleMerchantInfo?.data?.merchantPersonalDetail?.accountCreatedBy);
  }
  }, [form, singleMerchantInfo.data]);

  function handleChange(value) {
    setDocumentType(value);
  }
  useEffect(() => {
    if (startUploadProfilePic === "Uploaded") {
      form?.submit();
    }
  }, [form, startUploadProfilePic]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      name: data.name,
      gender: gender,
      email: data.email,
      accountCreatedBy: accountCreatedBy,
      designation: data.designation,
      identificationDocumentType: identificationType,
      identificationDocumentNumber: data.identificationDocumentNumber,
      identificationDocumentUrl: identificationDocumentUrl || "",
      identificationDocumentUrl2: identificationUrl2 || "",
      profilePicture: profilePictureUrl || "",
      utilityImage: utilityImageUrl || "",
    };

    await fetch(`${process.env.REACT_APP_RIDER_API}/merchant/personal-info`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification(data?.message, "success");
          form.resetFields();
          //window.location.reload();
          navigate("/merchant-profile");
          // navigate("/account/my-profile");
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const resetData = () => {
    form?.resetFields();
  };

  useEffect(() => {
    if (singleMerchantInfo?.data) {
      form.resetFields();
      setProfilePictureUrl(
        singleMerchantInfo?.data?.merchantPersonalDetail?.profilePicture
      );
      setIdentificationDocumentUrl(singleMerchantInfo?.data?.merchantPersonalDetail?.identificationDocumentUrl);
      setIdentificationUrl2(
        singleMerchantInfo?.data?.merchantPersonalDetail
          ?.identificationDocumentUrl2
      );
      setUtilityImageUrl(singleMerchantInfo?.data?.merchantPersonalDetail?.utilityImage);
      setAccountCreatedBy(singleMerchantInfo?.data?.merchantPersonalDetail?.accountCreatedBy);
    }
  }, [form, singleMerchantInfo?.data]);
  return (
    <div className="flex overflow-hidden flex-col bg-stone-50 pb-[740px] max-md:pb-24">
      <main className="flex flex-col self-center mt-5 max-w-full w-[1170px]">
        <div className="flex gap-2 self-start text-base font-bold text-zinc-800">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d7fe58accc7d9847083a3f1a5fef304a601ebb28b9ffa035b153f38c0dce7be1?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
            alt=""
            className="object-contain shrink-0 w-6 aspect-square"
          />
          <h1>Personal Details & ID</h1>
        </div>

        <Form
          name="control-hooks"
          onFinish={onSubmit}
          form={form}
          layout="vertical"
          className="flex flex-col items-start pt-8 pr-20 pb-12 pl-8 mt-2.5 w-full bg-white rounded-md shadow-sm max-md:px-5 max-md:max-w-full"
          initialValues={{
            ...singleMerchantInfo?.data?.merchantPersonalDetail,
            name: singleMerchantInfo?.data?.merchantPersonalDetail?.name || "",
            email:
              singleMerchantInfo?.data?.merchantPersonalDetail?.email || "",
          }}
        >
          {/* <form className="flex flex-col items-start pt-8 pr-20 pb-12 pl-8 mt-2.5 w-full bg-white rounded-md shadow-sm max-md:px-5 max-md:max-w-full"> */}
          <div className="flex flex-wrap gap-8 mt-7">
            <Form.Item label="Account Create by">
              <Radio.Group
                onChange={(e) => setAccountCreatedBy(e.target.value)}
                defaultValue={accountCreatedBy}
              >
                <Radio value="FOUNDER">Founder</Radio>
                <Radio value="SELLER_MANAGER">Seller manager</Radio>
                <Radio value="SELLER_EMPLOYEE">Seller Employee</Radio>
                <Radio value="AGENT">Referral/Agent</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="flex flex-col gap-2">
            <Form.Item
              name="name"
              hasFeedback
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Name is Required!",
                },
              ]}
            >
              <Input
                type="text"
                id="name"
                placeholder="Enter name"
                className="px-4 py-3.5 font-medium bg-white rounded border border-solid border-neutral-300 text-neutral-300 w-[570px]"
              />
            </Form.Item>
          </div>
          <div className="flex flex-col gap-2">
            <Form.Item
              name="designation"
              hasFeedback
              label="Designation"
              rules={[
                {
                  required: true,
                  message: "Designation is Required!",
                },
              ]}
            >
              <Input
                type="text"
                id="designation"
                placeholder="Enter Designation"
                className="px-4 py-3.5 font-medium bg-white rounded border border-solid border-neutral-300 text-neutral-300 w-[570px]"
              />
            </Form.Item>
          </div>
          <div className="flex flex-col gap-2">
            <Form.Item
              name="email"
              hasFeedback
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Email is Required!",
                },
              ]}
            >
              <Input
                type="text"
                id="email"
                placeholder="Enter Email"
                className="px-4 py-3.5 font-medium bg-white rounded border border-solid border-neutral-300 text-neutral-300 w-[570px]"
              />
            </Form.Item>
          </div>
          <div className="flex flex-col gap-2">
            <div className="grid grid-cols-3 gap-4">
              <div className="basis-0 pr-2">
                <Form.Item
                  name="gender"
                  hasFeedback
                  label="Select Gender"
                  rules={[
                    {
                      required: true,
                      message: "Gender is Required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Gender"
                    optionFilterProp="children"
                    defaultValue={gender || ""}
                    onChange={(val) => setGender(val)}
                    options={[
                      { label: "MALE", value: "MALE" },
                      { label: "FEMALE", value: "FEMALE" },
                    ]}
                  ></Select>
                </Form.Item>
              </div>
              <div className="basis-0">
                <Form.Item
                  name="identificationDocumentType"
                  hasFeedback
                  label="Verify Identification "
                  rules={[
                    {
                      required: true,
                      message: "Document Type is Required!",
                    },
                  ]}
                >
                  <Select
                    defaultValue={identificationType}
                    onChange={(val) => setIdentificationType(val)}
                    options={[
                      {
                        label: "NID",
                        value: "NID",
                      },
                      {
                        label: "PASSPORT",
                        value: "PASSPORT",
                      },
                      {
                        label: "BIRTH CERTIFICATE",
                        value: "BIRTH_CERTIFICATE",
                      },
                    ]}
                  ></Select>
                </Form.Item>
              </div>

              <div className="basis-0">
                <Form.Item
                  name="identificationDocumentNumber"
                  hasFeedback
                  label={`${identificationType.replace("_", " ")} Number`}
                  rules={[
                    {
                      required: false,
                      message: "Number is Required!",
                    },
                  ]}
                >
                  <Input
                    id="identificationDocumentNumber"
                    type="text"
                    className="input-box"
                    placeholder="Enter Id Number"
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="grid grid-cols-4 gap-4">
              <div className="basis-0 pr-2">
                <span className="text-md">Profile Picture</span>
                <ImageUploader
                  imgUrl={profilePictureUrl}
                  startUpload={startUploadProfilePic}
                  setStartUpload={(val) => setStartUploadProfilePic(val)}
                  setGeneratedImgUrl={(url) => setProfilePictureUrl(url)}
                />
              </div>
              <div className="basis-0 pr-2">
                <span className="text-md">Front Image</span>
                <ImageUploader
                  imgUrl={identificationDocumentUrl}
                  startUpload={startUploadFront}
                  setStartUpload={(val) => setStartUploadFront(val)}
                  setGeneratedImgUrl={(url) =>
                    setIdentificationDocumentUrl(url)
                  }
                />
              </div>
              {identificationType !== "BIRTH_CERTIFICATE" && (
                <>
                  <div className="basis-0">
                    <span className="text-md">Back Image</span>
                    <ImageUploader
                      imgUrl={identificationUrl2}
                      startUpload={startIdentificationUpload2}
                      setStartUpload={(val) =>
                        setStartIdentificationUpload2(val)
                      }
                      setGeneratedImgUrl={(url) => setIdentificationUrl2(url)}
                    />
                  </div>
                </>
              )}

              <div className="basis-0">
                <span className="text-md">Utility Bill</span>
                <ImageUploader
                  imgUrl={utilityImageUrl || ""}
                  startUpload={uploadUtilityImage}
                  setStartUpload={(val: any) => setUploadUtilityImage(val)}
                  setGeneratedImgUrl={(url: any) => setUtilityImageUrl(url)}
                />
              </div>
            </div>
          </div>
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUploadProfilePic("Uploading");
              setStartUploadFront("Uploading");
              setStartIdentificationUpload2("Uploading");
              setUploadUtilityImage("Uploading");
            }}
            onClickReset={resetData}
            // disabled={loading || startUploadProfilePic === "Uploading"}
            // loading={loading || startUploadProfilePic === "Uploading"}
          />
          {/* <div className="flex gap-5 justify-between self-center mt-8 max-w-full text-sm font-bold text-center text-white whitespace-nowrap w-[328px]">
            <button
              type="submit"
              className="px-8 py-4 bg-rose-700 rounded-md max-md:px-5"
            >
              Submit
            </button>
            <button
              type="reset"
              className="px-8 py-4 bg-amber-600 rounded-md max-md:px-5"
            >
              Reset
            </button>
          </div> */}
        </Form>
      </main>
    </div>
  );
}
