import * as React from 'react';
import { InputField } from '../MemberShipProfile/common/InputField';
import { DatePicker } from '../MemberShipProfile/common/DatePicker';
import { FileUpload } from '../MemberShipProfile/common/FileUpload';

export const BrandManagement: React.FC = () => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <div className="flex overflow-hidden flex-col bg-stone-50 pb-[667px] max-md:pb-24">
      <main className="flex flex-col self-center mt-5 max-w-full w-[1170px]">
        <div className="flex gap-2 self-start text-base font-bold text-zinc-800">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/bada42f2987ab446b8e85b6ecc991ca5785c6046e9b0c4119d7b927370f4bd95?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
            alt="Add brand icon"
            className="object-contain shrink-0 w-6 aspect-square"
          />
          <h1>Add Brand</h1>
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col items-start py-9 pr-20 pl-8 mt-2.5 w-full bg-white rounded-md shadow-sm max-md:px-5 max-md:max-w-full">
          <h2 className="text-base font-bold text-zinc-800 max-md:ml-0.5">Brand Information</h2>
          
          <InputField label="Brand Name" placeholder="Brand Name" required />
          <InputField label="Legal name/ Business Company Name" placeholder="Legal name/business company name" required />
          <InputField label="Business Registration Number" placeholder="Legal name/business company name" required />
          
          <DatePicker label="Authorization Start Time" />
          <DatePicker label="Authorization End Time" />
          
          <div className="flex flex-wrap gap-8 mt-6">
            <FileUpload
              label="Business Registration Document"
              buttonText="Upload Business Registration Document"
              required
            />
            <FileUpload
              label="Proof of Authenticity"
              buttonText="Upload proof of authenticity"
              required
            />
          </div>

          <div className="flex gap-6 mt-6 ml-60 text-sm font-bold text-center text-white whitespace-nowrap max-md:ml-2.5">
            <button type="submit" className="px-14 py-4 bg-rose-700 rounded-md max-md:px-5">
              Submit
            </button>
            <button type="reset" className="px-16 py-4 bg-amber-600 rounded-md max-md:px-5">
              Reset
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default BrandManagement;