import Cookies from "js-cookie";
import { responseNotification } from "../../utils/notify";
import { LOGIN_REQUEST, LOGIN_SUCCESS } from "./authType";
import decode from "jwt-decode";

export const login = async (
  dispatch: (arg0: { type: string; payload?: any }) => void,
  { mobileNumber, password, type }: any
) => {
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_API}/merchant/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobileNumber,
          password,
        }),
      }
    );

    const response1 = await res.json();
    const { token } = response1;
    if (token) {
      // start permission api
      const res2 = await fetch(
        `${process.env.REACT_APP_AUTH_API}/merchant-user-group/permissions?serviceType=${type}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const response2 = await res2.json();
      const { merchantServiceGroups } = response2;
      if (response2) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            ...response2,
            token: token,
            iat: new Date(),
          },
        });
      }
      if (merchantServiceGroups?.length) {
        const formatedServices = merchantServiceGroups?.map(
          (item: {
            action: any;
            serviceNameId: any;
            serviceName: { name: any };
          }) => ({
            action: item.action,
            serviceNameId: item.serviceNameId,
            serviceName: item.serviceName?.name,
          })
        );

        Cookies.set("authToken", token, {
          secure: true,
          expires: (decode(token as string) as any)?.exp,
        });
        localStorage.setItem("services", JSON.stringify(formatedServices));
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            ...response2,
            ...response1,
            iat: new Date(),
            token: token,
            // type: type,
          },
        });

        responseNotification(
          response2?.message?.replace("Operation", "Merchant Login"),
          "success"
        );
        // start merchaant check
        const merchantShopCheck = await fetch(
          `${process.env.REACT_APP_CATALOG_READER_API}/merchant/all-shops-by-merchant`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const merchantShopInfo = await merchantShopCheck.json();
        const merchantShopInfoCheck = merchantShopInfo?.shops;
        if (merchantShopInfoCheck?.length === 0) {
          //add new condition
          if (
            response1?.userType === "SUPER_MERCHANT" ||
            response1?.userType === "MERCHANT"
          ) {
            const res2 = await fetch(
              `${process.env.REACT_APP_RIDER_API}/details`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            const merchantInfo = await res2.json();
            const { merchant } = merchantInfo;

            if (merchant) {
              dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                  ...response1,
                  token: token,
                  merchantInfo: merchant,
                  merchantId: merchant?.merchantId,
                  iat: new Date(),
                  //type: merchant?.serviceType,
                },
              });

              responseNotification(
                merchantInfo?.message?.replace("Operation", "Login"),
                "success"
              );
              if (merchant?.state === "VERIFIED") {
                return "alreadyMerchant";
              } else {
                return "newMerchant";
              }
              //return "newMerchant";
              //return true;
            } else {
              responseNotification(
                merchantInfo?.message?.replace(
                  "Operation Successful",
                  "No data found"
                ),
                "error"
              );

              return false;
            }
          }
          //add new condition end
          //return "newMerchant";
        } else {
          // merchant all cradintial check
          if (
            response1?.userType === "SUPER_MERCHANT" ||
            response1?.userType === "MERCHANT"
          ) {
            const res2 = await fetch(
              `${process.env.REACT_APP_RIDER_API}/details`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            const merchantInfo = await res2.json();
            const { merchant } = merchantInfo;

            if (merchant) {
              dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                  ...response1,
                  token: token,
                  merchantInfo: merchant,
                  merchantId: merchant?.merchantId,
                  iat: new Date(),
                  //type: merchant?.serviceType,
                },
              });
              responseNotification(
                merchantInfo?.message?.replace("Operation", "Login"),
                "success"
              );
              if (merchant?.state === "VERIFIED") {
                return "alreadyMerchant";
              } else {
                return "newMerchant";
              }
              //return true;
            } else {
              responseNotification(
                merchantInfo?.message?.replace(
                  "Operation Successful",
                  "No data found"
                ),
                "error"
              );

              return false;
            }
          }
        }
        // end merchant check

        //return "alreadyMerchant";
        return true;
      } else {
        responseNotification(
          response2?.message?.replace(
            "Operation Successful",
            "No services allowed this merchant please contract admin"
          ),
          "error"
        );
        //return "newMerchant";
        return false;
      }
    } else {
      responseNotification(
        response1?.message || "No services allowed this merchant",
        "error"
      );
      return false;
    }
  } catch (error) {
    return false;
  }
};
// end permission api
