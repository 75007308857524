import React from "react";
import MerchantLayout from "../../components/MerchantLayouts";
import BrandAdd from "../../components/Business/BrandAdd";

const BrandAddPage = () => {
  return (
    <MerchantLayout>
      <BrandAdd />
    </MerchantLayout>
  ); 
};

export default BrandAddPage;
