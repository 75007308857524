import * as React from 'react';
import { InputFieldProps } from '../../../type';

export const InputField: React.FC<InputFieldProps> = ({
  label,
  placeholder,
  required = false,
  type = "text"
}) => (
  <>
    <div className="mt-5 text-sm font-bold text-zinc-800">
      {label}{required && <span className="text-red-600">*</span>}
    </div>
    <div className="px-3 pt-2.5 pb-5 mt-5 max-w-full text-sm font-medium bg-white rounded border border-solid border-neutral-300 text-neutral-300 w-[570px] max-md:pr-5">
      <input
        type={type}
        placeholder={placeholder}
        className="w-full bg-transparent outline-none"
        required={required}
        aria-label={label}
      />
    </div>
  </>
);