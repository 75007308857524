import * as React from 'react';
import { UserInfo } from '../types';

interface UserCardProps {
  userInfo: UserInfo;
}

export const UserCard: React.FC<UserCardProps> = ({ userInfo }) => {
  return (
    <div className="flex gap-5 justify-between pr-6 mt-8 max-w-full bg-orange-100 rounded-xl shadow-sm w-[970px] max-md:pr-5">
      <div className="flex flex-col items-center py-4 pr-4 pl-12 bg-amber-400 rounded-xl max-md:pl-5">
        <div className="flex gap-10 self-end">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2fbab5527c11221ab666edae5b0d6384fdb40c5453916a87436abf7c8364f61a?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
            alt="User avatar"
            className="object-contain shrink-0 w-20 aspect-square"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/cb5b5c56903b80768a83adbb961c18ca0bcbdfb00f1be2819324e99bde0548a0?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
            alt=""
            className="object-contain shrink-0 self-start aspect-square w-[18px]"
          />
        </div>
        <div className="mt-2 text-lg font-bold text-center text-zinc-800">
          New User
        </div>
        <div className="text-sm font-medium text-center text-zinc-800">
          {userInfo.id}
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/148e5ccbd9d39a668494664ca5179050b96b2f2f185293c6fe2b9d1085202fd4?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
          alt=""
          className="object-contain rounded-none aspect-[5.68] w-[68px]"
        />
      </div>
      <div className="flex flex-col my-auto max-md:max-w-full">
        <div className="flex flex-wrap gap-10 items-start px-4 py-4 w-full font-bold text-white bg-cyan-700 rounded-md max-md:pr-5 max-md:max-w-full">
          <div className="flex flex-col flex-1 self-start">
            <div className="text-xs">jachai.com Membership</div>
            <div className="self-start mt-4 text-sm">{userInfo.level}</div>
          </div>
          <div className="flex flex-1 gap-9 self-end mt-7 text-sm">
            <div>{userInfo.points} Points</div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/5075637adb9b6ab505f2852966fb8aaa90fc7b96a690fb00d237b8c39bc3a402?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
              alt=""
              className="object-contain shrink-0 self-start w-1.5 aspect-[0.5]"
            />
          </div>
        </div>
        <div className="mt-5 text-xs font-medium text-gray-700 max-md:max-w-full">
          <span className="text-sm font-bold leading-5">
            Verification in progress:
          </span>{" "}
          <span className="text-sm leading-5">
            our team is review your Documents. Expect a response within 48
            hours. Thank you for your patience.
          </span>
        </div>
      </div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d486dd53891aed6c4453d7fe116c3d7cf56f4b8444dfbf5e0ce0b313ff7fe89?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
        alt=""
        className="object-contain shrink-0 self-end mt-32 w-10 rounded-none aspect-square max-md:mt-10"
      />
    </div>
  );
};