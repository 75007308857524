import React from "react";
import MerchantLayout from "../../components/MerchantLayouts";
import BusinessDayInfo from "../../components/Business/BusinessInfoDay";

const BusinessDayInfoPage = () => {
  return (
    <MerchantLayout>
      <BusinessDayInfo />
    </MerchantLayout>
  ); 
};

export default BusinessDayInfoPage;
